import React from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { Text } from "../../../text/Text";
import { media } from "../../../../styles/media";
import { useMediaType } from "../../../../styles/style.context";

const Wrapper = styled.div`
  display: none;
  ${media.tablet`
    display: flex;
    align-items: center;
  `}
`;
export const BurgerButton = styled.div`
  width: 18px;
  height: 18px;
  position: relative;
  z-index: 999;
  display: none;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  & div {
    width: 100%;
    height: 2px;
    position: absolute;
    background-color: ${({ color, modalIsOpen, black1 }: any) =>
      modalIsOpen ? black1 : color};
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: ${({ color, modalIsOpen, black1 }: any) =>
        modalIsOpen ? black1 : color};
      transition: all 0.3s ease-in-out;
      border-radius: 10px;
    }
    &:before {
      transform: translateY(6px);
      border-radius: 10px;
    }
    &:after {
      transform: translateY(-6px);
      border-radius: 10px;
    }
  }
  &.open div {
    transform: translateX(-100%);
    background-color: transparent;
    border-radius: 10px;
    &:before {
      transform: translateX(100%) rotate(-45deg);
      border-radius: 10px;
      background-color: black1;
    }
    &:after {
      transform: translateX(100%) rotate(45deg);
      border-radius: 10px;
      background-color: black1;
    }
  }
  ${media.tablet`
    display: flex;
  `}
`;

export const Burger = ({
  onClick,
  isOpenMobileMenu,
  languageStrings,
}: {
  onClick: () => void;
  isOpenMobileMenu: boolean;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const mediaType = useMediaType();

  const { black1 } = theme.colors;
  const { medium, common } = theme.fontSizes;
  const { phone } = mediaType;

  return (
    <Wrapper onClick={onClick}>
      <BurgerButton
        className={isOpenMobileMenu && "open"}
        color={black1}
        modalIsOpen={isOpenMobileMenu}
        black={black1}
      >
        <div />
      </BurgerButton>
      {!isOpenMobileMenu && (
        <Text
          weight={phone ? "400" : "500"}
          size={phone ? common : medium}
          color={black1}
        >
          {languageStrings ? languageStrings.menuLabel : "Menu"}
        </Text>
      )}
    </Wrapper>
  );
};
