import React from "react";
// @ts-ignore
import styled from "@xstyled/styled-components";
import { ReactComponent as GroupIcon } from "../../../../../../assets/group.svg";
import { media } from "../../../../../../styles/media";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &.scrolled {
    & > svg > path {
      fill: ${(props: any) => props.theme.colors.black};
    }
    & > div {
      border-color: ${(props: any) => props.theme.colors.black};
    }
  }
  &:hover {
    & > svg > path {
      fill: ${(props: any) => props.theme.colors.blue};
      transition: all 0.3s ease;
    }
    & > div {
      border-color: ${(props: any) => props.theme.colors.blue};
    }
  }
`;
const Line = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  border-bottom: 3px solid;
  border-color: white;
  width: 132%;
  transform: rotate(45deg);
  transform-origin: left;
  transition: all 0.3s linear;
  cursor: pointer;
`;
const Icon = styled(GroupIcon)`
  width: 30px;
  height: 30px;
  cursor: pointer;
  & > path {
    transition: all 0.3s ease;
  }

  ${media.tablet`
    width: 24px;
    height: 24px;
  `}
`;

export const DeafIcon = ({
  onClick,
  isScrolled,
  className,
  isDeaf,
}: {
  onClick: () => void;
  isScrolled?: boolean;
  className?: string;
  isDeaf?: boolean;
}) => {
  return (
    <Wrapper
      onClick={onClick}
      className={isScrolled ? `scrolled ${className}` : `${className}`}
    >
      <Icon className={isScrolled ? "scrolled" : ""} />
      {isDeaf && <Line />}
    </Wrapper>
  );
};
