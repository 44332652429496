import React from "react";
// @ts-ignore
import styled from "@xstyled/styled-components";
import ReactPlayer from "react-player";
import { isYouTubeVideoLink } from "../../../../utils/isVideoFromYoutube";

export const VideoRunner = ({ url }: { url: string }) => {
  const isVideoFromYoutube = isYouTubeVideoLink(url);

  return (
    <Wrapper>
      {isVideoFromYoutube ? (
        <StyledIframe
          width="560"
          height="315"
          src={url}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></StyledIframe>
      ) : (
        <ReactPlayer url={url} controls />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const StyledIframe = styled.iframe`
  display: flex;
`;
