import React from "react";
// @ts-ignore
import styled from "@xstyled/styled-components";
import { ImageBlock } from "./components/imageBlock/ImageBlock";
import { ChooseLanguage } from "./components/chooseLanguage/ChooseLanguage";
import {
  defaultLang,
  selectIsAppLoading,
  selectLanguageStrings,
  selectLanguagesList,
  setLanguage,
} from "../../../app/state/appSlice";
import ls from "../../../utils/ls";
import { LsValueType } from "../../../enums/LsValueType";
import { useDispatch, useSelector } from "react-redux";
import { useCustomHistory } from "../../../utils/react-router-dom-abstraction";
import { View } from "../../../routes";
import { useGetLanguages } from "../../../api/languages";
import { Loader } from "../../../components/placeholders/Loader";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;

export const Welcome = () => {
  useGetLanguages();
  const dispatch = useDispatch();
  const languageStrings = useSelector(selectLanguageStrings);
  const history = useCustomHistory();
  const languages = useSelector(selectLanguagesList);
  const isAppLoading = useSelector(selectIsAppLoading);

  const handleSetLanguage = (
    language: { name: string; id: string },
    index: number
  ) => {
    if (index < 10) {
      ls.set(LsValueType.locale, language);
      dispatch(setLanguage(language));
      history.push(View.USER_HOME, {
        pathParams: {
          languageKey: language.id,
          currentPage: 1,
          searchValue: "empty_search",
        },
      });
    } else {
      ls.set(LsValueType.locale, defaultLang);
      dispatch(setLanguage(defaultLang));
      history.push(View.DEAF_HOME, {
        pathParams: {
          languageKey: defaultLang.id,
          currentPage: 1,
          searchValue: "empty_search",
        },
      });
    }
  };

  return (
    <Wrapper>
      <ImageBlock
        languagesList={languages}
        handleSetLanguage={handleSetLanguage}
        languageStrings={languageStrings}
      />
      <ChooseLanguage
        languagesList={languages}
        handleSetLanguage={handleSetLanguage}
        languageStrings={languageStrings}
      />
      {isAppLoading && <Loader />}
    </Wrapper>
  );
};
