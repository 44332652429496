import React, { useEffect } from "react";
// @ts-ignore
import styled from "@xstyled/styled-components";
import { useLocation, withRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { Header } from "../../components/header/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsAppLoading,
  selectLanguageStrings,
  selectLanguagesList,
  setLanguage,
} from "../../app/state/appSlice";
import { View } from "../../routes/routeInterfaces";
import { Footer } from "../../components/footer/Footer";
import { useGetLanguages } from "../../api/languages";
import { Loader } from "../../components/placeholders/Loader";
import ls from "../../utils/ls";
import { LsValueType } from "../../enums/LsValueType";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const userMenuArr = (languageStrings?: any) => [
  {
    id: "books",
    label: languageStrings ? languageStrings.booksLabel : "Books",
    to: View.USER_BOOKS,
  },
  {
    id: "audiobooks",
    label: languageStrings ? languageStrings.audioBooksLabel : "Audiobooks",
    to: View.USER_AUDIOBOOKS,
  },
  {
    id: "movies",
    label: languageStrings ? languageStrings.mooviesLabel : "Moovies",
    to: View.USER_MOVIES,
  },
  {
    id: "sermons",
    label: languageStrings ? languageStrings.sermonsLabel : "Sermons",
    to: View.USER_SERMONS,
  },
  {
    id: "kids",
    label: languageStrings ? languageStrings.kidsLabel : "Kids",
    to: View.USER_KIDS,
  },
];

const User = (props: any) => {
  useGetLanguages();
  const languagesArr = useSelector(selectLanguagesList);
  const languageStrings = useSelector(selectLanguageStrings);
  const isAppLoading = useSelector(selectIsAppLoading);
  const languageIdLS = ls.get(LsValueType.locale);

  const location = useLocation();
  const dispatch = useDispatch();

  const languageIdFromPath = location?.pathname.split("/")[2];

  const menuArr = userMenuArr(languageStrings);

  useEffect(() => {
    const locale =
      languagesArr &&
      (languagesArr.find((item: any) => item.id === languageIdFromPath) as {
        name: string;
        id: string;
      });

    if (!languageIdLS && locale) {
      ls.set(LsValueType.locale, locale);
      dispatch(setLanguage(locale));
    }
  }, [languagesArr, languageIdLS, languageIdFromPath]);

  return (
    <Wrapper>
      <Header menuArr={menuArr} />
      {renderRoutes(props.route.routes)}
      <Footer languageStrings={languageStrings} />
      {isAppLoading && <Loader />}
    </Wrapper>
  );
};

export default withRouter(User);
