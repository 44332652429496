import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ContentDetails } from "../../../layouts/contentDetails/ContentDetails";
import { selectContentItem } from "../../../app/state/contentSlice";
import { UserView } from "../../../enums/View";
import { useGetContentDetails } from "../../../api/content";

export const MoviesDetails = () => {
  const commentCategory = "movie";
  const contentCategory = "movies";
  const pathArr = useLocation().pathname.split("/");
  const movieId = pathArr[4];
  useGetContentDetails(movieId, contentCategory, commentCategory);
  const movieDetailsData = useSelector(selectContentItem);

  return (
    <ContentDetails
      rootView={UserView.USER_MOVIES}
      data={movieDetailsData}
      category={commentCategory}
      contentCategory={contentCategory}
    />
  );
};
