import React from "react";

import { withRouter } from "react-router-dom";
import { PATHS } from "../routes/paths";
import { LoadingScreen } from "../features/loadingScreen/LoadingScreen";

const UserSwitcher = ({ history }: { history: any }) => {
  history.push(PATHS.COMMON_WELCOME);

  return <LoadingScreen />;
};

export default withRouter(UserSwitcher);
