import { CombinedRoutes, View } from "./routeInterfaces";

export const MAPPING = Object.freeze({
  [View.USER]: { path: "/user", access: [] },
  [View.USER_HOME]: { path: "/user/:languageKey/home", access: [] },
  [View.USER_BOOKS_DETAILS]: {
    path: "/user/:languageKey/books/:id",
    access: [],
  },
  [View.USER_BOOKS]: {
    path: "/user/:languageKey/books/:currentPage/:searchValue",
    access: [],
  },
  [View.USER_AUDIOBOOKS]: {
    path: "/user/:languageKey/audiobooks/:currentPage/:searchValue",
    access: [],
  },
  [View.USER_AUDIOBOOKS_DETAILS]: {
    path: "/user/:languageKey/audiobooks/:id",
    access: [],
  },
  [View.USER_MOVIES]: {
    path: "/user/:languageKey/movies/:currentPage/:searchValue",
    access: [],
  },
  [View.USER_MOVIES_DETAILS]: {
    path: "/user/:languageKey/movies/:id",
    access: [],
  },
  [View.USER_SERMONS]: {
    path: "/user/:languageKey/sermons/:currentPage/:searchValue",
    access: [],
  },
  [View.USER_SERMONS_DETAILS]: {
    path: "/user/:languageKey/sermons/:id",
    access: [],
  },
  [View.USER_KIDS]: {
    path: "/user/:languageKey/kids/:currentPage/:searchValue",
    access: [],
  },
  [View.USER_KIDS_DETAILS]: { path: "/user/:languageKey/kids/:id", access: [] },
  [View.USER_PERSONAL_INFO]: {
    path: "/user/:languageKey/personal-info/:code",
    access: [],
  },
  [View.REDIRECT]: { path: "/", access: [] },
  [View.DEAF]: { path: "/deaf", access: [] },
  [View.DEAF_HOME]: { path: "/deaf/:languageKey/home", access: [] },
  [View.DEAF_MOVIES]: {
    path: "/deaf/:languageKey/movies/:currentPage/:searchValue",
    access: [],
  },
  [View.DEAF_MOVIES_DETAILS]: {
    path: "/deaf/:languageKey/movies/:id",
    access: [],
  },
  [View.DEAF_SERMONS]: {
    path: "/deaf/:languageKey/sermons/:currentPage/:searchValue",
    access: [],
  },
  [View.DEAF_SERMONS_DETAILS]: {
    path: "/deaf/:languageKey/sermons/:id",
    access: [],
  },
  [View.DEAF_LESSONS]: {
    path: "/deaf/:languageKey/lessons/:currentPage/:searchValue",
    access: [],
  },
  [View.DEAF_LESSONS_DETAILS]: {
    path: "/deaf/:languageKey/lessons/:id",
    access: [],
  },
  [View.COMMON]: { path: "/common", access: [] },
  [View.COMMON_WELCOME]: { path: "/common/welcome", access: [] },
}) as CombinedRoutes;

export const getPath = (view: typeof View[keyof typeof View]) =>
  MAPPING[view] ? MAPPING[view].path : "";

export const replacePathParams = (
  pathTemplate: string,
  values: any
): string => {
  let path = pathTemplate;

  Object.entries(values).forEach(([key, value]) => {
    const paramKey = `:${key}`;
    path = path.replace(new RegExp(paramKey, "g"), String(value));
  });

  return path;
};

export const getAccess = (view: typeof View[keyof typeof View]) =>
  MAPPING[view] ? MAPPING[view].access : false;

export const PATHS = Object.keys(View)
  .map((key) => ({
    [key]: getPath((View as any)[key]),
  }))
  .reduce(
    (obj, item) => ({
      ...obj,
      [Object.keys(item)[0]]: item[Object.keys(item)[0]],
    }),
    {}
  ) as CombinedRoutes;

export const ACCESSES = Object.keys(View)
  .map((key) => ({
    [key]: getAccess((View as any)[key]),
  }))
  .reduce(
    (obj, item) => ({
      ...obj,
      [Object.keys(item)[0]]: item[Object.keys(item)[0]],
    }),
    {}
  ) as CombinedRoutes;

export const injectPathParams = (route: string, params: any) => {
  let newRoute = route;
  Object.keys(params).forEach(function (key, index) {
    const regex = new RegExp(`:${key}`, "g");
    newRoute = newRoute.replace(regex, params[key]);
  });
  return newRoute as any;
};

export const getCurrentPath = (pathname: string, pathParams: any) => {
  let schema = pathname;

  for (const key in pathParams) {
    const pattern = `:${key}`;
    const value = pathParams[key];

    schema = schema.replace(value, pattern);
  }

  return schema;
};
