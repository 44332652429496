import React from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { Text } from "../../../../../components/text/Text";

export const NotificationsToggle = ({
  label,
  value,
  onChange,
}: {
  label: string;
  value: boolean | undefined;
  onChange: (e: any) => void;
}) => {
  const theme = useTheme();
  const { black1 } = theme.colors;
  const { big } = theme.fontSizes;

  return (
    <Wrapper>
      <Text weight={"500"} size={big} color={black1} margin={"0 0 18px 0"}>
        {label}
      </Text>
      {typeof value === "boolean" && (
        <StyledToggle
          defaultChecked={value}
          icons={false}
          onChange={onChange}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledToggle = styled(Toggle)`
  &.react-toggle--checked .react-toggle-track {
    background-color: ${(props: any) => props.theme.colors.blue};
  }

  .react-toggle-track:hover {
    background-color: ${(props: any) => props.theme.colors.blue};
  }

  &.react-toggle--checked .react-toggle-thumb {
    border-color: ${(props: any) => props.theme.colors.blue};
  }

  &.react-toggle--checked:hover:not(.react-toggle-disabled)
    .react-toggle-track {
    background-color: ${(props: any) => props.theme.colors.blue};
  }

  .react-toggle-track {
    background-color: ${(props: any) => props.theme.colors.black1};
  }
`;
