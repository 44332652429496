import React, { useEffect, useState } from "react";
// @ts-ignore
import styled from "@xstyled/styled-components";
import { HomeItem } from "./components/homeItem/HomeItem";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultLang,
  selectLanguage,
  selectLanguageStrings,
  selectLanguagesList,
  setLanguage,
} from "../../app/state/appSlice";
import { Settings } from "./components/settings/Settings";
import { View } from "../../routes/routeInterfaces";
import { MenuItem, MenuItemType } from "./components/menuItem/MenuItem";
import { DeafView, UserView } from "../../enums/View";
import { useCustomHistory } from "../../utils/react-router-dom-abstraction";
import { LanguageModal } from "../../containers/common/welcome/components/languageModal/LanguageModal";
import ls from "../../utils/ls";
import { LsValueType } from "../../enums/LsValueType";
import { media } from "../../styles/media";
import { useWindowSize } from "../../styles/style.context";
import { Burger } from "./components/burger/Burger";
import { MobileMenu } from "./components/mobileMenu/MobileMenu";
import { useLocation } from "react-router-dom";
import { useBlockBodyScroll } from "../../utils/useBlockBodyScroll";
import { scrollToTop } from "../../utils/scroll";
import { getPath } from "../../routes";
import { replacePathParams } from "../../routes/paths";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  &.scrolled {
    background-color: white;
    border-bottom: 1px solid;
    border-color: rgb(228, 228, 235);
  }
`;
const CenteredWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 66px 58px 0 58px;
  &.scrolled {
    padding: 22px 58px;
  }
  ${media.tablet`
    padding: 16px !important;
  `}
`;
const MenuItemsWrapper = styled.div`
  display: flex;
  ${media.tablet`
    display: none;
  `}
`;

export const Header = ({
  menuArr,
}: {
  menuArr: {
    id: string;
    label: string;
    to: UserView | DeafView;
  }[];
}) => {
  const [isOpenLanguageModal, setIsOpenLanguageModal] = useState(false);
  const [isOpenMobileMenu, setIsOpenModalMobileMenu] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const windowWidth = useWindowSize()[0];
  const currentUrl = useLocation().pathname;

  const languageStrings = useSelector(selectLanguageStrings);
  const langId = useSelector(selectLanguage).id;
  const pathArr = currentUrl.split("/");
  const isDeaf = currentUrl.includes("deaf");
  useBlockBodyScroll(isOpenMobileMenu || isOpenLanguageModal);

  const getView = () => {
    const view = Object.keys(View).find(
      (el: string) =>
        el.toLocaleLowerCase().includes(`${pathArr[1]}`) &&
        (el.toLocaleLowerCase().includes(`${pathArr[3]}`) ||
          el.toLocaleLowerCase().includes(`${pathArr[2]}`))
    );
    return view;
  };
  const currentView = getView() as UserView | DeafView;

  const homeRoute = currentUrl.includes("user")
    ? View.USER_HOME
    : View.DEAF_HOME;
  const homePath = getPath(homeRoute);
  const homeHref = replacePathParams(homePath, {
    languageKey: langId,
  });
  const isScrolled =
    windowWidth < 821 || scrollY > 130 || !currentUrl.includes("home");
  const history = useCustomHistory();
  const dispatch = useDispatch();
  const languagesArr = useSelector(selectLanguagesList);

  const languagePickerOnClick = () => {
    setIsOpenLanguageModal(true);
  };
  const deafIconOnClick = () => {
    history.push(isDeaf ? View.USER_HOME : View.DEAF_HOME, {
      pathParams: { languageKey: langId },
    });
    scrollToTop();
  };
  const personalInfoOnClick = () => {
    history.push(View.USER_PERSONAL_INFO, {
      pathParams: { languageKey: langId, code: null },
    });
  };

  const closeLanguageModal = () => {
    setIsOpenLanguageModal(false);
  };
  const handleSetLanguage = (
    language: { name: string; id: string },
    index: number
  ) => {
    const route = currentView;

    if (index < 10) {
      scrollToTop();
      ls.set(LsValueType.locale, language);
      dispatch(setLanguage(language));
      setIsOpenLanguageModal(false);
      history.push(route, {
        pathParams: {
          languageKey: language.id,
          currentPage: 1,
          searchValue: "empty_search",
        },
      });
    } else {
      scrollToTop();
      ls.set(LsValueType.locale, defaultLang);
      dispatch(setLanguage(defaultLang));
      setIsOpenLanguageModal(false);
      history.push(View.DEAF_HOME, {
        pathParams: {
          languageKey: defaultLang.id,
          currentPage: 1,
          searchValue: "empty_search",
        },
      });
    }
  };
  const handleScroll = () => {
    setScrollY(window.scrollY);
  };
  const handleBurgerOnClick = () => {
    setIsOpenModalMobileMenu((el) => !el);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <Wrapper className={isScrolled ? "scrolled" : ""}>
      <CenteredWrapper className={isScrolled ? "scrolled" : ""}>
        <HomeItem
          href={homeHref}
          languageStrings={languageStrings}
          isScrolled={isScrolled}
          isActive={currentUrl.includes("home")}
        />
        <Burger
          onClick={handleBurgerOnClick}
          isOpenMobileMenu={isOpenMobileMenu}
          languageStrings={languageStrings}
        />
        <MenuItemsWrapper>
          {menuArr.map((item: MenuItemType, index: number) => {
            const { id, label, to } = item;
            const isActive = pathArr[3] === id;
            const path = getPath(to);
            const href = replacePathParams(path, {
              languageKey: langId,
              currentPage: 1,
              searchValue: "empty_search",
            });

            return (
              <MenuItem
                key={id}
                className={index === menuArr.length - 1 ? "last" : ""}
                label={label}
                href={href}
                isScrolled={isScrolled}
                isActive={isActive}
              />
            );
          })}
        </MenuItemsWrapper>
        <Settings
          languagePickerOnClick={languagePickerOnClick}
          isOpenMobileMenu={isOpenMobileMenu}
          deafIconOnClick={deafIconOnClick}
          personalInfoOnClick={personalInfoOnClick}
          isScrolled={isScrolled}
          isDeaf={isDeaf}
        />
        <LanguageModal
          isOpenModal={isOpenLanguageModal}
          closeModal={closeLanguageModal}
          handleSetLanguage={handleSetLanguage}
          languagesList={languagesArr}
          languageStrings={languageStrings}
        />
        <MobileMenu
          menuArr={menuArr}
          isOpenMobileMenu={isOpenMobileMenu}
          languageStrings={languageStrings}
        />
      </CenteredWrapper>
    </Wrapper>
  );
};
