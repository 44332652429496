import React from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { Text } from "../../../../../components/text/Text";
import { Button } from "../../../../../components/button/Button";
import { Input } from "../../../../../components/input/Input";
import { ReactComponent as EditIcon } from "../../../../../assets/edit-icon.svg";
import { media } from "../../../../../styles/media";
import { useSelector } from "react-redux";
import { selectLanguageStrings } from "../../../../../app/state/appSlice";

export const PersonalInformationSection = ({
  label,
  isEditInProcess,
  type,
  value,
  placeholder,
  errorMessage,
  additionalInputs,
  buttonsArr,
  validatingIsStarted,
  isNewPasswordValid,
  isValid,
  onChange,
  editOnClick,
  getErrorMessage,
  isDisable,
}: {
  label: string;
  isEditInProcess: boolean;
  type: string;
  value: string;
  placeholder: string;
  errorMessage: any;
  additionalInputs: any[];
  buttonsArr: any[];
  validatingIsStarted: boolean;
  isNewPasswordValid: boolean;
  isValid: (value: string) => boolean;
  onChange: (value: string) => void;
  editOnClick: (label: string) => void;
  getErrorMessage: (
    value: string,
    inputType: string,
    isValid: (value: string) => boolean
  ) => any;
  isDisable: boolean;
}) => {
  const theme = useTheme();
  const { black1 } = theme.colors;
  const { big } = theme.fontSizes;

  const languageStrings = useSelector(selectLanguageStrings);

  const isNameDisplayed = isDisable
    ? label === (languageStrings ? languageStrings.passwordLabel : "Password")
    : true;

  return (
    <InfoItemWrapper>
      {isNameDisplayed && (
        <NameWrapper>
          <Text weight={"500"} size={big} color={black1}>
            {label}
          </Text>
          {!isEditInProcess && (
            <Edit
              onClick={() => {
                editOnClick(label);
              }}
            />
          )}
        </NameWrapper>
      )}

      <InputWrapper>
        {!isDisable && (
          <StyledInput
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            disabled={!isEditInProcess}
            errorMessage={errorMessage}
          />
        )}

        {isEditInProcess && (
          <>
            {additionalInputs &&
              additionalInputs.map((additionalInput: any, index: number) => {
                const additionalInputErrorMessage =
                  validatingIsStarted &&
                  getErrorMessage(
                    additionalInput.value,
                    additionalInput.type,
                    additionalInput.isValid
                  );

                return (
                  <StyledInput
                    key={index}
                    type={"password"}
                    value={additionalInput.value}
                    onChange={additionalInput.onChange}
                    placeholder={additionalInput.placeholder}
                    errorMessage={additionalInputErrorMessage}
                  />
                );
              })}
            <ActionsWrapper>
              {buttonsArr.map((button: any, index: number) => {
                const {
                  buttonValue,
                  onClick,
                  color,
                  backgroundColor,
                  borderColor,
                  hoverColor,
                  hoverBackgroundColor,
                  borderColorHover,
                } = button;
                return (
                  <Button
                    key={index}
                    value={buttonValue}
                    onClick={() => {
                      const isCorrectValue =
                        type === "password"
                          ? isValid(value) && isNewPasswordValid
                          : isValid(value);

                      onClick(isCorrectValue, type);
                    }}
                    color={color}
                    backgroundColor={backgroundColor}
                    borderColor={borderColor}
                    hoverColor={hoverColor}
                    hoverBackgroundColor={hoverBackgroundColor}
                    borderColorHover={borderColorHover}
                  />
                );
              })}
            </ActionsWrapper>
          </>
        )}
      </InputWrapper>
    </InfoItemWrapper>
  );
};

const InfoItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const NameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 18px;
`;

const InputWrapper = styled.div`
  width: 100%;
`;

const StyledInput = styled(Input)`
  width: 50%;

  & > input {
    width: 100%;
    box-shadow: none;
  }

  ${media.tabletS`
    width: 100%;
  `}
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 24px;

  ${media.tabletS`
    width: 100%;
    flex-direction: column;

    & > button {
        width: 100%;
    }
  `}
`;

const Edit = styled(EditIcon)`
  width: 24px;
  height: 24px;
  margin-left: 8px;
  cursor: pointer;

  & > g > path {
    transition: all 0.3s linear;
  }

  &:hover > g > path {
    stroke: ${(props: any) => props.theme.colors.blue};
    transition: all 0.3s linear;
  }
`;
