import React from "react";
// @ts-ignore
import styled from "@xstyled/styled-components";
import { useSelector } from "react-redux";
import {
  selectIsLoggedIn,
  selectLanguageStrings,
} from "../../../app/state/appSlice";
import { Purpose } from "./components/purpose/Purpose";
import { AboutUs } from "./components/aboutUs/AboutUs";
import { Sections } from "./components/sections/Sections";
import { NavIcons } from "../../../components/navIcons/NavIcons";
import { useLocation } from "react-router-dom";
import { userMenuArr } from "../../../layouts/user/User";
import { deafMenuArr } from "../../../layouts/deaf/Deaf";
import { SubscribeOnUs } from "./components/subscribeOnUs/SubscribeOnUs";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Home = ({ ...props }) => {
  const languageStrings = useSelector(selectLanguageStrings);

  const currentUrl = useLocation().pathname;
  const isDeaf = currentUrl.includes("deaf");
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const menuArr = isDeaf
    ? deafMenuArr(languageStrings).map((item: any) => {
        const { id, label, to } = item;
        return {
          id,
          title: label,
          image: () => (
            <NavIcons label={label} languageStrings={languageStrings} />
          ),
          to,
        };
      })
    : userMenuArr(languageStrings).map((item: any) => {
        const { id, label, to } = item;
        return {
          id,
          title: label,
          image: () => (
            <NavIcons label={label} languageStrings={languageStrings} />
          ),
          to,
        };
      });

  return (
    <Wrapper>
      <Purpose languageStrings={languageStrings} />
      <AboutUs languageStrings={languageStrings} />
      <Sections sectionsList={menuArr} />
      {!isLoggedIn && <SubscribeOnUs languageStrings={languageStrings} />}
    </Wrapper>
  );
};
