import React from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import background from "../../../../../assets/HomeBackground.png";
import { Text } from "../../../../../components/text/Text";
import { media } from "../../../../../styles/media";
import { useMediaType } from "../../../../../styles/style.context";

const Wrapper = styled.div`
  width: 100%;
  height: 712px;
  background-image: url(${(props: any) => props.backgroundImage});
  background-size: cover;
  background-position: center;
`;
const BluredWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
`;
const CenteredWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 112px;
  ${media.desktop`
      padding: 0 88px;
  `}
  ${media.tablet`
      padding: 0 64px;
  `}
  ${media.phone`
      padding: 0 16px;
  `}
`;

export const Purpose = ({ languageStrings }: { languageStrings?: any }) => {
  const mediaType = useMediaType();
  const theme = useTheme();

  const { large, big, small, extraSmall } = theme.fontSizes;
  const { white } = theme.colors;
  const { phone } = mediaType;

  return (
    <Wrapper backgroundImage={background}>
      <BluredWrapper>
        <CenteredWrapper>
          <Text
            weight={"500"}
            size={phone ? big : large}
            margin={phone ? `0 0 ${extraSmall} 0` : `0 0 ${big} 0`}
            color={white}
            textAlign={"center"}
          >
            {languageStrings
              ? languageStrings.homePurposeTitle
              : "Grow in your christian faith"}
          </Text>
          <Text
            weight={"400"}
            size={phone ? small : big}
            color={white}
            textAlign={"center"}
          >
            {languageStrings
              ? languageStrings.homePurposeDescription
              : "Find all the resources you need to help you grow."}
          </Text>
        </CenteredWrapper>
      </BluredWrapper>
    </Wrapper>
  );
};
