import React, { ReactNode, useEffect, useState } from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../../components/input/Input";
import { Text } from "../../components/text/Text";
import {
  selectIsSearchDataLoading,
  selectLanguage,
  selectLanguageStrings,
  selectSearchInputValue,
  setSearchInputValue,
} from "../../app/state/appSlice";
import { Button } from "../../components/button/Button";
import { ReactComponent as SearchIcon } from "../../assets/search_icon.svg";
import {
  selectPaginationStep,
  setBottomOffset,
  setContent,
  setCurrentPage,
  setIsSearchContentVisible,
  setOffset,
  selectContent,
} from "../../app/state/contentSlice";
import { useLocation } from "react-router-dom";
import { useCustomHistory } from "../../utils/react-router-dom-abstraction";
import { DeafView, UserView } from "../../enums/View";
import { media } from "../../styles/media";
import { useMediaType } from "../../styles/style.context";
import { setViews } from "../../api/content";
import ls from "../../utils/ls";
import { LsValueType } from "../../enums/LsValueType";

export const Wrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  position: relative;
`;
export const CenteredWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 131px 58px 0 58px;

  ${media.tablet`
    padding: 88px 16px 0 16px
  `}
`;
const InputWrapper = styled.div`
  display: flex;

  ${media.tablet`
    width: 100%;
  `}

  ${media.tabletS`
    & > div {
      width: calc(100% - 56px);
    }
  `}
`;
const SearchButton = styled(Button)`
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  margin-left: 16px;
  & > svg {
    width: 20px;
    height: 20px;
    margin-left: 6px;
    & > path {
      transition: all 0.3s linear;
    }
  }
  &.disabled {
    user-select: none;
    cursor: auto;
    &:hover {
      background-color: white;
      & > svg > path {
        fill: ${(props: any) => props.theme.colors.blue};
      }
    }
  }
  &:hover {
    & > svg > path {
      fill: white;
      transition: all 0.3s linear;
    }
  }

  ${media.tabletS`
    width: 40px;
    padding: 0;

    & > svg {
      margin: 0;
    }
  `}
`;

export const PageWrapper = ({
  searchedDataOrdered,
  searchedDataFull,
  currentView,
  detailsView,
  category,
  children,
  title,
  ...props
}: {
  currentView: UserView | DeafView;
  detailsView: UserView | DeafView;
  searchedDataOrdered?: any[];
  searchedDataFull: any[];
  children: ReactNode;
  category: string;
  title: string;
  className?: string;
}) => {
  const languageStrings = useSelector(selectLanguageStrings);
  const history = useCustomHistory();
  const mediaType = useMediaType();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { blue, white } = theme.colors;
  const { large, common } = theme.fontSizes;
  const { tabletS } = mediaType;

  const [isSearchButtonAvailable, setIsSearchButtonAvailable] = useState(false);
  const isSearchedDataLoading = useSelector(selectIsSearchDataLoading);
  const searchInputValue = useSelector(selectSearchInputValue);
  const langId = useSelector(selectLanguage).id;
  const paginationStep = useSelector(selectPaginationStep);
  const pathArr = useLocation().pathname.split("/");
  const content = useSelector(selectContent);
  const searchValueFromPath = pathArr[5];
  const currentPage = +pathArr[4];

  const searchButtonOnClick = () => {
    if (isSearchButtonAvailable) {
      history.push(currentView, {
        pathParams: {
          languageKey: langId,
          currentPage: 1,
          searchValue: searchInputValue,
        },
      });
      dispatch(setContent({ value: searchedDataOrdered }));
      dispatch(setBottomOffset({ value: 0 }));
      dispatch(setOffset({ value: paginationStep }));
      dispatch(setIsSearchContentVisible({ value: true }));
      setIsSearchButtonAvailable(false);
    }
  };
  const searchInputOnChange = (value: string) => {
    dispatch(setSearchInputValue({ value }));
    !isSearchButtonAvailable &&
      value.length > 0 &&
      setIsSearchButtonAvailable(true);
  };
  const clearInputValue = () => {
    searchInputOnChange("");
    dispatch(setIsSearchContentVisible({ value: false }));
    setIsSearchButtonAvailable(false);
    history.push(currentView, {
      pathParams: {
        languageKey: langId,
        currentPage: 1,
        searchValue: "empty_search",
      },
    });
  };
  const searchInputOnBlur = () => {
    searchInputValue === "" &&
      history.push(currentView, {
        pathParams: {
          languageKey: langId,
          currentPage,
          searchValue: "empty_search",
        },
      });
    searchedDataFull.length === 0 && clearInputValue();
  };
  const searchItemOnClick = (e: any, id: string) => {
    const detailsItem = content.filter((item: any) => item.id === id)[0];
    const isCmdOrCtrlPressed = e.metaKey || e.ctrlKey;
    ls.set(LsValueType.currentPage, currentPage);

    !isCmdOrCtrlPressed && setViews(category, detailsItem.short_id);
  };

  useEffect(() => {
    dispatch(setCurrentPage(currentPage));
  }, [currentPage, dispatch]);

  useEffect(() => {
    searchValueFromPath === "empty_search" && searchInputOnChange("");
  }, [searchValueFromPath]);

  useEffect(() => {
    if (
      searchValueFromPath !== "empty_search" &&
      searchValueFromPath !== searchInputValue
    ) {
      dispatch(setIsSearchContentVisible({ value: true }));
      searchInputOnChange(searchValueFromPath);

      //sds
      dispatch(setContent({ value: searchedDataOrdered }));
      dispatch(setBottomOffset({ value: 0 }));
      dispatch(setOffset({ value: paginationStep }));
    } else {
      dispatch(setIsSearchContentVisible({ value: false }));
    }
  }, []);

  useEffect(() => {
    if (searchInputValue.length > 0 && searchedDataFull.length === 0) {
      setIsSearchButtonAvailable(false);
    } else if (searchInputValue.length > 0 && searchedDataFull.length > 0) {
      setIsSearchButtonAvailable(true);
    }
  }, [searchInputValue, searchedDataFull]);

  return (
    <Wrapper {...props}>
      <CenteredWrapper>
        <Text
          weight={"500"}
          size={large}
          color={blue}
          margin={`0 0 ${large} 0`}
        >
          {title}
        </Text>
        <InputWrapper>
          <Input
            searchItemOnClick={searchItemOnClick}
            value={searchInputValue}
            onChange={searchInputOnChange}
            clearValue={clearInputValue}
            onBlur={searchInputOnBlur}
            placeholder={
              languageStrings
                ? languageStrings.searchInputPlaceholder
                : "What do you want to find?"
            }
            isLoadingInProcess={isSearchedDataLoading}
            searchListPlaceholder={
              languageStrings
                ? languageStrings.contentListPlaceholder
                : "No results"
            }
            searchedData={searchedDataFull}
            detailsView={detailsView}
          />
          <SearchButton
            value={
              tabletS
                ? ""
                : languageStrings
                ? languageStrings.searchButtonLabel
                : "Search"
            }
            onClick={searchButtonOnClick}
            width={"140px"}
            backgroundColor={white}
            borderColor={blue}
            color={blue}
            hoverColor={isSearchButtonAvailable ? white : blue}
            fontSize={common}
            className={!isSearchButtonAvailable ? "disabled" : ""}
          >
            <SearchIcon />
          </SearchButton>
        </InputWrapper>
        {children}
      </CenteredWrapper>
    </Wrapper>
  );
};
