import { GeneralView, UserView, DeafView, CommonView } from "../enums/View";

export const View = {
  ...GeneralView,
  ...UserView,
  ...DeafView,
  ...CommonView,
};

export type View = typeof View;

export type CombinedRoutes = {
  [key in keyof View]: any;
};

export type GeneralRoutes = {
  [key in keyof GeneralView]: any;
};

export type UserRoutes = {
  [key in UserView]: any;
};
export type DeafRoutes = {
  [key in DeafView]: any;
};
