import React, { useState } from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { Text } from "../../../../../components/text/Text";
import { Button } from "../../../../../components/button/Button";
import { media } from "../../../../../styles/media";
import { useMediaType } from "../../../../../styles/style.context";
import {
  ESignUpLoginModalType,
  SignUpLoginModal,
} from "../../../../../components/signUpLogInModal/SignUpLoginModal";
import { useBlockBodyScroll } from "../../../../../utils/useBlockBodyScroll";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const CenteredWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 112px;
  ${media.tablet`
    padding: 0 32px;
  `}
  ${media.phone`
    padding: 0 16px;
  `}
`;
const StyledButton = styled(Button)`
  ${media.phone`
        font-size: ${(props: any) => props.theme.fontSizes.common};
        margin: ${(props: any) => `0 0 ${props.theme.fontSizes.common} 0`};
  `}
`;
export const SubscribeOnUs = ({
  languageStrings,
}: {
  languageStrings?: any;
}) => {
  const [
    signModalType,
    setSignModalType,
  ] = useState<ESignUpLoginModalType | null>(null);

  const mediaType = useMediaType();
  const theme = useTheme();
  const { blue, blue1, black3 } = theme.colors;
  const { large, big, common } = theme.fontSizes;
  const { phone } = mediaType;

  const subscribeButtonOnClick = () => {
    setSignModalType(ESignUpLoginModalType.SIGN_UP);
  };

  const closeSignUpModal = () => {
    setSignModalType(null);
  };

  useBlockBodyScroll(!!signModalType);

  return (
    <Wrapper>
      <CenteredWrapper>
        <Text
          size={phone ? big : large}
          weight={"500"}
          color={blue}
          margin={phone ? `0 0 ${common} 0` : `0 0 ${big} 0`}
          textAlign={"center"}
        >
          {languageStrings
            ? languageStrings.subscribeTitle
            : "Sign up to recieve email notifications"}
        </Text>
        <Text
          size={phone ? common : big}
          weight={"400"}
          color={black3}
          margin={phone ? `0 0 ${common} 0` : `0 0 ${big} 0`}
          textAlign={"center"}
        >
          {languageStrings
            ? languageStrings.subscribeDescription
            : "Be the first to know when we upload new content"}
        </Text>
        <StyledButton
          onClick={subscribeButtonOnClick}
          value={languageStrings ? languageStrings.signUpButton : "Sign me up!"}
          width={"248px"}
          height={"40px"}
          fontSize={common}
          margin={"0 0 64px 0"}
          hoverBackgroundColor={blue1}
        />
      </CenteredWrapper>
      <SignUpLoginModal
        type={signModalType}
        setType={setSignModalType}
        isOpenModal={!!signModalType}
        closeModal={closeSignUpModal}
      />
    </Wrapper>
  );
};
