import Common from "../layouts/common/Common";
import Deaf from "../layouts/deaf/Deaf";
import User from "../layouts/user/User";
import { PATHS } from "./paths";
import { Welcome } from "../containers/common/welcome/Welcome";
import { Home } from "../containers/user/home/Home";
import UserSwitcher from "./UserSwitcher";
import { Books } from "../containers/user/books/Books";
import { Audiobooks } from "../containers/user/audiobooks/Audiobooks";
import { Movies } from "../containers/user/movies/Movies";
import { Sermons } from "../containers/user/sermons/Sermons";
import { Kids } from "../containers/user/kids/Kids";
import { DeafMovies } from "../containers/deaf/deafMovies/DeafMovies";
import { DeafSermons } from "../containers/deaf/deafSermons/DeafSermons";
import { DeafLessons } from "../containers/deaf/deafLessons/DeafLessons";
import { BooksDetails } from "../containers/user/booksDetails/BooksDetails";
import { AudiobooksDetails } from "../containers/user/audiobooksDetails/AudiobooksDetails";
import { MoviesDetails } from "../containers/user/moviesDetails/MoviesDetails";
import { SermonsDetails } from "../containers/user/sermonsDetails/SermonsDetails";
import { KidsDetails } from "../containers/user/kidsDetails/KidsDetails";
import { DeafMoviesDetails } from "../containers/deaf/deafMoviesDetails/DeafMoviesDetails";
import { DeafSermonsDetails } from "../containers/deaf/deafSermonsDetails/DeafSermonsDetails";
import { DeafLessonsDetails } from "../containers/deaf/deafLessonsDetails/DeafLessonsDetails";
import { PersonalInformation } from "../containers/common/personalInformation/PersonalInformation";

export const routes = [
  {
    path: PATHS.COMMON,
    render: Common,
    routes: [{ path: PATHS.COMMON_WELCOME, component: Welcome }],
  },
  {
    path: PATHS.USER_PERSONAL_INFO,
    render: User,
    routes: [
      { path: PATHS.USER_PERSONAL_INFO, component: PersonalInformation },
    ],
  },
  {
    path: PATHS.USER_KIDS,
    render: User,
    routes: [{ path: PATHS.USER_KIDS, component: Kids }],
  },
  {
    path: PATHS.USER_KIDS_DETAILS,
    render: User,
    routes: [{ path: PATHS.USER_KIDS_DETAILS, component: KidsDetails }],
  },
  {
    path: PATHS.USER_SERMONS,
    render: User,
    routes: [{ path: PATHS.USER_SERMONS, component: Sermons }],
  },
  {
    path: PATHS.USER_SERMONS_DETAILS,
    render: User,
    routes: [{ path: PATHS.USER_SERMONS_DETAILS, component: SermonsDetails }],
  },
  {
    path: PATHS.USER_MOVIES,
    render: User,
    routes: [{ path: PATHS.USER_MOVIES, component: Movies }],
  },
  {
    path: PATHS.USER_MOVIES_DETAILS,
    render: User,
    routes: [{ path: PATHS.USER_MOVIES_DETAILS, component: MoviesDetails }],
  },
  {
    path: PATHS.USER_AUDIOBOOKS,
    render: User,
    routes: [{ path: PATHS.USER_AUDIOBOOKS, component: Audiobooks }],
  },
  {
    path: PATHS.USER_AUDIOBOOKS_DETAILS,
    render: User,
    routes: [
      { path: PATHS.USER_AUDIOBOOKS_DETAILS, component: AudiobooksDetails },
    ],
  },
  {
    path: PATHS.USER_BOOKS,
    render: User,
    routes: [{ path: PATHS.USER_BOOKS, component: Books }],
  },
  {
    path: PATHS.USER_BOOKS_DETAILS,
    render: User,
    routes: [{ path: PATHS.USER_BOOKS_DETAILS, component: BooksDetails }],
  },
  {
    path: PATHS.USER,
    render: User,
    routes: [{ path: PATHS.USER_HOME, component: Home }],
  },
  {
    path: PATHS.DEAF_LESSONS,
    render: Deaf,
    routes: [{ path: PATHS.DEAF_LESSONS, component: DeafLessons }],
  },
  {
    path: PATHS.DEAF_LESSONS_DETAILS,
    render: Deaf,
    routes: [
      { path: PATHS.DEAF_LESSONS_DETAILS, component: DeafLessonsDetails },
    ],
  },
  {
    path: PATHS.DEAF_SERMONS,
    render: Deaf,
    routes: [{ path: PATHS.DEAF_SERMONS, component: DeafSermons }],
  },
  {
    path: PATHS.DEAF_SERMONS_DETAILS,
    render: Deaf,
    routes: [
      { path: PATHS.DEAF_SERMONS_DETAILS, component: DeafSermonsDetails },
    ],
  },
  {
    path: PATHS.DEAF_MOVIES,
    render: Deaf,
    routes: [{ path: PATHS.DEAF_MOVIES, component: DeafMovies }],
  },
  {
    path: PATHS.DEAF_MOVIES_DETAILS,
    render: Deaf,
    routes: [{ path: PATHS.DEAF_MOVIES_DETAILS, component: DeafMoviesDetails }],
  },
  {
    path: PATHS.DEAF,
    render: Deaf,
    routes: [{ path: PATHS.DEAF_HOME, component: Home }],
  },
  {
    path: PATHS.REDIRECT,
    component: UserSwitcher,
  },
];
