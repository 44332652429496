import React from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { Text } from "../../../../../components/text/Text";
import { useWindowSize } from "../../../../../styles/style.context";
import { FlagIcon } from "../../../../../components/flagIcon/FlagIcon";
import { media } from "../../../../../styles/media";

const Wrapper = styled.div`
  width: 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white1;
  padding: 64px;
  &.small {
    display: none;
  }
`;
const LanguagesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px 16px;

  ${media.tablet`
     height: 400px;
     overflow-y: scroll;
     justify-content: flex-start;
  `}
`;
const LanguageItem = styled.div`
  width: 286px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    & > span {
      color: blue;
    }
  }
`;

export const ChooseLanguage = ({
  languagesList,
  handleSetLanguage,
  languageStrings,
}: {
  languagesList: { name: string; id: string }[];
  handleSetLanguage: (
    language: { name: string; id: string },
    index: number
  ) => void;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const windowWidth = useWindowSize()[0];

  const { black1 } = theme.colors;
  const { large, medium } = theme.fontSizes;

  return (
    <Wrapper className={windowWidth < 1432 ? "small" : ""}>
      <Text
        size={large}
        weight={"500"}
        color={black1}
        textAlign={"center"}
        margin={"0 0 48px 0"}
      >
        {languageStrings
          ? languageStrings.chooseALanguage
          : "Choose a language"}
      </Text>
      <LanguagesWrapper>
        {languagesList.map(
          (item: { name: string; id: string }, index: number) => {
            const { name, id } = item;

            return (
              <LanguageItem
                key={id}
                onClick={() => {
                  handleSetLanguage(item, index);
                }}
              >
                <FlagIcon id={id} width={"80px"} />
                <Text
                  size={medium}
                  weight={"400"}
                  color={black1}
                  margin={"0 0 0 16px"}
                >
                  {name}
                </Text>
              </LanguageItem>
            );
          }
        )}
      </LanguagesWrapper>
    </Wrapper>
  );
};
