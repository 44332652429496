import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import {
  selectLanguage,
  selectLanguageStrings,
  selectSearchInputValue,
} from "../../app/state/appSlice";
import { ContentItem } from "../contentItem/ContentItem";
import { Pagination } from "../pagination/Pagination";
import { DeafView, UserView } from "../../enums/View";
import {
  selectContent,
  selectCurrentPage,
  selectIsContentLoading,
  selectIsSearchContentVisible,
  setContent,
} from "../../app/state/contentSlice";
import { Text } from "../text/Text";
import { media } from "../../styles/media";
import { Loader } from "../placeholders/Loader";
import { setViews } from "../../api/content";
import { getPath, replacePathParams } from "../../routes/paths";
import { LsValueType } from "../../enums/LsValueType";
import ls from "../../utils/ls";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 300px;
  position: relative;
`;
const Wrapper = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 64px 32px;
  list-style: none;
  padding: 0;
  margin: 46px 0 0 0;

  ${media.narrowMonitor`
    grid-template-columns: 1fr;
  `}
`;

export const ContentList = ({
  searchedDataOrdered,
  currentView,
  detailsView,
  data,
  category,
}: {
  currentView: UserView | DeafView;
  detailsView: UserView | DeafView;
  searchedDataOrdered?: any[];
  category: string;
  data: any;
}) => {
  const dispatch = useDispatch();
  const isSearchedContentVisible = useSelector(selectIsSearchContentVisible);
  const searchInputValue = useSelector(selectSearchInputValue);
  const languageStrings = useSelector(selectLanguageStrings);
  const langId = useSelector(selectLanguage).id;
  const content = useSelector(selectContent);
  const isContentLoading = useSelector(selectIsContentLoading);
  const currentPage = useSelector(selectCurrentPage);

  const theme = useTheme();

  const { blue } = theme.colors;
  const { large } = theme.fontSizes;

  const preparedData = content.map((item: any) => {
    const {
      id,
      image_url,
      name,
      description,
      release_date,
      download_url,
    } = item;
    return {
      id,
      image_url,
      name,
      description,
      release_date,
      download_url,
      buttonArr: [
        {
          value: languageStrings
            ? languageStrings.downloadPDFButtonValue
            : "Download",
          onClick: () => {
            download_url && window.open(download_url, "_blank");
          },
          withBackground: true,
        },
      ],
    };
  });

  const toDetails = (e: any, id: string) => {
    const detailsItem = content.filter((item: any) => item.id === id)[0];
    const isCmdOrCtrlPressed = e.metaKey || e.ctrlKey;
    ls.set(LsValueType.currentPage, currentPage);

    !isCmdOrCtrlPressed && setViews(category, detailsItem.short_id);
  };

  useEffect(() => {
    if (searchInputValue.length === 0) {
      dispatch(setContent({ value: data }));
    } else {
      isSearchedContentVisible &&
        dispatch(setContent({ value: searchedDataOrdered }));
    }
  }, [data, searchInputValue, searchedDataOrdered, isSearchedContentVisible]);

  return (
    <Container>
      {preparedData.length > 0 ? (
        <>
          <Wrapper>
            {preparedData.map((item: any, index: number) => {
              const path = getPath(detailsView);
              const href = replacePathParams(path, {
                languageKey: langId,
                id: item.id,
              });

              return (
                <ContentItem
                  key={index}
                  data={item}
                  href={href}
                  goToDetails={(e: any) => {
                    toDetails(e, item.id);
                  }}
                />
              );
            })}
          </Wrapper>
          <Pagination
            languageStrings={languageStrings}
            currentView={currentView}
          />
        </>
      ) : (
        <Text weight={"500"} size={large} color={blue}>
          {`${
            languageStrings
              ? languageStrings.comingSoonPlaceholder
              : "Coming soon"
          }...`}
        </Text>
      )}

      {isContentLoading && <Loader />}
    </Container>
  );
};
