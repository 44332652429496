import React from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { Text } from "../../../../../components/text/Text";
import aboutUsImage from "../../../../../assets/aboutUsImage.png";
import { media } from "../../../../../styles/media";
import { useMediaType } from "../../../../../styles/style.context";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 120px 0 120px 0;
  ${media.tablet`
    margin: 60px 0 60px 0;
  `}
`;
const CenteredWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 112px;

  ${media.desktop`
    flex-direction: column;
  `}
  ${media.tablet`
    padding: 0 32px;
  `}
  ${media.phone`
    padding: 0 16px
  `}
`;
const TextWrapper = styled.div`
  width: calc(50% - 40px);
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  ${media.desktop`
    width: 100%;
    margin: 0 0 40px 0;
  `}
`;
const Image = styled.img`
  width: 50%;
  max-width: 595px;
  height: 627px;
  object-fit: cover;
  object-position: 50% 50%;
  ${media.desktop`
    width: 100%;
    max-width: none;
  `}
  ${media.phone`
      height: 360px;
  `}
`;

export const AboutUs = ({ languageStrings }: { languageStrings?: any }) => {
  const mediaType = useMediaType();
  const theme = useTheme();

  const { large, big, medium, common } = theme.fontSizes;
  const { black2, blue } = theme.colors;
  const { phone } = mediaType;

  return (
    <Wrapper>
      <CenteredWrapper>
        <TextWrapper>
          <Text
            color={blue}
            size={phone ? big : large}
            weight={"500"}
            margin={`0 0 ${big} 0`}
          >
            {languageStrings ? languageStrings.aboutUsTitle : "About us"}
          </Text>
          <Text color={black2} size={phone ? common : medium} weight={"400"}>
            {languageStrings
              ? languageStrings.aboutUsDescription
              : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nisl eu hendrerit sem. Rhoncus urna facilisis ipsum aliquam ornare dignissim adipiscing consectetur vulputate. Etiam cursus adipiscing quam sed amet, eget integer lectus. Ipsum a nisi, suspendisse nibh non placerat magna pretium.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nisl eu hendrerit sem. Rhoncus urna facilisis ipsum aliquam ornare dignissim adipiscing consectetur vulputate. Etiam cursus adipiscing quam sed amet, eget integer lectus. Ipsum a nisi, suspendisse nibh non placerat magna pretium."}
          </Text>
        </TextWrapper>
        <Image src={aboutUsImage} alt={"About us image"} />
      </CenteredWrapper>
    </Wrapper>
  );
};
