import React, { useCallback, useEffect, useRef } from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { SearchListItem } from "../searchListItem/SearchListItem";
import { SpinnerCircle } from "../../../../features/animations/SpinnerCircle";
import { Text } from "../../../text/Text";
import { getPath, replacePathParams } from "../../../../routes/paths";
import { selectLanguage } from "../../../../app/state/appSlice";
import { useSelector } from "react-redux";
import { DeafView, UserView } from "../../../../enums/View";

const Wrapper = styled.div`
  width: 100%;
  height: 0px;
  box-sizing: border-box;
  padding: 15px 12px;
  border: 1px solid;
  border-top: none;
  border-color: ${(props: any) => props.theme.colors.black};
  border-radius: 0 0 22px 22px;
  position: absolute;
  top: 0;
  z-index: 10;
  background-color: white;
  opacity: 0;
  transition: all 0.3s linear;

  &.visible {
    height: auto;
    opacity: 1;
    top: 48px;
    border-color: ${(props: any) => props.theme.colors.blue};
  }
`;
const List = styled.ul`
  width: 100%;
  max-height: 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: ${(props: any) => (props.toStart ? "flex-start" : "center")};
  align-items: ${(props: any) => (props.toStart ? "flex-start" : "center")};
  margin: 0;
  padding: 0 12px;
  list-style: none;
  overflow-y: auto;

  &.visible {
    max-height: 443px;
  }
  &.styledScroll {
    &::-webkit-scrollbar {
      width: 4px;
      background: transparent;
      opacity: 0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${(props: any) => props.theme.colors.gray3};
      border-radius: 9px;
      width: 4px;
    }
  }
`;

export const SearchList = ({
  searchedData,
  isResultBlockDisplayed,
  placeholderText,
  setIsSearchListOpen,
  searchItemOnClick,
  isLoadingInProcess,
  detailsView,
}: {
  searchedData: any[];
  isResultBlockDisplayed: boolean;
  placeholderText: string;
  setIsSearchListOpen: React.Dispatch<React.SetStateAction<boolean>>;
  searchItemOnClick: (e: any, id: string) => void;
  detailsView?: UserView | DeafView;
  isLoadingInProcess?: boolean;
}) => {
  const theme = useTheme();
  const { black2 } = theme.colors;
  const { medium } = theme.fontSizes;

  const langId = useSelector(selectLanguage).id;

  const searchedListRef = useRef<any>(null);

  const outsideHandler = useCallback(
    (e: any) => {
      if (
        isResultBlockDisplayed &&
        searchedListRef.current &&
        !searchedListRef.current.contains(e.target)
      ) {
        setIsSearchListOpen(false);
      }
    },
    [isResultBlockDisplayed]
  );

  useEffect(() => {
    window.addEventListener("click", outsideHandler);
    return () => {
      window.removeEventListener("click", outsideHandler);
    };
  }, [outsideHandler]);

  return (
    <>
      <Wrapper
        ref={searchedListRef}
        className={isResultBlockDisplayed ? "visible" : ""}
      >
        <List
          theme={theme}
          className={`${
            searchedData && searchedData.length > 3 ? "styledScroll" : ""
          } ${isResultBlockDisplayed ? "visible" : ""}`}
          toStart={searchedData && searchedData.length > 0}
        >
          {searchedData && searchedData.length > 0 ? (
            <>
              {searchedData.map((item: any, index: number) => {
                const { id, image_url, name, release_date, format } = item;

                const path = detailsView ? getPath(detailsView) : "";
                const href = replacePathParams(path, {
                  languageKey: langId,
                  id,
                });

                return (
                  <SearchListItem
                    key={id}
                    className={index === 0 ? "first" : ""}
                    searchItemOnClick={(e: any) => {
                      searchItemOnClick(e, id);
                    }}
                    imageUrl={image_url}
                    title={name}
                    releaseDate={release_date}
                    additionalInfo={format}
                    href={href}
                  />
                );
              })}
            </>
          ) : (
            <>
              {isLoadingInProcess ? (
                <SpinnerCircle />
              ) : (
                <Text weight={"400"} size={medium} color={black2}>
                  {placeholderText}
                </Text>
              )}
            </>
          )}
        </List>
      </Wrapper>
    </>
  );
};
