import React, { useRef, useState } from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";

import { Text } from "../../../../components/text/Text";
import { CommentItem } from "./components/commentItem/CommentItem";
import {
  CommentForm,
  CommentFormValuesType,
} from "./components/commentForm/CommentForm";
import { useDispatch, useSelector } from "react-redux";
import {
  addComment,
  deleteCommentRedux,
  setComment,
} from "../../../../app/state/contentSlice";
import { media } from "../../../../styles/media";
import { selectIsLoggedIn, selectUser } from "../../../../app/state/appSlice";
import { Button } from "../../../../components/button/Button";
import {
  ESignUpLoginModalType,
  SignUpLoginModal,
} from "../../../../components/signUpLogInModal/SignUpLoginModal";
import { useBlockBodyScroll } from "../../../../utils/useBlockBodyScroll";
import { DeleteModal } from "../../../../containers/common/personalInformation/components/deleteModal/DeleteModal";
import {
  editComment,
  postComment,
  removeComment,
} from "../../../../api/content";
import { useNotifierFunctions } from "../../../../features/notifier2";
import { uniqueId } from "lodash";
import { scrollToBottom } from "../../../../utils/scroll";

export interface CommentType {
  id: string;
  author: { id: string; name: string; email: string };
  comment: string;
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;
const List = styled.ul`
  width: 100%;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: start;
  box-sizing: border-box;
  overflow-y: auto;
  padding: 0 60px;
  margin: 36px 0 12px 0;

  ${media.tabletS`
    padding: 0;
  `}
`;
const WhatDoYouThinkTitle = styled(Text)`
  width: 100%;
  padding-top: 36px;
  border-top: 1px solid;
  border-color: black4;
`;

const CommentExplaination = styled(Text)`
  width: 100%;
  margin-bottom: 40px;
`;

const LoginWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  ${media.tabletS`
    & > button {
      width: 100%;
      height: 40px;
    }
  `}
`;

const CommentsPlaceholderWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 36px;
`;

export const Comments = ({
  data,
  pageId,
  languageStrings,
  contentId,
  shortId,
  category,
}: {
  data: any[];
  pageId: string;
  contentId: string;
  shortId: number;
  category: string;
  languageStrings?: any;
}) => {
  const user = useSelector(selectUser);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [activeMoreItem, setActiveMoreItem] = useState("");
  const [deletedCommentId, setDeletedCommentId] = useState("");
  const [commentFormInitialValue, setCommentFormInitialValue] = useState<
    CommentFormValuesType
  >({
    comment: "",
    author: { id: user.id, name: user.username, email: user.email },
    id: uniqueId(),
  });
  const [savedCommentValue, setSavedCommentValue] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isEditInProcess, setIsEditInProcess] = useState(false);
  const [
    signModalType,
    setSignModalType,
  ] = useState<ESignUpLoginModalType | null>(null);
  const commentInputRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { addNotification } = useNotifierFunctions();
  const { blue, black } = theme.colors;
  const { big, medium } = theme.fontSizes;
  const commentsCount = data?.length;
  const questionSecondPart =
    pageId === "books"
      ? languageStrings
        ? languageStrings.commentsQuestionPartTwoBook
        : "book?"
      : pageId === "audiobooks"
      ? languageStrings
        ? languageStrings.commentsQuestionPartTwoAudiobook
        : "audiobook?"
      : pageId === "movies"
      ? languageStrings
        ? languageStrings.commentsQuestionPartTwoMovie
        : "movie?"
      : pageId === "sermons"
      ? languageStrings
        ? languageStrings.commentsQuestionPartTwoSermon
        : "sermon?"
      : pageId === "lessons"
      ? languageStrings
        ? languageStrings.commentsQuestionPartTwoLesson
        : "lesson?"
      : languageStrings
      ? languageStrings.commentsQuestionPartTwoMaterial
      : "material?";

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const showSuccessMessage = (message: string) => {
    addNotification({
      message: message,
      type: "success",
    });
  };

  const showFailMessage = (errorMessage: string) => {
    addNotification({
      message: errorMessage,
      type: "error",
    });
  };

  const finishEditingProcess = () => {
    setCommentFormInitialValue({ ...commentFormInitialValue, comment: "" });
    setSavedCommentValue("");
    setIsEditInProcess(false);
    setIsFormSubmitted(false);
  };

  const commentOnSubmit = (comment: CommentFormValuesType) => {
    const isCommentChanged =
      savedCommentValue !== commentFormInitialValue.comment;

    if (isEditInProcess) {
      if (isCommentChanged) {
        editComment(
          category,
          shortId,
          comment.id,
          {
            content: comment.comment,
            author: { id: user.id, name: user.username, email: user.email },
          },
          () => {
            dispatch(setComment({ id: comment.id, value: comment }));
            finishEditingProcess();
            showSuccessMessage(
              languageStrings.commentSuccessfullyChangedMessage
            );
          },
          (message: string) => {
            finishEditingProcess();
            showFailMessage(message);
          }
        );
      } else {
        finishEditingProcess();
        showFailMessage(languageStrings.commentWasntChangedError);
      }
    } else {
      postComment(
        category,
        shortId,
        { content: comment.comment },
        (comment) => {
          dispatch(addComment({ id: contentId, comment }));
        },
        () => {
          showSuccessMessage(languageStrings.commentSuccessfullyAddedMessage);
        },
        showFailMessage
      );
    }
  };

  const openLogInModal = () => {
    setSignModalType(ESignUpLoginModalType.SIGN_IN);
  };

  const closeLogInModal = () => {
    setSignModalType(null);
  };

  const moreIconOnClick = (id: string) => {
    setActiveMoreItem(id);

    if (isEditInProcess) {
      finishEditingProcess();
    }
  };

  const closeMorePopup = () => {
    setActiveMoreItem("");
  };

  const editOnClick = (commentId: string, commentValue: string) => {
    scrollToBottom();
    setSavedCommentValue(commentValue);
    setIsEditInProcess(true);
    commentInputRef &&
      commentInputRef.current &&
      commentInputRef.current.focus();
    setCommentFormInitialValue({
      id: commentId,
      author: { id: user.id, name: user.username, email: user.email },
      comment: commentValue,
    });
    setActiveMoreItem("");
  };

  const deleteOnClick = (commentId: string) => {
    setDeletedCommentId(commentId);
    setIsOpenDeleteModal(true);
    setActiveMoreItem("");
  };

  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  const deleteComment = (commentId: string) => {
    removeComment(
      user.id,
      category,
      shortId,
      commentId,
      () => {
        dispatch(deleteCommentRedux({ value: commentId }));
        setDeletedCommentId("");
        closeDeleteModal();
        showSuccessMessage(languageStrings.commentSuccessfullyDeletedMessage);
      },
      (message: string) => {
        finishEditingProcess();
        showFailMessage(message);
      }
    );
  };
  useBlockBodyScroll(!!signModalType);

  return (
    <Wrapper>
      <Text color={blue} size={medium} weight={"500"}>
        {`${
          languageStrings ? languageStrings.commentsLabel : "Comments"
        } (${commentsCount})`}
      </Text>
      {data?.length > 0 ? (
        <List>
          {data.map((item: CommentType, index: number) => (
            <CommentItem
              key={index}
              data={item}
              activeMoreItem={activeMoreItem}
              moreIconOnClick={() => {
                moreIconOnClick(item.id);
              }}
              editOnClick={() => {
                editOnClick(item.id, item.comment);
              }}
              deleteOnClick={() => {
                deleteOnClick(item.id);
              }}
              closeMorePopup={closeMorePopup}
            />
          ))}
        </List>
      ) : (
        <CommentsPlaceholderWrapper>
          <Text size={medium} color={black}>
            {languageStrings
              ? languageStrings.commentsPlaceholder
              : "No comments"}
          </Text>
        </CommentsPlaceholderWrapper>
      )}

      <WhatDoYouThinkTitle size={big} color={blue} margin={"0 0 40px 0"}>
        {`${
          languageStrings
            ? languageStrings.commentsQuestionPartOne
            : "What do you think about the "
        }${questionSecondPart}`}
      </WhatDoYouThinkTitle>
      {isLoggedIn ? (
        <CommentForm
          initialValues={commentFormInitialValue}
          setInitialValues={setCommentFormInitialValue}
          isFormSubmitted={isFormSubmitted}
          setIsFormSubmitted={setIsFormSubmitted}
          onSubmit={commentOnSubmit}
          cancelOnClick={finishEditingProcess}
          inputRef={commentInputRef}
          isEditInProcess={isEditInProcess}
          languageStrings={languageStrings}
        />
      ) : (
        <>
          <CommentExplaination color={black} size={medium} weight={"500"}>
            {languageStrings.commentExplaination}
          </CommentExplaination>
          <LoginWrapper>
            <Button
              type={"button"}
              value={languageStrings ? languageStrings.logInButton : "Log in"}
              width={"242px"}
              fontSize={medium}
              onClick={openLogInModal}
            />
            <SignUpLoginModal
              type={signModalType}
              setType={setSignModalType}
              isOpenModal={!!signModalType}
              closeModal={closeLogInModal}
            />
          </LoginWrapper>
        </>
      )}
      <DeleteModal
        title={
          languageStrings
            ? languageStrings.deleteCommentConfirmation
            : "Are you sure that you want to delete a comment?"
        }
        isOpenModal={isOpenDeleteModal}
        closeModal={closeDeleteModal}
        deleteOnClick={() => {
          deleteComment(deletedCommentId);
        }}
      />
    </Wrapper>
  );
};
