import React from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { Modal } from "../../../../../components/modal/Modal";
import { Text } from "../../../../../components/text/Text";
import { Button } from "../../../../../components/button/Button";
import {
  useMediaType,
  useWindowSize,
} from "../../../../../styles/style.context";
import { media } from "../../../../../styles/media";
import { FlagIcon } from "../../../../../components/flagIcon/FlagIcon";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../../app/state/appSlice";

const LanguagesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px 16px;

  ${media.tablet`
     height: 400px;
    overflow-y: scroll;
    justify-content: flex-start;
  `}
`;
const LanguageItem = styled.div`
  width: 260px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    & > span {
      color: blue;
    }
  }
`;

export const LanguageModal = ({
  isOpenModal,
  closeModal,
  handleSetLanguage,
  languagesList,
  languageStrings,
}: {
  isOpenModal: boolean;
  closeModal: () => void;
  handleSetLanguage: (
    language: { name: string; id: string },
    index: number
  ) => void;
  languagesList: { name: string; id: string }[];
  languageStrings?: any;
}) => {
  const windowWidth = useWindowSize()[0];
  const mediaType = useMediaType();
  const theme = useTheme();

  const currentLanguage = useSelector(selectLanguage);

  const { black, black1, blue1, blue } = theme.colors;
  const { big, large, medium, common } = theme.fontSizes;
  const { phone } = mediaType;

  return (
    <Modal isOpenModal={isOpenModal} closeModal={closeModal}>
      <Text
        size={windowWidth < 600 ? big : large}
        weight={"500"}
        color={black}
        margin={windowWidth < 600 ? `0 0 ${big} 0` : `0 0 ${large} 0`}
      >
        {languageStrings
          ? languageStrings.chooseALanguage
          : "Choose a language"}
      </Text>
      <LanguagesWrapper>
        {languagesList.map(
          (item: { name: string; id: string }, index: number) => {
            const { name, id } = item;

            const isActive = id === currentLanguage.id;

            return (
              <LanguageItem
                key={id}
                onClick={() => {
                  handleSetLanguage(item, index);
                }}
              >
                <FlagIcon id={id} width={windowWidth < 600 ? "60px" : "80px"} />
                <Text
                  size={phone ? common : medium}
                  weight={"400"}
                  color={isActive ? blue : black1}
                  margin={`0 0 0 ${medium}`}
                >
                  {name}
                </Text>
              </LanguageItem>
            );
          }
        )}
      </LanguagesWrapper>
      <Button
        value={languageStrings ? languageStrings.closeButton : "Close"}
        onClick={closeModal}
        width={windowWidth < 600 ? "100%" : "180px"}
        fontSize={windowWidth < 600 ? common : medium}
        margin={windowWidth < 600 ? `${common} 0 0 0` : `${medium} 0 0 0`}
        hoverBackgroundColor={blue1}
      />
    </Modal>
  );
};
