import React, { useEffect } from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { NavIcons } from "../../../navIcons/NavIcons";
import { Text } from "../../../text/Text";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../app/state/appSlice";
import { View, getPath } from "../../../../routes";
import { DeafIcon } from "../settings/components/deafIcon/DeafIcon";
import { CONTACT_US_EMAIL } from "../../../../consts";
import { media } from "../../../../styles/media";
import { useMediaType } from "../../../../styles/style.context";
import { replacePathParams } from "../../../../routes/paths";

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 56px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  position: absolute;
  top: 56px;
  left: 0;
  padding-bottom: 40px;
`;
const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Item = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 26px;
  padding-bottom: 26px;
  border-bottom: 1px solid;
  border-color: gray1;
  text-decoration: none;

  &.first {
    margin-top: 50px;
  }
  &.last {
    border: none;
  }
  & > div {
    margin-right: 8px;
  }
`;
const Constainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.contact-us {
    gap: 6px;
  }

  ${media.tabletS`
    &.contact-us {
      flex-direction: column;
      gap: 8px;
    }
  `}
`;
const LinkWrapper = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;
const DeafImage = styled(DeafIcon)`
  & > svg > path {
    fill: ${(props: any) => props.theme.colors.black1};
  }
  margin-right: 10px;
`;

export const MobileMenu = ({
  isOpenMobileMenu,
  menuArr,
  languageStrings,
}: {
  isOpenMobileMenu: boolean;
  menuArr: any[];
  languageStrings?: any;
}) => {
  const mediaType = useMediaType();
  const theme = useTheme();

  const { blue, black1 } = theme.colors;
  const { medium } = theme.fontSizes;
  const { tabletS } = mediaType;

  const langId = useSelector(selectLanguage).id;

  const homePath = getPath(View.USER_HOME);
  const homeHref = replacePathParams(homePath, {
    languageKey: langId,
  });
  const deafHomePath = getPath(View.DEAF_HOME);
  const deafHomeHref = replacePathParams(deafHomePath, {
    languageKey: langId,
  });

  useEffect(() => {
    const body: any = document.getElementsByTagName("body")[0];
    if (body) {
      body.style.overflow = isOpenMobileMenu ? "hidden" : "visible";
    }
  }, [isOpenMobileMenu]);

  return (
    <>
      {isOpenMobileMenu && (
        <Wrapper>
          <ItemsWrapper>
            {menuArr.map((item: any, index: number) => {
              const { label, to } = item;
              const path = getPath(to);
              const href = replacePathParams(path, {
                languageKey: langId,
                currentPage: 1,
                searchValue: "empty_search",
              });

              return (
                <Item
                  key={index}
                  href={href}
                  className={
                    menuArr.length - 1 === index
                      ? "last"
                      : index === 0
                      ? "first"
                      : ""
                  }
                >
                  <NavIcons
                    label={label}
                    width={"24px"}
                    languageStrings={languageStrings}
                  />
                  <Text color={blue} size={medium} weight={"400"}>
                    {label}
                  </Text>
                </Item>
              );
            })}
          </ItemsWrapper>
          <LinkWrapper href={homeHref}>
            <Text color={black1} size={medium} weight={"500"}>
              {languageStrings
                ? languageStrings.backToHomeLabel
                : "Return to homepage"}
            </Text>
          </LinkWrapper>
          <LinkWrapper href={deafHomeHref}>
            <DeafImage />
            <Text color={black1} size={medium} weight={"500"}>
              {languageStrings ? languageStrings.deafAndDumb : "Deaf & dumb"}
            </Text>
          </LinkWrapper>
          <Constainer className={"contact-us"}>
            <Text color={blue} weight={"400"} size={medium}>
              {`${
                languageStrings ? languageStrings.contactUsLabel : "Contact us"
              }`}
            </Text>
            <Text color={blue} weight={"400"} size={medium}>
              {`${tabletS ? "" : " - "}${CONTACT_US_EMAIL}`}
            </Text>
          </Constainer>
        </Wrapper>
      )}
    </>
  );
};
