import React, { FC } from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { SpinnerCircle } from "../../features/animations/SpinnerCircle";
import { media } from "../../styles/media";
export interface ComponentProps {}

const Background = styled.div`
  position: fixed;
  z-index: 9999999999;
  top: 74px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);

  ${media.tablet`
    top: 56px;
  `}
`;

export interface ComponentProps {
  className?: string;
  withoutLoader?: boolean;
}

export const Loader: FC<ComponentProps> = ({
  withoutLoader = false,
  className,
}) => {
  const theme = useTheme();

  return (
    <Background className={className}>
      {!withoutLoader && <SpinnerCircle backgroundColor={theme.colors.blue} />}
    </Background>
  );
};
