import React from "react";
// @ts-ignore
import styled from "@xstyled/styled-components";

const Svg = styled.svg`
  margin-right: 8px;
`;

export const HomeIcon = () => {
  return (
    <Svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99981 21.6667H6.66647V33.3333C6.66647 35.1717 8.16147 36.6667 9.99981 36.6667H29.9998C31.8381 36.6667 33.3331 35.1717 33.3331 33.3333V21.6667H34.9998C35.3294 21.6666 35.6516 21.5688 35.9256 21.3857C36.1996 21.2025 36.4132 20.9422 36.5393 20.6378C36.6654 20.3333 36.6984 19.9982 36.6341 19.6749C36.5698 19.3517 36.4112 19.0547 36.1781 18.8217L21.1781 3.82166C21.0235 3.66675 20.8398 3.54386 20.6376 3.46C20.4354 3.37615 20.2187 3.33299 19.9998 3.33299C19.7809 3.33299 19.5642 3.37615 19.362 3.46C19.1598 3.54386 18.9761 3.66675 18.8215 3.82166L3.82147 18.8217C3.58846 19.0547 3.42978 19.3517 3.3655 19.6749C3.30122 19.9982 3.33423 20.3333 3.46034 20.6378C3.58646 20.9422 3.80003 21.2025 4.07404 21.3857C4.34806 21.5688 4.67022 21.6666 4.99981 21.6667ZM16.6665 33.3333V25H23.3331V33.3333H16.6665ZM19.9998 7.35666L29.9998 17.3567V25L30.0015 33.3333H26.6665V25C26.6665 23.1617 25.1715 21.6667 23.3331 21.6667H16.6665C14.8281 21.6667 13.3331 23.1617 13.3331 25V33.3333H9.99981V17.3567L19.9998 7.35666Z"
        fill="white"
      />
    </Svg>
  );
};
