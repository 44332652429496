import React, { useState, useRef, useEffect } from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";

import { Text } from "../../../../components/text/Text";
import { media } from "../../../../styles/media";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useMediaType } from "../../../../styles/style.context";

const Wrapper = styled.div`
  margin-bottom: 24px;

  ${media.tabletS`
      margin: 24px 0;
  `}
`;

const Player = styled(AudioPlayer)`
  &.rhap_container {
    margin: 14px 0;
    box-shadow: none;
  }
`;

const TracksList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
`;

const ListItem = styled(Text)`
  cursor: pointer;

  &.active {
    cursor: auto;
    font-weight: 600;
  }

  &:hover {
    font-weight: 600;
  }
`;

export const AudioRunner = ({ data }: { data: any[] }) => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);

  const trackListRef = useRef() as any;
  const mediaType = useMediaType();
  const theme = useTheme();

  const { blue } = theme.colors;
  const { common } = theme.fontSizes;

  const currentTack = data && data[currentTrackIndex];
  const { tabletS } = mediaType;

  const nextTrack = (e: any) => {
    currentTrackIndex < data.length - 1 &&
      setCurrentTrackIndex(currentTrackIndex + 1);
  };

  const prviousTrack = (e: any) => {
    currentTrackIndex > 0 && setCurrentTrackIndex(currentTrackIndex - 1);
  };

  const onEnded = (e: any) => {
    nextTrack(e);
  };

  const listItemOnClick = (e: any, index: number) => {
    setCurrentTrackIndex(index);
  };

  useEffect(() => {
    const activeItem =
      trackListRef &&
      trackListRef.current &&
      new Array(...trackListRef.current.children).filter((item: any) =>
        item.className.includes("active")
      )[0];
    const activeElementMargins = 4 + 4;

    if (activeItem) {
      const topValue =
        currentTrackIndex * (activeItem.offsetHeight + activeElementMargins);
      trackListRef.current.scrollTo({
        behavior: "smooth",
        top: topValue,
      });
    }
  }, [currentTrackIndex, trackListRef]);

  return (
    <Wrapper>
      <Text size={common} margin={`4px 0 0 ${common}`} color={blue}>
        {currentTack?.name}
      </Text>
      <Player
        onClickPrevious={prviousTrack}
        onClickNext={nextTrack}
        onEnded={onEnded}
        src={currentTack?.src}
        showSkipControls={!tabletS}
      />

      {data && data.length > 1 && (
        <TracksList ref={trackListRef}>
          {data.map((item: any, index: number) => {
            return (
              <ListItem
                className={index === currentTrackIndex ? "active" : ""}
                margin={`4px ${common}`}
                size={common}
                color={blue}
                key={index}
                onClick={(e: any) => {
                  listItemOnClick(e, index);
                }}
              >
                {item.name}
              </ListItem>
            );
          })}
        </TracksList>
      )}
    </Wrapper>
  );
};
