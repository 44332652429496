import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { Tooltip, TooltipContextProviderType } from './types';

const DEFAULT_TOOLTIP = {
  openRequests: [],
  closeRequests: [],
} as Tooltip;

const TooltipContext = createContext([DEFAULT_TOOLTIP, null as any] as TooltipContextProviderType);

const useTooltipContextCreator = (): TooltipContextProviderType => {
  const [tooltip, setTooltip] = useState(DEFAULT_TOOLTIP);
  return [tooltip, setTooltip];
};

export const TooltipContextProvider = ({ children }: { children: ReactNode }) => {
  const provider = useTooltipContextCreator();
  const [tooltip, setTooltip] = provider;
  const { openRequests, closeRequests } = tooltip;

  useEffect(() => {
    if (openRequests.length > 0) {
      setTooltip((value) => ({
        ...value,
        openRequests: openRequests.filter((request) => request.id !== openRequests[0].id),
      }));
    }
  }, [openRequests]);

  useEffect(() => {
    if (closeRequests.length > 0) {
      setTooltip((value) => ({
        ...value,
        closeRequests: closeRequests.filter((request) => request.id !== closeRequests[0].id),
      }));
    }
  }, [closeRequests]);

  return <TooltipContext.Provider value={provider}>{children}</TooltipContext.Provider>;
};

export const useTooltipContext = (): TooltipContextProviderType => {
  const service = useContext(TooltipContext);

  if (!service) {
    throw new Error('Tooltip Context is unavailable');
  }

  return service;
};

export const withTooltipContext = (Component: any) => {
  return function WithTooltipContext(props: any) {
    const service = useTooltipContext();

    return <Component {...props} tooltipContext={service} />;
  };
};
