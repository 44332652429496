import React, { useEffect, useState } from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { Modal } from "../modal/Modal";
import { Text } from "../text/Text";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLanguageStrings,
  setUser,
  setIsLoggedIn,
} from "../../app/state/appSlice";
import { Input } from "../input/Input";
import { FieldsType, isValidField } from "../../utils/validation";
import { Button } from "../button/Button";
import { media } from "../../styles/media";
import { useNotifierFunctions } from "../../features/notifier2";
import {
  requestForgotPasswordCode,
  signIn,
  signUp,
} from "../../api/personalInformation";
import { IUserState } from "../../app/state/appInterfaces";

export enum ESignUpLoginModalType {
  SIGN_UP = "SIGN_UP",
  SIGN_IN = "SIGN_IN",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
}

export const SignUpLoginModal = ({
  type,
  setType,
  isOpenModal,
  closeModal,
}: {
  type: ESignUpLoginModalType | null;
  setType: React.Dispatch<React.SetStateAction<ESignUpLoginModalType | null>>;
  isOpenModal: boolean;
  closeModal: () => void;
}) => {
  const [inputValues, setInputValues] = useState<any>(
    type === ESignUpLoginModalType.SIGN_UP
      ? {
          username: "",
          email: "",
          password: "",
        }
      : { email: "", password: "" }
  );
  const [validatingIsStarted, setIsValidatingIsStarted] = useState(false);
  const languageStrings = useSelector(selectLanguageStrings);
  const theme = useTheme();

  const { blue, blue1, black, gray2 } = theme.colors;
  const { common } = theme.fontSizes;

  const { addNotification } = useNotifierFunctions();

  const title =
    type === ESignUpLoginModalType.SIGN_UP
      ? languageStrings?.signUpTitle
      : type === ESignUpLoginModalType.FORGOT_PASSWORD
      ? languageStrings?.resetPasswordTitle
      : languageStrings?.signInTitle;
  const logInQuestion = languageStrings
    ? languageStrings.loginQuestion
    : "Aren't you registered";
  const signUpQuestion = languageStrings
    ? languageStrings.signUpQuestion
    : "Already have account";
  const question =
    type === ESignUpLoginModalType.SIGN_UP ? signUpQuestion : logInQuestion;
  const answerText =
    type === ESignUpLoginModalType.SIGN_UP
      ? languageStrings?.logInButton
      : languageStrings?.signUpButton;
  const submitButtonLabel =
    type === ESignUpLoginModalType.SIGN_UP
      ? languageStrings?.signUpButton
      : type === ESignUpLoginModalType.FORGOT_PASSWORD
      ? languageStrings?.resetPasswordButtonLabel
      : languageStrings?.logInButton;

  const dispatch = useDispatch();

  const inputs = [
    {
      value: inputValues.username,
      label: languageStrings ? languageStrings.userNameLabel : "User name",
      placeholder: languageStrings
        ? languageStrings.userNamePlaceholder
        : "Enter user name",
      inputType: "text",
      isValid: (value: string) => {
        return isValidField(value, FieldsType.TEXT);
      },
      onChange: (value: string) => {
        setInputValues({ ...inputValues, username: value });
      },
      isHidden:
        type === ESignUpLoginModalType.SIGN_IN ||
        type === ESignUpLoginModalType.FORGOT_PASSWORD,
    },
    {
      value: inputValues.email,
      label: languageStrings ? languageStrings.emailLabel : "Email",
      placeholder: languageStrings
        ? languageStrings.emailPlaceholder
        : "Enter email",
      inputType: "text",
      isValid: (value: string) => {
        return isValidField(value, FieldsType.EMAIL);
      },
      onChange: (value: string) => {
        setInputValues({ ...inputValues, email: value });
      },
      isHidden: false,
    },
    {
      value: inputValues.password,
      label: languageStrings ? languageStrings.passwordLabel : "Password",
      placeholder: languageStrings
        ? languageStrings.passwordPlaceholder
        : "Enter password",
      inputType: "password",
      isValid: (value: string) => {
        return isValidField(value, FieldsType.PASSWORD);
      },
      onChange: (value: string) => {
        setInputValues({ ...inputValues, password: value });
      },
      isHidden: type === ESignUpLoginModalType.FORGOT_PASSWORD,
    },
  ];

  const clear = () => {
    setInputValues(
      type === ESignUpLoginModalType.SIGN_IN
        ? { email: "", password: "" }
        : type === ESignUpLoginModalType.FORGOT_PASSWORD
        ? { email: "" }
        : { username: "", email: "", password: "" }
    );
    setIsValidatingIsStarted(false);
  };

  const answerTextOnClick = () => {
    const value =
      type === ESignUpLoginModalType.SIGN_IN
        ? ESignUpLoginModalType.SIGN_UP
        : ESignUpLoginModalType.SIGN_IN;

    clear();
    setType(value);
  };

  const getErrorMessage = (
    value: string,
    inputType: string,
    isValid: (value: string) => boolean
  ) => {
    if (value?.length === 0) {
      return languageStrings.requiredFieldError;
    } else {
      const isFieldValid = isValid(value);
      if (!isFieldValid) {
        return inputType === "password"
          ? languageStrings.passwordIncorrectValue
          : languageStrings.emailIncorrectValue;
      } else return null;
    }
  };

  const close = () => {
    if (type === ESignUpLoginModalType.FORGOT_PASSWORD) {
      setType(ESignUpLoginModalType.SIGN_IN);
    }
    clear();
    closeModal();
  };

  const setUserData = (user: IUserState) => {
    dispatch(setUser(user));
  };

  const showSuccesToastSignUp = () => {
    dispatch(setIsLoggedIn(true));
    addNotification({
      message: languageStrings
        ? languageStrings.emailSuccessMessage
        : "Email is added successfully. You will get news from us!",
      type: "success",
    });
  };

  const showFailureToastSignUp = (errorMessage: string) => {
    if (errorMessage === "Email or Username are already taken") {
      addNotification({
        message: languageStrings
          ? languageStrings.userAlreadyExist
          : "User already exist",
        type: "error",
      });
    } else {
      addNotification({
        message: languageStrings
          ? languageStrings.somethingWentWrongFailureMessage
          : "Something went wrong",
        type: "error",
      });
    }
  };

  const showSuccesToastSignIn = () => {
    dispatch(setIsLoggedIn(true));
    addNotification({
      message: languageStrings
        ? languageStrings.loginSuccessMessage
        : "You successfully authorized, welcome!",
      type: "success",
    });
  };

  const showSuccessToastForgotPassword = () => {
    addNotification({
      message: languageStrings
        ? languageStrings.resetPasswordSuccessMessage
        : "The instruction for reseting password is sent to your email",
      type: "success",
    });
  };

  const showFailureMessage = (errorMessage: string) => {
    addNotification({
      message: errorMessage,
      type: "error",
    });
  };

  const showFailureToastSignIn = (errorMessage: string) => {
    if (errorMessage === "Email or Username are already taken") {
      addNotification({
        message: languageStrings
          ? languageStrings.userAlreadyExist
          : "User already exist",
        type: "error",
      });
    } else {
      addNotification({
        message: errorMessage
          ? errorMessage
          : languageStrings
          ? languageStrings.somethingWentWrongFailureMessage
          : "Something went wrong",
        type: "error",
      });
    }
  };

  const submitOnClick = () => {
    setIsValidatingIsStarted(true);
    const arr = inputs.filter((item) => !item.isHidden);

    const checkArr = arr
      .map((item: any) => {
        const { value, isValid } = item;

        return isValid(value);
      })
      .filter((item) => item === true);

    const isAllFieldsValid = arr.length === checkArr.length;

    if (isAllFieldsValid) {
      if (type === ESignUpLoginModalType.SIGN_UP) {
        signUp(
          inputValues,
          close,
          setUserData,
          showSuccesToastSignUp,
          showFailureToastSignUp
        );
      } else if (type === ESignUpLoginModalType.FORGOT_PASSWORD) {
        requestForgotPasswordCode(
          inputValues,
          showSuccessToastForgotPassword,
          showFailureMessage,
          languageStrings
        );
      } else {
        signIn(
          { identifier: inputValues.email, password: inputValues.password },
          close,
          setUserData,
          showSuccesToastSignIn,
          showFailureToastSignIn,
          languageStrings
        );
      }

      close();
    }
  };

  const forgotPasswordOnClick = () => {
    setType(ESignUpLoginModalType.FORGOT_PASSWORD);
  };

  useEffect(() => {
    setInputValues(
      type === ESignUpLoginModalType.SIGN_UP
        ? {
            username: "",
            email: "",
            password: "",
          }
        : type === ESignUpLoginModalType.FORGOT_PASSWORD
        ? { email: "" }
        : { email: "", password: "" }
    );
  }, [type]);

  return (
    <Modal isOpenModal={isOpenModal} closeModal={close} width={"688px"}>
      <Text size={"24px"} color={blue} margin={"0 0 46px 0"}>
        {title}
      </Text>
      {inputs.map((item: any, index: number) => {
        const {
          value,
          label,
          placeholder,
          inputType,
          isValid,
          onChange,
          isHidden,
        } = item;
        const errorMessage =
          validatingIsStarted && getErrorMessage(value, inputType, isValid);

        return (
          <>
            {!isHidden && (
              <InputWrapper key={index}>
                <Text size={"16px"} margin={"0 0 2px 24px"} color={black}>
                  {label}
                </Text>
                <StyledInput
                  value={value}
                  placeholder={placeholder}
                  errorMessage={errorMessage}
                  onChange={onChange}
                  type={inputType}
                />
              </InputWrapper>
            )}
          </>
        );
      })}
      <StyledButton
        onClick={submitOnClick}
        value={submitButtonLabel}
        width={"248px"}
        height={"40px"}
        fontSize={common}
        margin={"0 0 64px 0"}
        hoverBackgroundColor={blue1}
      />
      {type === ESignUpLoginModalType.SIGN_IN && (
        <ForgotPasswordWrapper>
          <Text onClick={forgotPasswordOnClick} color={gray2}>
            {languageStrings
              ? languageStrings.forgotPasswordLabel
              : "Forgot password?"}
          </Text>
        </ForgotPasswordWrapper>
      )}
      {type !== ESignUpLoginModalType.FORGOT_PASSWORD && (
        <QuestionWrapper>
          <Text color={gray2}>{`${question}? `}</Text>
          <AnswerText onClick={answerTextOnClick} color={blue}>
            {answerText}
          </AnswerText>
        </QuestionWrapper>
      )}
    </Modal>
  );
};

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledInput = styled(Input)`
  width: 100%;

  & > input {
    width: 100%;
    box-shadow: none;
  }
`;

const StyledButton = styled(Button)`
  margin: 0;
  ${media.tabletS`
      width: 100%;
      font-size: ${(props: any) => props.theme.fontSizes.common};
      margin: ${(props: any) => `0 0 ${props.theme.fontSizes.common} 0`};
  `};
`;

const QuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 34px 0 22px 0;
`;

const AnswerText = styled(Text)`
  margin: 0 0 0 4px;
  cursor: pointer;
`;

const ForgotPasswordWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin-top: 34px;

  & > span {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      opacity: 0.7;
    }
  }
`;
