export enum FieldsType {
  EMAIL = "EMAIL",
  PHONE_NUMBER = "PHONE_NUMBER",
  NUMBER = "NUMBER",
  SELECT = "SELECT",
  TEXT = "TEXT",
  CUSTOM = "CUSTOM",
  PASSWORD = "PASSWORD",
}

export const isStringConsistOfSpaces = (str: string) => {
  const regex = /^\s+$/;
  return regex.test(str);
};

export const isValidField = (
  value: string,
  type?: FieldsType,
  condition?: any
) => {
  if (type === FieldsType.CUSTOM) {
    if (condition) {
      const valueForTest =
        typeof value === "object" ? Object.values(value)[0] : value;
      return condition.test(valueForTest);
    } else {
      return true;
    }
  }
  if (type === FieldsType.EMAIL) {
    const re = /^\w+([\.\-\+]\w+)*\w*@(\w+\.)+\w+$/gm;
    return re.test(String(value).toLowerCase());
  }
  if (type === FieldsType.PHONE_NUMBER) {
    const re = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/im;
    return re.test(value);
  }
  if (type === FieldsType.NUMBER) {
    const re = /^\d+$/;
    return re.test(value) && +value > 0;
  }

  if (type === FieldsType.TEXT) {
    const re = /^\s+$/;
    return value && value.length > 0 && !re.test(value);
  }

  if (type === FieldsType.SELECT) {
    const condition = value && value.length > 0 ? true : false;
    return condition;
  }

  if (type === FieldsType.PASSWORD) {
    // Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one digit, and one special character.
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    return value && value.length > 0 && re.test(value);
  }
};
