import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import {
  selectSearchInputValue,
  setIsAvailableToStartSearching,
} from "../../app/state/appSlice";
import { SpinnerCircle } from "../../features/animations/SpinnerCircle";
import { SearchList } from "./components/searchList/SearchList";
import { media } from "../../styles/media";
import { Text } from "../text/Text";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeafView, UserView } from "../../enums/View";
library.add(faEye, faEyeSlash);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  position: relative;
  border-radius: ${(props: any) =>
    props.isResultBlockDisplayed ? "22px" : "50px"};
  ${media.tablet`
    width: 100%;
  `}
`;
const LocalInput = styled.input`
  width: 684px;
  height: ${(props: any) => (props.isResultBlockDisplayed ? "48px" : "40px")};
  box-sizing: border-box;
  border: 1px solid;
  border-color: ${(props: any) =>
    props.isFocused || props.isResultBlockDisplayed
      ? props.theme.colors.blue
      : props.isError
      ? props.theme.colors.red2
      : props.theme.colors.black1};
  border-radius: ${(props: any) =>
    props.isResultBlockDisplayed ? "22px 22px 0 0" : "50px"};
  padding: ${(props: any) => props.padding};
  outline: none;
  font-weight: 500;
  font-size: ${(props: any) => props.theme.fontSizes.common};
  color: black1;
  transition: border-color 0.3s linear;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  position: relative;
  z-index: ${(props: any) => (props.isResultBlockDisplayed ? "0" : "20")};

  &.disabled {
    border: none;
    background-color: ${(props: any) => props.theme.colors.gray1};
  }

  ::placeholder {
    font-weight: 400;
    font-size: ${(props: any) => props.theme.fontSizes.small};
    color: ${(props: any) =>
      props.isError ? props.theme.colors.red2 : props.theme.colors.black3};
    font-style: italic;
  }
  ${media.tablet`
    width: 100%;
  `}
`;
const CloseWrapper = styled.div`
  width: 12px;
  height: ${(props: any) => props.height};
  display: flex;
  align-items: center;
  position: absolute;
  right: 24px;
`;
const StyledCloseIcon = styled(CloseIcon)`
  width: 12px;
  height: 12px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  transition: all 0.3s linear;
`;
const Loader = styled(SpinnerCircle)`
  width: 20px;
  height: 20px;
`;

const StyledEyeIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1000;
  cursor: pointer;
`;

const ErrorMessageText = styled(Text)<any>`
  height: max-content;
  opacity: ${(props: any) => (props.isError ? "1" : "0")};
`;

export const Input = ({
  value,
  placeholder,
  onChange,
  clearValue,
  searchListPlaceholder,
  isLoadingInProcess,
  errorMessage,
  searchedData,
  languageStrings,
  onFocus,
  onBlur,
  searchItemOnClick,
  type,
  disabled,
  detailsView,
  ...props
}: {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  searchListPlaceholder?: string;
  isLoadingInProcess?: boolean;
  clearValue?: () => void;
  errorMessage?: string;
  onFocus?: () => void;
  searchedData?: any[];
  onBlur?: () => void;
  searchItemOnClick?: (e: any, id: string) => void;
  languageStrings?: any;
  type?: string;
  disabled?: boolean;
  detailsView?: UserView | DeafView;
  className?: string;
}) => {
  const theme = useTheme();
  const { red2 } = theme.colors;
  const { small } = theme.fontSizes;
  const [isFocused, setIsFocused] = useState(false);
  const [timer, setTimer] = useState<any>(null);
  const [isSearchListOpen, setIsSearchListOpen] = useState(false);
  const [localInputType, setLocalIputType] = useState(type);
  const searchInputValue = useSelector(selectSearchInputValue);

  const dispatch = useDispatch();
  const isResultBlockDisplayed = isSearchListOpen && !!searchedData;

  const handleSetIsAvailableToStartSearching = (value: boolean) => {
    dispatch(setIsAvailableToStartSearching({ value }));
  };
  const inputOnChange = (value: string) => {
    onChange(value);
    if (value.length > 1 && value.length > searchInputValue.length) {
      setIsSearchListOpen(true);
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        handleSetIsAvailableToStartSearching(true);
      }, 1500);
      setTimer(newTimer);
    }
  };

  const handleOnFocus = () => {
    setIsFocused(true);
    onFocus && onFocus();
  };

  const showHidePassword = () => {
    localInputType === "password"
      ? setLocalIputType("text")
      : setLocalIputType("password");
  };

  const handleOnBlur = () => {
    type === "password" &&
      !disabled &&
      localInputType === "text" &&
      showHidePassword();
    setIsFocused(false);
    onBlur && onBlur();
  };

  const inputOnClick = () => {
    if (!isSearchListOpen && value.length > 1) {
      setIsSearchListOpen(true);
    }
  };

  return (
    <Wrapper isResultBlockDisplayed={isResultBlockDisplayed} {...props}>
      <LocalInput
        className={disabled ? "disabled" : ""}
        type={localInputType ? localInputType : "text"}
        disabled={!!disabled}
        value={value}
        placeholder={placeholder}
        isFocused={isFocused}
        onChange={(e: any) => {
          inputOnChange(e.target.value);
        }}
        onClick={inputOnClick}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        isResultBlockDisplayed={isResultBlockDisplayed}
        padding={
          !!searchedData && value.length > 0 ? "0 50px 0 24px" : "0 24px"
        }
      />
      {!!searchedData && value.length > 0 && (
        <CloseWrapper height={isResultBlockDisplayed ? "48px" : "40px"}>
          {isLoadingInProcess ? (
            <Loader />
          ) : (
            <>
              <StyledCloseIcon onClick={clearValue} />
            </>
          )}
        </CloseWrapper>
      )}
      {type === "password" && !disabled && (
        <StyledEyeIcon
          icon={localInputType === "text" ? "eye-slash" : "eye"}
          onClick={showHidePassword}
        />
      )}
      <ErrorMessageText
        size={small}
        margin={"12px 0 12px 24px"}
        color={red2}
        lineHeight={"20px"}
        isError={!!errorMessage}
      >
        {errorMessage}
      </ErrorMessageText>
      <SearchList
        searchedData={searchedData ? searchedData : []}
        isResultBlockDisplayed={isResultBlockDisplayed}
        isLoadingInProcess={isLoadingInProcess}
        setIsSearchListOpen={setIsSearchListOpen}
        searchItemOnClick={
          searchItemOnClick
            ? searchItemOnClick
            : (e: any, id: string) => console.log("id: ", id)
        }
        placeholderText={
          searchListPlaceholder
            ? searchListPlaceholder
            : languageStrings
            ? languageStrings.contentListPlaceholder
            : "No results"
        }
        detailsView={detailsView}
      />
    </Wrapper>
  );
};
