import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ContentDetails } from "../../../layouts/contentDetails/ContentDetails";
import { selectContentItem } from "../../../app/state/contentSlice";
import { DeafView } from "../../../enums/View";
import { useGetContentDetails } from "../../../api/content";

export const DeafLessonsDetails = () => {
  const commentCategory = "lesson";
  const contentCategory = "lessons";
  const pathArr = useLocation().pathname.split("/");
  const sermonId = pathArr[4];
  useGetContentDetails(sermonId, contentCategory, commentCategory);
  const lessonDetailsData = useSelector(selectContentItem);

  return (
    <ContentDetails
      rootView={DeafView.DEAF_LESSONS}
      data={lessonDetailsData}
      category={commentCategory}
      contentCategory={contentCategory}
    />
  );
};
