import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLanguageStrings,
  selectSearchInputValue,
  setIsSearchedDataLoading,
} from "../../../app/state/appSlice";
import { PageWrapper } from "../../../layouts/pageWrapper/PageWrapper";
import { ContentList } from "../../../components/contentList/ContentList";
import { View } from "../../../routes";
import {
  useGetContent,
  useGetFilteredFullListContent,
  useGetSearchedOrderedContent,
} from "../../../api/content";
import { selectIsSearchContentVisible } from "../../../app/state/contentSlice";

export const Movies = () => {
  const category = "movies";
  const dispatch = useDispatch();

  const setLoadingInProcess = (value: boolean) => {
    dispatch(setIsSearchedDataLoading({ value }));
  };

  const searchInputValue = useSelector(selectSearchInputValue);
  const languageStrings = useSelector(selectLanguageStrings);
  const isSearchedContentVisible = useSelector(selectIsSearchContentVisible);

  const { content } = useGetContent(category);
  const searchedDataOrdered = useGetSearchedOrderedContent(
    category,
    searchInputValue,
    isSearchedContentVisible
  );
  const searchedDataFull = useGetFilteredFullListContent(
    category,
    searchInputValue,
    setLoadingInProcess
  );

  return (
    <PageWrapper
      title={languageStrings ? languageStrings.mooviesLabel : "Moovies"}
      searchedDataOrdered={searchedDataOrdered}
      searchedDataFull={searchedDataFull}
      detailsView={View.USER_MOVIES_DETAILS}
      currentView={View.USER_MOVIES}
      category={category}
    >
      <ContentList
        searchedDataOrdered={searchedDataOrdered}
        currentView={View.USER_MOVIES}
        detailsView={View.USER_MOVIES_DETAILS}
        category={category}
        data={content}
      />
    </PageWrapper>
  );
};
