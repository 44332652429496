import React from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { Text } from "../../../text/Text";

const Wrapper = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    & > span {
      color: ${(props: any) => props.theme.colors.blue1};
    }
  }
`;

export const PaginationControllerButton = ({
  onClick,
  value,
}: {
  onClick: () => void;
  value: string;
}) => {
  const theme = useTheme();

  const { blue } = theme.colors;
  const { common } = theme.fontSizes;

  return (
    <Wrapper onClick={onClick}>
      <Text margin={"0"} size={common} color={blue}>
        {value}
      </Text>
    </Wrapper>
  );
};
