import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { PaginationControllerButton } from "./components/paginationControllerButton/PaginationControllerButton";
import { useCustomHistory } from "../../utils/react-router-dom-abstraction";
import {
  selectContentLength,
  selectCurrentPage,
  selectPagination,
  setCurrentPage,
} from "../../app/state/contentSlice";
import { selectLanguage } from "../../app/state/appSlice";
import { DeafView, UserView } from "../../enums/View";
import { scrollToTop } from "../../utils/scroll";
import { useLocation } from "react-router-dom";
import { Text } from "../text/Text";
import { media } from "../../styles/media";
import { useMediaType } from "../../styles/style.context";
import ls from "../../utils/ls";
import { LsValueType } from "../../enums/LsValueType";

const Wrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: flex-end;
  margin-top: 84px;
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;

  ${media.tabletS`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;
const Placeholder = styled.div`
  width: 114px;

  ${media.tabletS`
    width: 57px;
  `}
`;
export const Pagination = ({
  languageStrings,
  currentView,
}: {
  currentView: UserView | DeafView;
  languageStrings?: any;
}) => {
  const pagination = useSelector(selectPagination);
  const currentPage = useSelector(selectCurrentPage);
  const history = useCustomHistory();
  const currentUrl = useLocation();
  const mediaType = useMediaType();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { medium } = theme.fontSizes;
  const { tabletS } = mediaType;
  const { page, pageCount } = pagination;

  const pathArr = currentUrl.pathname.split("/");
  const searchInputValue = pathArr[5] === "empty_search" ? "" : pathArr[5];
  const currentOffset = +pathArr[4];
  const isNextAvailable = page < pageCount;
  const langId = useSelector(selectLanguage).id;
  const isPrevAvailable = currentOffset > 1;
  const contentLength = useSelector(selectContentLength);

  const handleChangePageOffset = (currentPage: number) => {
    history.push(currentView, {
      pathParams: {
        languageKey: langId,
        currentPage,
        searchValue:
          searchInputValue.length > 0 && contentLength > 0
            ? searchInputValue
            : "empty_search",
      },
    });
    dispatch(setCurrentPage(currentPage));
    scrollToTop();
  };
  const nextButtonOnClick = () => {
    if (isNextAvailable) {
      handleChangePageOffset(currentPage + 1);
      ls.set(LsValueType.currentPage, currentPage + 1);
    }
  };
  const prevButtonOnClick = () => {
    if (isPrevAvailable) {
      handleChangePageOffset(currentPage - 1);
      ls.set(LsValueType.currentPage, currentPage - 1);
    }
  };

  return (
    <Wrapper>
      <Container>
        {isPrevAvailable ? (
          <PaginationControllerButton
            onClick={prevButtonOnClick}
            value={
              languageStrings
                ? languageStrings.paginationPreviousPage
                : "Previous"
            }
          />
        ) : (
          <Placeholder />
        )}
        <Text margin={"0 48px"} size={medium}>
          {`${
            !tabletS
              ? languageStrings
                ? languageStrings.paginationPageLabel
                : "Page"
              : ""
          } ${page} ${
            languageStrings ? languageStrings.paginationOf : "of"
          } ${pageCount}`}
        </Text>
        {isNextAvailable ? (
          <PaginationControllerButton
            onClick={nextButtonOnClick}
            value={
              languageStrings ? languageStrings.paginationNextPage : "Next"
            }
          />
        ) : (
          <Placeholder />
        )}
      </Container>
    </Wrapper>
  );
};
