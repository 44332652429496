import React, { useState } from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import backgroundImage from "../../../../../assets/welcome_background.png";
import { Text } from "../../../../../components/text/Text";
import { Button } from "../../../../../components/button/Button";
import {
  useMediaType,
  useWindowSize,
} from "../../../../../styles/style.context";
import { media } from "../../../../../styles/media";
import { LanguageModal } from "../languageModal/LanguageModal";
import { CONTACT_US_EMAIL } from "../../../../../consts";

const ImageWrapper = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  &.small {
    width: 100%;
  }
`;
const BlackFilter = styled.div`
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  padding: 96px 32px;
`;
const Image = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: 50% 50%;
`;
const TextWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  & > span:first-child {
    max-width: 538px;
    word-break: break-word;
  }
`;
const ChooseButton = styled(Button)`
  ${media.phone`
    width: 100%;
    height: 40px;
    font-size: ${(props: any) => props.theme.fontSizes.medium};   
    margin: 0 0 100px 0;
`}
`;

export const ImageBlock = ({
  languagesList,
  handleSetLanguage,
  languageStrings,
}: {
  languagesList: { name: string; id: string }[];
  handleSetLanguage: (
    language: { name: string; id: string },
    index: number
  ) => void;
  languageStrings?: any;
}) => {
  const [isOpenLanguageModal, setIsOpenLanguageModal] = useState(false);
  const mediaType = useMediaType();
  const theme = useTheme();

  const { white, blue1 } = theme.colors;
  const { extraLarge, large, medium, big } = theme.fontSizes;
  const { phone } = mediaType;
  const windowWidth = useWindowSize()[0];

  const openLanguageModal = () => {
    setIsOpenLanguageModal(true);
  };
  const closeLanguageModal = () => {
    setIsOpenLanguageModal(false);
  };

  return (
    <ImageWrapper
      className={windowWidth < 1432 ? "small" : ""}
      backgroundImage={backgroundImage}
    >
      <Image src={backgroundImage} alt={"IMAGE"} />
      <BlackFilter>
        <TextWrapper>
          <Text
            color={white}
            weight={"700"}
            size={windowWidth < 551 ? large : extraLarge}
            margin={windowWidth > 1431 ? "0 0 164px 0" : "0 0 50px 0"}
          >
            {languageStrings ? languageStrings.welcomeTitle : "Welcome"}
          </Text>
          {windowWidth < 1432 && (
            <ChooseButton
              value={
                languageStrings
                  ? languageStrings.chooseALanguage
                  : "Choose a language"
              }
              onClick={openLanguageModal}
              margin={"0 0 164px 0"}
              width={"360px"}
              height={"50px"}
              fontSize={medium}
              hoverBackgroundColor={blue1}
            />
          )}

          <Text
            color={white}
            weight={"400"}
            size={phone ? medium : big}
            textAlign={"center"}
          >
            {CONTACT_US_EMAIL}
          </Text>
        </TextWrapper>
      </BlackFilter>
      <LanguageModal
        isOpenModal={isOpenLanguageModal}
        closeModal={closeLanguageModal}
        handleSetLanguage={handleSetLanguage}
        languagesList={languagesList}
        languageStrings={languageStrings}
      />
    </ImageWrapper>
  );
};
