import { API_URL } from "./consts";
import { IUserState } from "../app/state/appInterfaces";
import ls from "../utils/ls";
import { LsValueType } from "../enums/LsValueType";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../app/state/appSlice";

export interface ISignUpData {
  username: string;
  email: string;
  password: string;
}

export interface ISignInData {
  identifier: string;
  password: string;
}

export interface INewPasswordData {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

export const signUp = (
  data: ISignUpData,
  closeModal: () => void,
  setUser: (user: IUserState) => void,
  showSuccesToast: () => void,
  showFailureToast: (errorMessge: string) => void
) => {
  fetch(`${API_URL}/auth/local/register`, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" }),
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then(({ jwt, user, error }) => {
      if (!!error) {
        showFailureToast(error.message);
      } else {
        setUser(user);
        ls.set(LsValueType.token, jwt);
        showSuccesToast();
      }

      closeModal();
    })
    .catch((e) => {
      showFailureToast("");
    });
};

export const signIn = (
  data: ISignInData,
  closeModal: () => void,
  setUser: (user: IUserState) => void,
  showSuccesToast: () => void,
  showFailureToast: (errorMessge: string) => void,
  languageStrings: any
) => {
  fetch(`${API_URL}/users?email=${data.identifier}`, {
    method: "GET",
  })
    .then((res) => res.json())
    .then((res) => {
      const isUserExist =
        res.filter((item: any) => item.email === data.identifier).length > 0;

      if (isUserExist) {
        fetch(`${API_URL}/auth/local`, {
          method: "POST",
          headers: new Headers({ "Content-Type": "application/json" }),
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then(({ jwt, user, error }) => {
            if (!!error) {
              showFailureToast(error.message);
            } else {
              setUser(user);
              ls.set(LsValueType.token, jwt);
              showSuccesToast();
            }

            closeModal();
          })
          .catch((e) => {
            showFailureToast(e.message);
          });
      } else {
        showFailureToast(languageStrings.userWithEmailDoesntExist);
      }
    })
    .catch((e) => {
      showFailureToast(e.message);
    });
};

export const useGetUserData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const token = ls.get(LsValueType.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      fetch(`${API_URL}/users/me`, {
        method: "GET",
        headers: new Headers({ Authorization: token ? `Bearer ${token}` : "" }),
      })
        .then((response) => response.json())
        .then((data) => {
          setIsLoading(false);
          dispatch(setUser({ ...data, password: "" }));
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching user data:", error);
        });
    }
  }, [token]);

  return { isLoading };
};

export const setUserData = (
  data: IUserState,
  setLocalData: (data: IUserState) => void,
  showSuccesToast: () => void,
  showFailureToast: (errorMessge: string) => void
) => {
  const token = ls.get(LsValueType.token);
  const { id } = data;
  fetch(`${API_URL}/users/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      setLocalData(data);
      showSuccesToast();
    })
    .catch((error) => {
      showFailureToast(error.message);
    });
};

export const deleteUser = (
  id: number,
  showSuccesToast: (error?: string) => void,
  showFailureToast: (error: string) => void
) => {
  const token = ls.get(LsValueType.token);
  fetch(`${API_URL}/users/${id}`, {
    method: "DELETE",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      ls.remove(LsValueType.token);
      showSuccesToast();
    })
    .catch((error) => {
      showFailureToast(error.message);
    });
};

export const changePassword = (
  data: INewPasswordData,
  showSuccesToast: () => void,
  showFailureToast: (errorMessge: string) => void
) => {
  const token = ls.get(LsValueType.token);
  fetch(`${API_URL}/auth/change-password`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then(({ jwt, user, error }) => {
      if (!!error) {
        showFailureToast(error.message);
      } else {
        setUser(user);
        ls.set(LsValueType.token, jwt);
        showSuccesToast();
      }
    })
    .catch((e) => {
      showFailureToast(e.message);
    });
};

export const requestForgotPasswordCode = (
  value: { email: string },
  showSuccesToast: () => void,
  showFailureToast: (errorMessge: string) => void,
  languageStrings: any
) => {
  const token = ls.get(LsValueType.token);

  fetch(`${API_URL}/users?email=${value.email}`, {
    method: "GET",
  })
    .then((res) => res.json())
    .then((res) => {
      const isUserExist =
        res.filter((item: any) => item.email === value.email).length > 0;

      if (isUserExist) {
        fetch(`${API_URL}/auth/forgot-password`, {
          method: "POST",
          headers: new Headers({
            Authorization: token ? `Bearer ${token}` : "",
            "Content-Type": "application/json",
          }),
          body: JSON.stringify(value),
        })
          .then((res) => res.json())
          .then((res) => {
            const { error } = res;
            if (!!error) {
              showFailureToast(error.message);
            } else {
              showSuccesToast();
            }
          })
          .catch((e) => {
            showFailureToast(e.message);
          });
      } else {
        showFailureToast(languageStrings.userWithEmailDoesntExist);
      }
    })
    .catch((e) => {
      showFailureToast(e.message);
    });
};

export const resetPassword = (
  value: { code: string; password: string; passwordConfirmation: string },
  showSuccesToast: () => void,
  showFailureToast: (errorMessge: string) => void
) => {
  fetch(`${API_URL}/auth/reset-password`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(value),
  })
    .then((res) => res.json())
    .then(({ jwt, user, error }) => {
      if (!!error) {
        showFailureToast(error.message);
      } else {
        setUser(user);
        ls.set(LsValueType.token, jwt);
        showSuccesToast();
      }
    })
    .catch((e) => {
      showFailureToast(e.message);
    });
};
