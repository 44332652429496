import React, { ReactChild } from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { Modal } from "../../../../../components/modal/Modal";
import { Text } from "../../../../../components/text/Text";
import { Button } from "../../../../../components/button/Button";
import { media } from "../../../../../styles/media";
import { useSelector } from "react-redux";
import { selectLanguageStrings } from "../../../../../app/state/appSlice";

export const DeleteModal = ({
  isOpenModal,
  closeModal,
  deleteOnClick,
  title,
  children,
}: {
  isOpenModal: boolean;
  closeModal: () => void;
  deleteOnClick: () => void;
  title: string;
  children?: ReactChild;
}) => {
  const theme = useTheme();

  const { blue, black1, white, red2 } = theme.colors;

  const languageStrings = useSelector(selectLanguageStrings);

  return (
    <StyledModal isOpenModal={isOpenModal} closeModal={closeModal}>
      <Text size={"24px"} color={black1} margin={"0 0 46px 0"}>
        {title}
      </Text>
      {children}
      <ButtonsWrapper>
        <Button
          value={languageStrings ? languageStrings.deleteButtonLabel : "Delete"}
          onClick={deleteOnClick}
          color={red2}
          backgroundColor={"transparent"}
          borderColor={red2}
          hoverColor={white}
          hoverBackgroundColor={red2}
          borderColorHover={red2}
        />
        <Button
          value={languageStrings ? languageStrings.closeButtonLabel : "Close"}
          onClick={closeModal}
          color={blue}
          backgroundColor={"transparent"}
          borderColor={blue}
          hoverColor={white}
          hoverBackgroundColor={blue}
          borderColorHover={blue}
        />
      </ButtonsWrapper>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  & > div {
    width: max-content;
  }

  ${media.tablet`
    & > div {
        width: 100%;
    }
    
  `}
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 12px;

  ${media.tablet`
    flex-direction: column;

    & > button {
        width: 100%;
    }
  `}
`;
