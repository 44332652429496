import React from "react";
// @ts-ignore
import styled from "@xstyled/styled-components";
import { ReactComponent as BooksIcon } from "../../assets/books_icon.svg";
import { ReactComponent as AudioBooksIcon } from "../../assets/audio_books_icon.svg";
import { ReactComponent as MooviesIcon } from "../../assets/movies_icon.svg";
import { ReactComponent as SermonsIcon } from "../../assets/sermons_icon.svg";
import { ReactComponent as KidsIcon } from "../../assets/kids_icon.svg";
import { ReactComponent as LessonsIcon } from "../../assets/lessons_icon.svg";

const Wrapper = styled.div`
  width: ${(props: any) => (props.width ? props.width : "auto")};
  height: ${(props: any) => (props.width ? props.width : "auto")};
  & > svg {
    width: ${(props: any) => (props.width ? props.width : "155px")};
    height: ${(props: any) => (props.width ? props.width : "155px")};
  }
`;

export const NavIcons = ({
  label,
  width,
  languageStrings,
}: {
  label: string;
  width?: string;
  languageStrings?: any;
}) => {
  return (
    <Wrapper width={width}>
      {label === languageStrings?.booksLabel ? (
        <BooksIcon />
      ) : label === languageStrings?.audioBooksLabel ? (
        <AudioBooksIcon />
      ) : label === languageStrings?.mooviesLabel ? (
        <MooviesIcon />
      ) : label === languageStrings?.sermonsLabel ? (
        <SermonsIcon />
      ) : label === languageStrings?.kidsLabel ? (
        <KidsIcon />
      ) : label === languageStrings?.lessonsLabel ? (
        <LessonsIcon />
      ) : (
        <></>
      )}
    </Wrapper>
  );
};
