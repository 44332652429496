import React from "react";
// @ts-ignore
import styled from "@xstyled/styled-components";

import { DeafView, UserView } from "../../../../enums/View";

export type MenuItemType = {
  id: string;
  label: string;
  to: UserView | DeafView;
};

const Item = styled.a`
  text-decoration: none;
  margin-right: 40px;
  font-family: "Poppins", sans-serif;
  color: ${(props: any) =>
    props.isActive
      ? props.theme.colors.blue
      : props.isScrolled
      ? props.theme.colors.black
      : props.theme.colors.white};
  font-size: ${(props: any) => props.theme.fontSizes.medium};
  font-weight: 500;
  cursor: ${(props: any) => (props.isActive ? "auto" : "pointer")};
  transition: all 0.3s ease;

  &:hover {
    color: ${(props: any) => props.theme.colors.blue};
  }

  &.last {
    margin: 0;
  }
`;

export const MenuItem = ({
  label,
  isScrolled,
  isActive,
  href,
  ...props
}: {
  label: string;
  isScrolled: boolean;
  isActive: boolean;
  href: string;
  className?: string;
}) => {
  return (
    <Item href={href} isScrolled={isScrolled} isActive={isActive} {...props}>
      {label}
    </Item>
  );
};
