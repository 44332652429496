import React from "react";
// @ts-ignore
import styled from "@xstyled/styled-components";
import { renderRoutes } from "react-router-config";
import { withRouter } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;

const Common = (props: any) => {
  return <Wrapper>{renderRoutes(props.route.routes)}</Wrapper>;
};

export default withRouter(Common);
