import React, { createContext, useState, useContext, ReactNode } from 'react';
import { Loader, LoaderContextProviderType, LoaderFunctions } from './types';

const DEFAULT_LOADER = { loaderList: [], placeholderList: [] } as Loader;

const LoaderContext = createContext([DEFAULT_LOADER, null as any, null as any] as LoaderContextProviderType);

export const useLoaderContextCreator = (): LoaderContextProviderType => {
  const [loader, setLoader] = useState(DEFAULT_LOADER);
  // @TODO Artem make sure list is a SET (no repeats of keywords)
  const appendKeywordList = (keywords: string[], isPlaceholder: boolean) => {
    setLoader((value: Loader) => {
      const loaderSet = new Set([...value.loaderList, ...keywords]);
      const loaderOption = {
        loaderList: Array.from(loaderSet),
      };
      const placeholderSet = new Set([...value.placeholderList, ...keywords]);
      const placeholderOption = {
        placeholderList: Array.from(placeholderSet),
      };
      const options = isPlaceholder ? placeholderOption : loaderOption;
      return {
        ...value,
        ...options,
      };
    });
  };

  const deleteFromKeywordList = (keywords: string[], isPlaceholder: boolean) => {
    setLoader((value: Loader) => {
      const loaderOption = {
        loaderList: value.loaderList.filter((loaderKeyword) => {
          const isMatching = keywords.findIndex((keyword) => keyword === loaderKeyword) !== -1;
          return !isMatching;
        }),
      };
      const placeholderOption = {
        placeholderList: value.placeholderList.filter((placeholderKeyword) => {
          const isMatching = keywords.findIndex((keyword) => keyword === placeholderKeyword) !== -1;
          return !isMatching;
        }),
      };
      const options = isPlaceholder ? placeholderOption : loaderOption;
      return {
        ...value,
        ...options,
      };
    });
  };

  const functions = {
    appendKeywordList,
    deleteFromKeywordList,
  };
  return [loader, setLoader, functions];
};

export const LoaderContextProvider = ({ children }: { children: ReactNode }) => {
  const provider = useLoaderContextCreator();

  return <LoaderContext.Provider value={provider}>{children}</LoaderContext.Provider>;
};

export const useLoaderContext = (): LoaderContextProviderType => {
  const service = useContext(LoaderContext);

  if (!service) {
    throw new Error('Placeholder Context is unavailable');
  }

  return service;
};

export const useLoaderFunctions = (): LoaderFunctions => {
  const service = useContext(LoaderContext);

  if (!service) {
    throw new Error('Loader Context is unavailable');
  }

  return service[2];
};

export const withLoaderContext = (Component: any) => {
  return function WithLoaderContext(props: any) {
    const service = useLoaderContext();

    return <Component {...props} loaderContext={service} />;
  };
};
