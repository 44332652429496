import React from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { Text } from "../../../../components/text/Text";
import { ReactComponent as ViewIcon } from "../../../../assets/views-icon.svg";
import { abbreviateNumber } from "../../../../utils/abbrNumber";

export const ViewsComponent = ({ views }: { views: number | null }) => {
  const value = views ? views : 0;

  const theme = useTheme();

  const { blue } = theme.colors;
  const { common } = theme.fontSizes;

  return (
    <Wrapper>
      <ViewIcon />
      <Text size={common} margin={`0 0 0 4px`} color={blue}>
        {abbreviateNumber(value)}
      </Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0 0 16px;
  & > svg {
    width: 22px;
    height: 22px;

    & > path {
      stroke: ${(props: any) => props.theme.colors.blue};
      fill: ${(props: any) => props.theme.colors.blue};
    }
  }
`;
