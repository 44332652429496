import React, { useCallback, useEffect, useRef } from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { Text } from "../../../../../../components/text/Text";
import { CommentType } from "../../Comments";
import { ReactComponent as More } from "../../../../../../assets/OthersSign.svg";
import { useSelector } from "react-redux";
import {
  selectLanguageStrings,
  selectUser,
} from "../../../../../../app/state/appSlice";

const Wrapper = styled.li`
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 26px;
`;

const AuthorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
`;

const MoreWrapper = styled.div`
  display: flex;
  position: relative;
`;

const MoreIcon = styled(More)`
  width: 30px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s linear;

  &:hover {
    opacity: 0.8;
  }
`;

const ActionsWrapper = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  background-color: ${(props: any) => props.theme.colors.white};
  box-shadow: ${(props: any) => props.theme.shadows.primary};
  border-radius: 8px;
  position: absolute;
  top: 12px;
  right: 30px;
`;

const ActionsItem = styled(Text)`
  cursor: pointer;

  &:hover {
    color: ${(props: any) => props.theme.colors.blue};
  }
`;

export const CommentItem = ({
  data,
  activeMoreItem,
  moreIconOnClick,
  editOnClick,
  deleteOnClick,
  closeMorePopup,
}: {
  data: CommentType;
  activeMoreItem: string;
  moreIconOnClick: () => void;
  editOnClick: () => void;
  deleteOnClick: () => void;
  closeMorePopup: () => void;
}) => {
  const morePopupRef = useRef<any>(null);
  const languageStrings = useSelector(selectLanguageStrings);
  const user = useSelector(selectUser);
  const theme = useTheme();
  const { black1 } = theme.colors;
  const { common, small } = theme.fontSizes;
  const { id, author, comment } = data;

  const actionsArr = [
    {
      label: languageStrings ? languageStrings.editButtonLabel : "Edit",
      onClick: editOnClick,
    },
    {
      label: languageStrings ? languageStrings.deleteButtonLabel : "Delete",
      onClick: deleteOnClick,
    },
  ];

  const outsideHandler = useCallback(
    (e: any) => {
      if (
        activeMoreItem === id &&
        morePopupRef.current &&
        !morePopupRef.current.contains(e.target)
      ) {
        closeMorePopup();
      }
    },
    [activeMoreItem, id]
  );

  useEffect(() => {
    window.addEventListener("click", outsideHandler);
    return () => {
      window.removeEventListener("click", outsideHandler);
    };
  }, [outsideHandler]);

  return (
    <Wrapper>
      <AuthorWrapper>
        <Text size={common} weight={"500"} color={black1}>
          {author.name}
        </Text>
        {user.id === +author.id && (
          <MoreWrapper>
            <MoreIcon
              onClick={(e: any) => {
                e.stopPropagation();
                moreIconOnClick();
              }}
            />
            {activeMoreItem === id && (
              <ActionsWrapper ref={morePopupRef}>
                {actionsArr.map((item: any) => {
                  const { label, onClick } = item;
                  return (
                    <ActionsItem
                      key={label}
                      onClick={onClick}
                      size={small}
                      weight={"500"}
                      color={black1}
                    >
                      {label}
                    </ActionsItem>
                  );
                })}
              </ActionsWrapper>
            )}
          </MoreWrapper>
        )}
      </AuthorWrapper>
      <Text size={small} weight={"400"} color={black1}>
        {comment}
      </Text>
    </Wrapper>
  );
};
