const pureWhite = "#ffffff";
const pureBlack = "#000000";

export const theme = {
  colors: {
    white: pureWhite,
    white1: "#F7F7F7",
    white2: "#F2F2F2",
    black: pureBlack,
    black1: "#333333",
    black2: "rgba(0, 0, 0, 0.7)",
    black3: "rgba(0, 0, 0, 0.5)",
    black4: "rgba(0, 0, 0, 0.2)",
    blue: "#0066FF",
    blue1: "#3385FF",
    green: "#33894e",
    red: "#991019",
    red1: "#ff8d95",
    red2: "rgb(244, 68, 80)",
    gray: "#555770",
    gray1: "#EAEAEA",
    gray2: "#808080",
    gray3: "#DCDCDC",
    gray4: "rgba(0, 0, 0, 0.1)",
  },
  shadows: {
    primary:
      "0px 0px 1px rgba(40,41,61,0.04), 0px 2px 4px rgba(96,97,112,0.16)",
    additionalShadow1:
      "0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16)",
    additionalShadow2:
      "0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)",
    additionalShadow3:
      "0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16)",
    additionalShadow4:
      "0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)",
    additionalShadow5:
      "0px 2px 2px rgba(40, 41, 61, 0.04), 0px 2px 2px rgba(96, 97, 112, 0.16)",
    additionalShadow6:
      "0px 1px 2px rgba(40, 41, 61, 0.2), 0px 2px 4px rgba(96, 97, 112, 0.2)",
    additionalShadow7:
      "0px 0px 8px rgba(40, 41, 61, 0.04), 0px 0px 24px rgba(96, 97, 112, 0.16)",
    additionalShadow8:
      "0px 1px 1px rgba(40, 41, 61, 0.04), 0px 2px 2px rgba(96, 97, 112, 0.16)",
    additionalShadow9: "0px -2px 15px rgba(0, 0, 0, 0.07)",
    additionalShadow10:
      "0px -4px 12px rgb(96 97 112 / 10%), 0px 8px 24px rgb(96 97 112 / 10%)",
  },
  borders: {
    card: "3px solid",
  },
  borderRadius: {
    primary: "8px",
    additional2: "2px",
    additional4: "4px",
    additional6: "6px",
    additional10: "10px",
    additional14: "14px",
    additional16: "16px",
    additional24: "24px",
    round: "100%",
  },
  fontSizes: {
    extraLarge: "74px",
    large: "50px",
    big: "26px",
    medium: "18px",
    common: "16px",
    small: "14px",
    extraSmall: "12px",
  },
};
