import React, { useEffect, useState } from "react";
import {
  CenteredWrapper,
  Wrapper,
} from "../../../layouts/pageWrapper/PageWrapper";
import { Text } from "../../../components/text/Text";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLanguage,
  selectLanguageStrings,
  selectUser,
  setIsLoggedIn,
  setUser,
} from "../../../app/state/appSlice";
import { FieldsType, isValidField } from "../../../utils/validation";
import { PersonalInformationSection } from "./components/personalInformationSection/PersonalInformationSection";
import { isEqual } from "lodash";
import { NotificationsToggle } from "./components/notificationsToggle/NotificationsToggle";
import { Button } from "../../../components/button/Button";
import { DeleteModal } from "./components/deleteModal/DeleteModal";
import { useCustomHistory } from "../../../utils/react-router-dom-abstraction";
import { View } from "../../../routes/routeInterfaces";
import { useLocation } from "react-router-dom";
import ls from "../../../utils/ls";
import { LsValueType } from "../../../enums/LsValueType";
import { media } from "../../../styles/media";
import {
  changePassword,
  deleteUser,
  resetPassword,
  setUserData,
  useGetUserData,
} from "../../../api/personalInformation";
import { IUserState } from "../../../app/state/appInterfaces";
import { useNotifierFunctions } from "../../../features/notifier2";
import { Loader } from "../../../components/placeholders/Loader";

export const PersonalInformation = () => {
  const { isLoading } = useGetUserData();
  const [savedUser, setSavedUser] = useState<IUserState>({
    blocked: false,
    confirmed: true,
    createdAt: "",
    email: "",
    email_notification: undefined,
    id: 2,
    provider: "",
    updatedAt: "",
    username: "",
    uuid: "",
    password: "",
  });
  const [passwordValues, setPasswordValues] = useState({
    newPassword: "",
    secondNewPassword: "",
  });
  const [validatingIsStarted, setIsValidatingIsStarted] = useState(false);
  const [editableField, setEditableField] = useState<string>("");
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(
    false
  );
  const languageStrings = useSelector(selectLanguageStrings);
  const langId = useSelector(selectLanguage).id;
  const history = useCustomHistory();
  const user = useSelector(selectUser);
  const theme = useTheme();
  const dispatch = useDispatch();

  const { location } = history;
  const code = location.pathname.split("/")[4];
  const resetPasswordCode = code !== "null" ? code : null;

  const { addNotification } = useNotifierFunctions();

  const { blue, white, red2 } = theme.colors;
  const { large } = theme.fontSizes;

  const currentUrl = useLocation().pathname;
  const homeRoute = currentUrl.includes("user")
    ? View.USER_HOME
    : View.DEAF_HOME;

  const setUserLocally = (value: IUserState) => {
    dispatch(setUser(value));
  };
  const getErrorMessage = (
    value: string,
    inputType: string,
    isValid: (value: string) => boolean
  ) => {
    const password = user.password;
    const newPassword = passwordValues.newPassword;
    const secondNewPassword = passwordValues.secondNewPassword;

    if (value?.length === 0) {
      return languageStrings.requiredFieldError;
    } else {
      const isFieldValid = isValid(value);
      const isPasswordFieldFinished =
        inputType === "additional-password" &&
        newPassword?.length > 0 &&
        secondNewPassword?.length > 0;
      if (!isFieldValid) {
        return inputType === "password" || inputType === "additional-password"
          ? languageStrings.passwordIncorrectValue
          : languageStrings.emailIncorrectValue;
      } else if (isPasswordFieldFinished && newPassword !== secondNewPassword) {
        return languageStrings
          ? languageStrings.errorMessageNewPassword
          : "New passwords should match";
      } else if (
        isPasswordFieldFinished &&
        (password === newPassword || password === secondNewPassword)
      ) {
        return languageStrings
          ? languageStrings.passwordNewPrevTheSameError
          : "New password should be different with previous one";
      } else return null;
    }
  };

  const showSuccessMessage = (message?: string) => {
    addNotification({
      message: message
        ? message
        : languageStrings
        ? languageStrings.successMessageAfterChanges
        : "Changes successfully saved!",
      type: "success",
    });
  };

  const showFailMessage = (errorMessage?: string) => {
    addNotification({
      message: errorMessage
        ? errorMessage
        : languageStrings
        ? languageStrings.somethingWentWrongFailureMessage
        : "Something went wrong...",
      type: "error",
    });
  };

  const editOnClick = (value: string) => {
    const hasAlreadyEditableField = editableField.length > 0;

    if (hasAlreadyEditableField) {
      setUserLocally(savedUser);
      editableField === "Password" &&
        setPasswordValues({ newPassword: "", secondNewPassword: "" });
    } else {
      setSavedUser(user);
    }
    setIsValidatingIsStarted(false);
    setEditableField(value);
  };

  const checkAdditionalInputs = (additionalInputs: any[]) => {
    const additionalInputsCheck =
      additionalInputs &&
      additionalInputs
        .map((input: any) => input.isValid(input.value))
        .filter((input: any) => input === true);
    const isNewPasswordValid =
      additionalInputs &&
      additionalInputsCheck.length === additionalInputs.length &&
      passwordValues.newPassword === passwordValues.secondNewPassword;

    return isNewPasswordValid;
  };

  const notificationsToggleOnChange = (e: any) => {
    setUserData(
      { ...user, email_notification: e.target.checked },
      setUserLocally,
      showSuccessMessage,
      showFailMessage
    );
  };

  const logOutOnClick = () => {
    ls.remove(LsValueType.token);
    dispatch(setIsLoggedIn(false));
    dispatch(
      setUser({
        blocked: false,
        confirmed: true,
        createdAt: "",
        email: "",
        email_notification: false,
        id: 2,
        provider: "",
        updatedAt: "",
        username: "",
        uuid: "",
        password: "",
      })
    );
    setIsDeleteAccountModalOpen(false);
    history.push(homeRoute, { pathParams: { languageKey: langId } });
  };

  const deleteOnClick = () => {
    deleteUser(
      user.id,
      () => {
        dispatch(setIsLoggedIn(false));
        showSuccessMessage(languageStrings.userSuccessfullyDeleted);
      },
      showFailMessage
    );
    setIsDeleteAccountModalOpen(false);
    history.push(homeRoute, { pathParams: { languageKey: langId } });
  };

  const openDeleteAccountModal = () => {
    setIsDeleteAccountModalOpen(true);
  };

  const closeDeleteAccountModal = () => {
    setIsDeleteAccountModalOpen(false);
  };

  const saveOnClick = (isFieldValid: boolean, sectionType: string) => {
    const password = !!passwordValues.newPassword
      ? passwordValues.newPassword
      : user.password;

    const changePasswordData = {
      currentPassword: user.password,
      password: passwordValues.newPassword,
      passwordConfirmation: passwordValues.secondNewPassword,
    };

    const value = { ...user, password };

    const isInfoChaged = !isEqual(value, savedUser);

    setIsValidatingIsStarted(true);
    if (isFieldValid && isInfoChaged) {
      setUserLocally({ ...user, password: "" });
      setSavedUser({ ...value, password: "" });
      if (sectionType === "password") {
        if (resetPasswordCode) {
          const value = {
            code: resetPasswordCode,
            password: changePasswordData.password,
            passwordConfirmation: changePasswordData.passwordConfirmation,
          };
          resetPassword(
            value,
            () => {
              showSuccessMessage(
                languageStrings
                  ? languageStrings.passwordSuccessfullyChanged
                  : "Password successfully changed"
              );
              dispatch(setIsLoggedIn(true));
              history.push(View.USER_PERSONAL_INFO, {
                pathParams: { languageKey: langId, code: null },
              });
            },
            (errorMessage: string) => {
              showFailMessage(errorMessage);
            }
          );
        } else {
          changePassword(
            changePasswordData,
            showSuccessMessage,
            showFailMessage
          );
        }
      } else {
        setUserData(value, setUserLocally, showSuccessMessage, showFailMessage);
      }
      setEditableField("");
      setIsValidatingIsStarted(false);
      sectionType === "password" &&
        setPasswordValues({ newPassword: "", secondNewPassword: "" });
    }
  };

  const cancelOnClick = (sectionType: string) => {
    setIsValidatingIsStarted(false);
    setUserLocally(savedUser);
    setEditableField("");
    sectionType === "password" &&
      setPasswordValues({ newPassword: "", secondNewPassword: "" });
    if (resetPasswordCode) {
      history.push(homeRoute, { pathParams: { languageKey: langId } });
    }
  };

  const sections = [
    {
      label: languageStrings ? languageStrings.userNameLabel : "User name",
      value: user.username,
      placeholder: languageStrings
        ? languageStrings.userNamePlaceholder
        : "Enter user name",
      onChange: (value: string) => {
        setUserLocally({ ...user, username: value });
      },
      type: "text",
      isValid: (value: string) => {
        return isValidField(value, FieldsType.TEXT);
      },
      isDisable: !!resetPasswordCode,
    },
    {
      label: languageStrings ? languageStrings.emailLabel : "Email",
      value: user.email,
      placeholder: languageStrings
        ? languageStrings.emailPlaceholder
        : "Enter email",
      onChange: (value: string) => {
        setUserLocally({ ...user, email: value });
      },
      isValid: (value: string) => {
        return isValidField(value, FieldsType.EMAIL);
      },
      type: "text",
      isDisable: !!resetPasswordCode,
    },
    {
      label: languageStrings ? languageStrings.passwordLabel : "Password",
      value: user.password,
      placeholder: languageStrings
        ? languageStrings.passwordPlaceholder
        : "Enter password",
      onChange: (value: string) => {
        setUserLocally({ ...user, password: value });
      },
      isValid: (value: string) => {
        return resetPasswordCode
          ? true
          : isValidField(value, FieldsType.PASSWORD);
      },
      type: "password",
      isDisable: !!resetPasswordCode,
      additionalInputs: [
        {
          placeholder: languageStrings
            ? languageStrings.enterNewPasswordPlaceholder
            : "Enter new password",
          value: passwordValues.newPassword,
          onChange: (value: string) => {
            setPasswordValues({ ...passwordValues, newPassword: value });
          },
          isValid: (value: string) => {
            return isValidField(value, FieldsType.PASSWORD);
          },
          type: "additional-password",
        },
        {
          placeholder: languageStrings
            ? languageStrings.enterPasswordOneTimeMore
            : "Enter new password one time more",
          value: passwordValues.secondNewPassword,
          onChange: (value: string) => {
            setPasswordValues({ ...passwordValues, secondNewPassword: value });
          },
          isValid: (value: string) => {
            return isValidField(value, FieldsType.PASSWORD);
          },
          type: "additional-password",
        },
      ],
    },
  ];

  const saveCancelButtonsArr = [
    {
      buttonValue: languageStrings ? languageStrings.saveButtonLabel : "Save",
      onClick: (isFieldValid: boolean, sectionType: string) => {
        saveOnClick(isFieldValid, sectionType);
      },
    },
    {
      buttonValue: languageStrings
        ? languageStrings.cancelButtonLabel
        : "Cancel",
      onClick: (sectionType: string) => {
        cancelOnClick(sectionType);
      },
      color: blue,
      backgroundColor: "transparent",
      borderColor: blue,
      hoverColor: white,
      hoverBackgroundColor: blue,
      borderColorHover: blue,
    },
  ];

  useEffect(() => {
    if (resetPasswordCode) {
      setEditableField(
        languageStrings ? languageStrings.passwordLabel : "Password"
      );
    }
  }, [resetPasswordCode]);

  return (
    <Wrapper>
      <CenteredWrapper>
        <Text
          weight={"500"}
          size={large}
          color={blue}
          margin={`0 0 ${large} 0`}
        >
          {languageStrings
            ? languageStrings.personalInformationTitle
            : "Personal Information"}
        </Text>
        <Container>
          {sections.map((item: any, index: number) => {
            const {
              label,
              value,
              placeholder,
              onChange,
              type,
              additionalInputs,
              isValid,
              isDisable,
            } = item;

            const isNewPasswordValid = checkAdditionalInputs(additionalInputs);
            const isEditInProcess = editableField === label;
            const errorMessage =
              isEditInProcess &&
              validatingIsStarted &&
              getErrorMessage(value, type, isValid);

            return (
              <PersonalInformationSection
                key={index}
                label={label}
                isEditInProcess={isEditInProcess}
                type={type}
                value={value}
                placeholder={placeholder}
                errorMessage={errorMessage}
                additionalInputs={additionalInputs}
                buttonsArr={saveCancelButtonsArr}
                validatingIsStarted={validatingIsStarted}
                isNewPasswordValid={isNewPasswordValid}
                isValid={isValid}
                onChange={onChange}
                editOnClick={editOnClick}
                getErrorMessage={getErrorMessage}
                isDisable={isDisable}
              />
            );
          })}
          {!resetPasswordCode && (
            <>
              <NotificationsToggle
                label={
                  languageStrings
                    ? languageStrings.emailNotificationsLabel
                    : "Email notifications"
                }
                value={user.email_notification}
                onChange={notificationsToggleOnChange}
              />
              <ButtonsWrapper>
                <Button
                  value={
                    languageStrings
                      ? languageStrings.deleteButtonLabel
                      : "Delete"
                  }
                  onClick={openDeleteAccountModal}
                  color={red2}
                  backgroundColor={"transparent"}
                  borderColor={red2}
                  hoverColor={white}
                  hoverBackgroundColor={red2}
                  borderColorHover={red2}
                />
                <Button
                  value={
                    languageStrings
                      ? languageStrings.logOutButtonLabel
                      : "Log out"
                  }
                  onClick={logOutOnClick}
                  color={blue}
                  backgroundColor={"transparent"}
                  borderColor={blue}
                  hoverColor={white}
                  hoverBackgroundColor={blue}
                  borderColorHover={blue}
                />
              </ButtonsWrapper>
            </>
          )}
          {isLoading && <Loader />}
        </Container>
      </CenteredWrapper>
      <DeleteModal
        isOpenModal={isDeleteAccountModalOpen}
        deleteOnClick={deleteOnClick}
        closeModal={closeDeleteAccountModal}
        title={
          languageStrings
            ? languageStrings.deleteAccountConfirmation
            : "Are you sure that you want to delete an account?"
        }
      />
    </Wrapper>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ButtonsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 12px;

  ${media.tabletS`
    width: 100%;
    flex-direction: column;

    & > button {
        width: 100%;
    }
  `}
`;
