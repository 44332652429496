import React from "react";
import ReactDOM from "react-dom";

import { store } from "./app/store";
import { Provider } from "react-redux";
import { routes } from "./routes";
import { renderRoutes } from "react-router-config";
import * as serviceWorker from "./serviceWorker";

import { BrowserRouter } from "react-router-dom";
import "./styles/global.css";

import { TooltipContextProvider } from "./components/tooltip/context";
import { StyleContextProvider } from "./styles/style.context";
import { LoaderContextProvider } from "./features/placeholderComponent/loader.context";
import { NotifierProvider } from "./features/notifier2";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <TooltipContextProvider>
        <LoaderContextProvider>
          <StyleContextProvider>
            <Provider store={store}>
              <NotifierProvider>{renderRoutes(routes as any)}</NotifierProvider>
            </Provider>
          </StyleContextProvider>
        </LoaderContextProvider>
      </TooltipContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
