import { useEffect, useState } from "react";
import { API_URL } from "./consts";
import {
  selectLanguageStrings,
  setIsAppLoading,
  setLanguage,
  setLanguagesList,
} from "../app/state/appSlice";
import { useDispatch, useSelector } from "react-redux";
import ls from "../utils/ls";
import { LsValueType } from "../enums/LsValueType";

export const useGetLanguages = () => {
  const token = ls.get(LsValueType.token);
  const [localData, setLocalData] = useState<any>([]);
  const languagesListLS = ls.get(LsValueType.languagesList);

  const languageStrings = useSelector(selectLanguageStrings);
  const languageIdLS = ls.get(LsValueType.locale);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!languagesListLS || languagesListLS?.length === 0) {
      dispatch(setIsAppLoading(true));
      fetch(`${API_URL}/i18n/locales`, {
        method: "GET",
        headers: new Headers({ Authorization: token ? `Bearer ${token}` : "" }),
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res) {
            const data = res.map((item: any) => ({
              name: item.name,
              id: item.code,
            }));
            const preparedData = [
              ...data,
              {
                name: languageStrings
                  ? languageStrings.deafAndDumb
                  : "Deaf & dumb",
                id: "deafDumb",
              },
            ];
            setTimeout(() => {
              dispatch(setIsAppLoading(false));
            }, 1000);

            dispatch(setLanguagesList(preparedData));
            dispatch(
              setLanguage(languageIdLS ? languageIdLS : preparedData[1].id)
            );
            ls.set(LsValueType.languagesList, preparedData);

            setLocalData(preparedData);
          }
        });
    }
  }, [languagesListLS, languageIdLS]);

  return localData;
};
