import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { AppState, IUserState } from "./appInterfaces";
import languageStrings from "../../languages/lang.json";
import ls from "../../utils/ls";
import { LsValueType } from "../../enums/LsValueType";

export const languagesList = ls.get(LsValueType.languagesList) || [
  {
    name: "English",
    id: "en-GB",
  },
];

export const defaultLang = ls.get(LsValueType.locale) || {
  name: "English",
  id: "en-GB",
};

const initialState: AppState = {
  isAuthenticated: false,
  loaderList: [],
  initialFlag: false,
  language: defaultLang,
  languages: languagesList,
  searchInputValue: "",
  isSearchedDataLoading: false,
  isAvailableToStartSearching: false,
  isAppLoading: false,
  isLoggedIn: !!ls.get(LsValueType.token),
  user: {
    blocked: false,
    confirmed: true,
    createdAt: "",
    email: "",
    email_notification: undefined,
    id: 2,
    provider: "",
    updatedAt: "",
    username: "",
    uuid: "",
    password: "",
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },

    startLoader: (state, action: PayloadAction<string | string[]>) => {
      const set = new Set(
        !Array.isArray(action.payload)
          ? [
              ...state.loaderList,
              { status: true, location: action.payload || "global" },
            ]
          : [
              ...state.loaderList,
              ...action.payload.map((act) => ({
                status: true,
                location: act || "global",
              })),
            ]
      );
      state.loaderList = Array.from(set);
    },
    stopLoader: (state, action: PayloadAction<string | string[]>) => {
      state.loaderList = !Array.isArray(action.payload)
        ? state.loaderList.filter(
            (element) => element.location !== action.payload
          )
        : state.loaderList.filter(
            (element) =>
              !(action.payload as string[]).some(
                (act) => act === element.location
              )
          );
    },
    setInitialFlag: (state, action: PayloadAction<boolean>) => {
      state.initialFlag = action.payload;
    },
    setLanguagesList: (
      state,
      action: PayloadAction<{ name: string; id: string }[]>
    ) => {
      state.languages = action.payload;
    },
    setLanguage: (
      state,
      action: PayloadAction<{ name: string; id: string }>
    ) => {
      state.language = action.payload;
    },
    setSearchInputValue: (state, action: PayloadAction<{ value: string }>) => {
      state.searchInputValue = action.payload.value;
    },
    setIsSearchedDataLoading: (
      state,
      action: PayloadAction<{ value: boolean }>
    ) => {
      state.isSearchedDataLoading = action.payload.value;
    },
    setIsAvailableToStartSearching: (
      state,
      action: PayloadAction<{ value: boolean }>
    ) => {
      state.isAvailableToStartSearching = action.payload.value;
    },
    setIsAppLoading: (state, action: PayloadAction<boolean>) => {
      state.isAppLoading = action.payload;
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setUser: (state, action: PayloadAction<IUserState>) => {
      state.user = action.payload;
    },
  },
});

export const {
  setIsAvailableToStartSearching,
  setIsSearchedDataLoading,
  setSearchInputValue,
  setIsAuthenticated,
  setLanguagesList,
  setIsAppLoading,
  setInitialFlag,
  startLoader,
  setLanguage,
  stopLoader,
  setIsLoggedIn,
  setUser,
} = appSlice.actions;

export const selectIsAuthenticated = (state: RootState) => {
  return state.app.isAuthenticated;
};
export const selectLoaderList = (state: RootState) => {
  return state.app.loaderList;
};
export const selectInitialFlag = (state: RootState) => {
  return state.app.initialFlag;
};
export const selectLanguagesList = (state: RootState) => {
  return state.app.languages;
};
export const selectLanguage = (state: RootState) => {
  return state.app.language;
};
export const selectLanguageStrings = (state: RootState) => {
  return (languageStrings as any)[state.app.language.id];
};
export const selectSearchInputValue = (state: RootState) => {
  return state.app.searchInputValue;
};
export const selectIsSearchDataLoading = (state: RootState) => {
  return state.app.isSearchedDataLoading;
};
export const selectIsAvailableToStartSearching = (state: RootState) => {
  return state.app.isAvailableToStartSearching;
};
export const selectIsAppLoading = (state: RootState) => {
  return state.app.isAppLoading;
};
export const selectIsLoggedIn = (state: RootState) => {
  return state.app.isLoggedIn;
};
export const selectUser = (state: RootState) => {
  return state.app.user;
};
export default appSlice.reducer;
