import React, { useEffect, useState } from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLanguage,
  selectLanguageStrings,
} from "../../app/state/appSlice";
import { Wrapper, CenteredWrapper } from "../pageWrapper/PageWrapper";
import { BackButton } from "./components/backButton/BackButton";
import { useCustomHistory } from "../../utils/react-router-dom-abstraction";
import { DeafView, UserView } from "../../enums/View";
import {
  selectIsContentLoading,
  setContentItem,
} from "../../app/state/contentSlice";
import { Text } from "../../components/text/Text";
import { Button } from "../../components/button/Button";
import { Comments } from "./components/comments/Comments";
import { useLocation } from "react-router-dom";
import { useMediaType } from "../../styles/style.context";
import { media } from "../../styles/media";
import { AudioRunner } from "./components/audioRunner/AudioRunner";
import { VideoRunner } from "./components/videoRunner/VideoRunner";
import { ContentType } from "../../enums/ContentType";
import { Loader } from "../../components/placeholders/Loader";
import { useGetUserData } from "../../api/personalInformation";
import { ViewsComponent } from "./components/viewsComponent/ViewsComponent";
import { setViews } from "../../api/content";
import ls from "../../utils/ls";
import { LsValueType } from "../../enums/LsValueType";

const Container = styled(CenteredWrapper)`
  align-items: flex-start;
`;
const BackButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.tabletS`
    & > button.download {
      display: none;
    }
  `}
`;
const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 36px 0;

  & > button.download {
    display: none;
  }

  ${media.tabletS`
    flex-direction: column;

    & > button.download {
    display: flex;
  }
  `}
`;
const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  ${media.tabletS`
    & > button {
      width: 100%;
      height: 40px;
    }
  `}
`;
const ImageContainer = styled.div`
  width: 400px;
  height: 378px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.tabletS`
    width: 100%;
  `}
`;
const Image = styled.img`
  width: max-content;
  max-width: 100%;
  max-height: 100%;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 36px;

  & > span:first-child {
    max-width: 90%;
  }

  ${media.tabletS`
    flex-direction: column;
    align-items: flex-start;

    & > span:first-child {
    max-width: 100%;
  }

    & > span:last-child {
      margin: 4px 0 0 0;
    }
  `}
`;

// Все размеры 2/3 от того, что в дизайне!!!

export const ContentDetails = ({
  contentCategory,
  category,
  rootView,
  data,
}: {
  contentCategory: string;
  category: string;
  rootView: UserView | DeafView;
  data: any;
}) => {
  const [
    pageIsLoadedAfterPastingLink,
    setPageIsLoadedAfterPastingLink,
  ] = useState(false);
  const { isLoading } = useGetUserData();
  const languageStrings = useSelector(selectLanguageStrings);
  const langId = useSelector(selectLanguage).id;
  const isContentLoading = useSelector(selectIsContentLoading);
  const history = useCustomHistory();
  const path = useLocation().pathname;
  const mediaType = useMediaType();
  const isDeaf = path.includes("deaf");
  const pathArr = path.split("/");
  const pageId = isDeaf ? pathArr[2] : pathArr[3];
  const theme = useTheme();
  const dispatch = useDispatch();
  const countOfOpening = sessionStorage.getItem("countOfOpening") || 0;

  const { blue, black3 } = theme.colors;
  const { medium, big, common, large } = theme.fontSizes;
  const { tabletS } = mediaType;

  const {
    release_date,
    content_type,
    download_url,
    description,
    image_url,
    comments,
    name,
    file,
    id,
    short_id,
    views,
  } = data;

  const isVideo = content_type === ContentType.VIDEO;
  const isAudio = content_type === ContentType.AUDIO;

  const backButtonOnClick = () => {
    const currentPageFromLS = ls.get(LsValueType.currentPage);

    history.push(rootView, {
      pathParams: {
        languageKey: langId,
        currentPage: currentPageFromLS,
        searchValue: "empty_search",
      },
    });
  };

  const setLocalViews = () => {
    dispatch(setContentItem({ value: { ...data, views: +views + 1 } }));
  };

  useEffect(() => {
    if (performance.navigation.type === 1) {
    } else if (
      window.performance.navigation.redirectCount === 0 &&
      countOfOpening === 0
    ) {
      setPageIsLoadedAfterPastingLink(true);
    }
  }, []);

  useEffect(() => {
    if (short_id && category && pageIsLoadedAfterPastingLink) {
      setViews(contentCategory, short_id);
      setLocalViews();
      sessionStorage.setItem("countOfOpening", `${+countOfOpening + 1}`);
      setPageIsLoadedAfterPastingLink(false);
    }
  }, [
    short_id,
    category,
    countOfOpening,
    pageIsLoadedAfterPastingLink,
    contentCategory,
  ]);

  return (
    <Wrapper>
      <Container>
        <BackButtonContainer>
          <BackButton
            onClick={backButtonOnClick}
            languageStrings={languageStrings}
          />
          {download_url && (
            <Button
              className={"download"}
              value={
                languageStrings
                  ? languageStrings.downloadPDFButtonValue
                  : "Download PDF"
              }
              onClick={() => {
                window.open(download_url, "_blank");
              }}
              width={"200px"}
              height={"40px"}
              fontSize={medium}
              margin={tabletS ? "24px 0 0 0" : "0"}
            />
          )}
        </BackButtonContainer>
        <ImageWrapper>
          {isVideo && <VideoRunner url={file[0].src} />}
          {!isVideo && (
            <ImageContainer>
              <Image src={image_url} alt={"IMAGE"} />
            </ImageContainer>
          )}
          {download_url && (
            <Button
              className={"download"}
              value={
                languageStrings
                  ? languageStrings.downloadPDFButtonValue
                  : "Download PDF"
              }
              onClick={() => {
                window.open(download_url, "_blank");
              }}
              width={"100%"}
              height={"40px"}
              fontSize={medium}
              margin={tabletS ? "24px 0 0 0" : "0"}
            />
          )}
          <ButtonsWrapper>
            {isAudio && <AudioRunner data={file} />}
          </ButtonsWrapper>
        </ImageWrapper>
        <TitleWrapper>
          <Text size={big} color={blue}>
            {name}
          </Text>
          <Text size={common} margin={`4px 0 0 ${common}`} color={blue}>
            {release_date}
          </Text>
          <ViewsComponent views={views} />
        </TitleWrapper>
        <Text size={common} margin={`0 0 ${large} 0`} color={black3}>
          {description}
        </Text>
        <Comments
          languageStrings={languageStrings}
          data={comments}
          pageId={pageId}
          contentId={id}
          shortId={short_id}
          category={category}
        />
      </Container>
      {(isContentLoading || isLoading) && <Loader />}
    </Wrapper>
  );
};
