import React from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { Text } from "../../../../../../../../components/text/Text";
import TextareaAutosize from "react-textarea-autosize";

export enum FormInputType {
  INPUT = "INPUT",
  TEXTAREA = "TEXTAREA",
}
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 32px;
`;
const Input = styled.input`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid;
  border-color: ${(props: any) => props.theme.colors.gray4};
  padding: 8px 16px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
  outline: none;
  transition: all 0.3s linear;

  &.error {
    border-color: ${(props: any) => props.theme.colors.red1};
  }

  &:focus {
    border-color: ${(props: any) => props.theme.colors.blue};
  }
`;
const Textarea = styled(TextareaAutosize)`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid;
  border-color: ${(props: any) => props.theme.colors.gray4};
  resize: none;
  padding: 8px 16px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
  outline: none;
  transition: all 0.3s linear;

  &.error {
    border-color: ${(props: any) => props.theme.colors.red1};
  }

  &:focus {
    border-color: ${(props: any) => props.theme.colors.blue};
  }
`;

export const FormInput = ({
  isFormSubmitted,
  errorMessage,
  placeholder,
  onChange,
  onBlur,
  isError,
  label,
  value,
  type,
  inputRef,
}: {
  onChange: (value: string) => void;
  isFormSubmitted?: boolean;
  errorMessage?: string;
  type: FormInputType;
  placeholder: string;
  isError?: boolean;
  inputRef?: any;
  onBlur?: () => void;
  label: string;
  value: string;
}) => {
  const theme = useTheme();
  const { black1, red2 } = theme.colors;
  const { small, common } = theme.fontSizes;

  return (
    <Wrapper>
      <Text
        color={black1}
        size={common}
        weight={"400"}
        margin={`0 0 8px ${common}`}
      >
        {label}
      </Text>
      {type === FormInputType.INPUT ? (
        <Input
          className={isFormSubmitted && isError ? "error" : ""}
          placeholder={placeholder}
          onChange={(e: any) => onChange(e.target.value)}
          onBlur={onBlur}
          value={value}
          ref={inputRef}
        />
      ) : (
        <Textarea
          className={isFormSubmitted && isError ? "error" : ""}
          placeholder={placeholder}
          onChange={(e: any) => onChange(e.target.value)}
          onBlur={onBlur}
          value={value}
          minRows={2}
          maxRows={8}
          ref={inputRef}
        />
      )}
      {isFormSubmitted && isError && errorMessage && (
        <Text color={red2} size={small} margin={`8px 0 0 ${small}`}>
          {errorMessage}
        </Text>
      )}
    </Wrapper>
  );
};
