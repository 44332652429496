import React from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { Text } from "../../../../../components/text/Text";
import { media } from "../../../../../styles/media";
import { getPath, replacePathParams } from "../../../../../routes/paths";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../../app/state/appSlice";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 120px;
  ${media.phone`
    margin-bottom: 60px;
  `}
`;
const CenteredWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 56px 112px;
  padding: 0 112px;

  ${media.tablet`
    justify-content: space-between;
    padding: 0 32px;
  `}
  ${media.phone`
    margin-bottom: 60px;
    padding: 0 16px;
  `}
`;
const Card = styled.a`
  width: 330px;
  height: 428px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  background-color: white;
  cursor: pointer;
  transition: all 0.3s linear;
  text-decoration: none;

  & > svg > path {
    transition: all 0.3s linear;
  }
  &:hover {
    & > svg > path {
      fill: ${(props: any) => props.theme.colors.blue1};
    }
    & > span {
      color: blue1;
    }

    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.2);
  }
  ${media.tablet`
    width: 40%;
    height: 300px
  `}
  ${media.phone`
    width: 100%;
  `}
`;
export const Sections = ({
  sectionsList,
}: {
  sectionsList: { title: string; image: any; to: any }[];
}) => {
  const theme = useTheme();
  const { blue } = theme.colors;
  const { big } = theme.fontSizes;

  const langId = useSelector(selectLanguage).id;

  return (
    <Wrapper>
      <CenteredWrapper>
        {sectionsList.map(
          (item: { title: string; image: any; to: any }, index: number) => {
            const { title, image, to } = item;

            const path = getPath(to);
            const href = replacePathParams(path, {
              languageKey: langId,
              currentPage: 1,
              searchValue: "empty_search",
            });

            return (
              <Card key={index} href={href}>
                {image()}
                <Text size={big} weight={"500"} color={blue}>
                  {title}
                </Text>
              </Card>
            );
          }
        )}
      </CenteredWrapper>
    </Wrapper>
  );
};
