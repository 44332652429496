import React, { useEffect, useMemo, useRef, useState } from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { Text } from "../text/Text";
import { Button } from "../button/Button";
import { useWindowSize } from "../../styles/style.context";
import { media } from "../../styles/media";

const Wrapper = styled.li`
  display: flex;

  ${media.tabletS`
    flex-direction: column;
  `}
`;
const ImageWrapper = styled.a`
  width: 224px;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.tabletS`
    width: 100%;
  `}
`;
const Image = styled.img`
  width: 224px;
  height: 260px;
  object-fit: cover;
  cursor: pointer;
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;

  ${media.tabletS`
    margin: 24px 0 0 0;
  `}
`;
const DescriptionWrapper = styled.div`
  width: 100%;
  height: ${(props: any) => props.height};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.tabletS`
    & > button {
      width: 100%;
    }
  `}
`;
const Description = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  height: ${(props: any) => props.descriptionHeight};
`;
const Title = styled.a`
  font-family: "Poppins", sans-serif;
  font-size: ${(props: any) => props.theme.fontSizes.big};
  weight: 400;
  margin: 0 0 8px 0;
  color: ${(props: any) => props.theme.colors.black1};
  cursor: pointer;
  text-decoration: none;
`;

export const ContentItem = ({
  data,
  href,
  goToDetails,
}: {
  data: any;
  href: string;
  goToDetails: (e: any) => void;
}) => {
  const theme = useTheme();
  const { black1 } = theme.colors;
  const { medium, common } = theme.fontSizes;
  const titleRef = useRef<any>(null);
  const [titleHeight, setTitleHeight] = useState(0);
  const windowWidth = useWindowSize()[0];

  const {
    image_url,
    name,
    release_date,
    description,
    buttonArr,
    download_url,
  } = data;

  const descriptionHeight = useMemo(() => 260 - titleHeight - 110, [
    titleHeight,
  ]);
  const resid = descriptionHeight % 22;
  const preparedDescHeight = useMemo(
    () =>
      resid > 11
        ? `${Math.ceil(descriptionHeight / 22) * 22}px`
        : `${Math.floor(descriptionHeight / 22) * 22}px`,
    [resid, descriptionHeight]
  );

  const descWrapperHeight = useMemo(() => `${260 - titleHeight - 52}px`, [
    titleHeight,
  ]);

  useEffect(() => {
    if (titleRef && titleRef.current) {
      setTitleHeight(titleRef.current.clientHeight);
    }
  }, [titleRef, windowWidth]);

  return (
    <Wrapper>
      <ImageWrapper href={href} onClick={goToDetails}>
        <Image src={image_url} alt={"ITEM"} />
      </ImageWrapper>
      <InfoWrapper>
        <Title textRef={titleRef} href={href} onClick={goToDetails}>
          {name}
        </Title>
        {release_date && (
          <Text
            size={medium}
            weight={"400"}
            margin={"0 0 16px 0"}
            color={"rgba(51, 51, 51, 0.5);"}
          >
            {release_date}
          </Text>
        )}
        <DescriptionWrapper height={descWrapperHeight}>
          <Description
            size={common}
            weight={"400"}
            margin={"0 0 0 0"}
            color={black1}
            descriptionHeight={preparedDescHeight}
          >
            {description}
          </Description>
          {download_url &&
            buttonArr.length > 0 &&
            buttonArr.map((button: any, index: number) => {
              const { value, onClick } = button;

              return (
                <Button
                  key={index}
                  value={value}
                  onClick={onClick}
                  margin={"0"}
                  width={"182px"}
                  fontSize={common}
                />
              );
            })}
        </DescriptionWrapper>
      </InfoWrapper>
    </Wrapper>
  );
};
