import React, { MouseEvent } from "react";
import {
  useHistory as useHistoryRRD,
  NavLink as NavLinkRRD,
} from "react-router-dom";
import { NavLinkProps } from "./react-router-dom-interfaces";
import { getAccess, getPath, injectPathParams } from "../routes/paths";
import { View } from "../routes/routeInterfaces";
import { getObjectPath } from "./getObjectPath";

export const useHistory = () => {
  const history = useHistoryRRD();
  const handlePush = (path: string, state?: any) => {
    if (!state) return;
    const { access, ...newState } = state;
    if (access && access.length > 0) {
      const permission = getObjectPath(access, {});
      if (!permission) return;
    }
    history.push(path, newState);
  };
  const handleReplace = (path: string, state?: any) => {
    if (!state) return;
    const { access, ...newState } = state;
    if (access && access.length > 0) {
      const permission = getObjectPath(access, {});
      if (!permission) return;
    }
    history.replace(path, newState);
  };
  const resultHistory = {
    ...history,
    push: handlePush,
    replace: handleReplace,
  };
  return resultHistory;
};

export const useCustomHistory = () => {
  const history = useHistoryRRD();
  const handlePush = (view: typeof View[keyof typeof View], state?: any) => {
    const access = getAccess(view);
    let path = getPath(view);
    if (state) {
      const { pathParams } = state;
      if (pathParams) {
        path = injectPathParams(path, pathParams);
        delete state.pathParams;
      }
    }
    if (access && access.length > 0) {
      const permission = getObjectPath(access, {});
      if (!permission) return;
    }
    history.push(path, state);
  };
  const handleReplace = (view: typeof View[keyof typeof View], state?: any) => {
    const access = getAccess(view);
    let path = getPath(view);
    if (state) {
      const { pathParams } = state;
      if (pathParams) {
        path = injectPathParams(path, pathParams);
        delete state.pathParams;
      }
    }
    if (access && access.length > 0) {
      const permission = getObjectPath(access, {});
      if (!permission) return;
    }
    history.replace(path, state);
  };
  const resultHistory = {
    ...history,
    push: handlePush,
    replace: handleReplace,
  };
  return resultHistory;
};

export const NavLink = ({
  access,
  children,
  onClick,
  ...props
}: NavLinkProps) => {
  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    onClick && onClick(e);
    const permission =
      !(access && access.length > 0) || getObjectPath(access, {});
    if (!permission) e.preventDefault();
  };
  return (
    <NavLinkRRD {...props} onClick={handleClick}>
      {children}
    </NavLinkRRD>
  );
};
