import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ContentState, IPaginationType } from "./contentInterfaces";

const initialState: ContentState = {
  paginationStep: 4,
  bottomOffset: 0,
  listLength: 0,
  offset: 0,
  list: [],
  isSearchProcessStarted: false,
  listItem: {},
  isSearchedContentVisible: false,
  isContentLoading: false,
  contentLength: 0,
  currentPage: 1,
  pagination: {
    page: 1,
    pageCount: 0,
    pageSize: 0,
    total: 0,
  },
};

export const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<IPaginationType>) => {
      state.pagination = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setPaginationStep: (state, action: PayloadAction<any>) => {
      state.paginationStep = action.payload.value;
    },
    setContent: (state, action: PayloadAction<any>) => {
      state.list = action.payload.value;
    },
    setBottomOffset: (state, action: PayloadAction<any>) => {
      state.bottomOffset = action.payload.value;
    },
    setOffset: (state, action: PayloadAction<any>) => {
      state.offset = action.payload.value;
    },
    setIsSearchContentVisible: (state, action: PayloadAction<any>) => {
      state.isSearchedContentVisible = action.payload.value;
    },
    setContentItem: (state, action: PayloadAction<any>) => {
      state.listItem = action.payload.value;
    },
    setContentLength: (state, action: PayloadAction<number>) => {
      state.contentLength = action.payload;
    },
    setIsContentLoading: (state, action: PayloadAction<boolean>) => {
      state.isContentLoading = action.payload;
    },
    addComment: (state, action: PayloadAction<any>) => {
      state.listItem = {
        ...state.listItem,
        comments: [...state.listItem.comments, action.payload.comment],
      };
    },
    setComment: (state, action: PayloadAction<any>) => {
      const commentId = action.payload.id;
      const commentValue = action.payload.value;

      const preparedComments = state.listItem.comments.map((comment: any) => {
        const { id } = comment;

        return id === commentId ? commentValue : comment;
      });

      state.listItem = {
        ...state.listItem,
        comments: preparedComments,
      };
    },
    deleteCommentRedux: (state, action: PayloadAction<any>) => {
      const commentId = action.payload.value;

      const preparedComments = state.listItem.comments.filter(
        (comment: any) => comment.id !== commentId
      );
      state.listItem = {
        ...state.listItem,
        comments: preparedComments,
      };
    },
  },
});

export const {
  setIsSearchContentVisible,
  setPaginationStep,
  setBottomOffset,
  setContent,
  addComment,
  setContentItem,
  setOffset,
  setPagination,
  setCurrentPage,
  setContentLength,
  setIsContentLoading,
  setComment,
  deleteCommentRedux,
} = contentSlice.actions;

export const selectPaginationStep = (state: RootState) => {
  return state.content.paginationStep;
};
export const selectContent = (state: RootState) => {
  return state.content.list;
};
export const selectOffset = (state: RootState) => {
  return state.content.offset;
};
export const selectBottomOffset = (state: RootState) => {
  return state.content.bottomOffset;
};
export const selectIsSearchContentVisible = (state: RootState) => {
  return state.content.isSearchedContentVisible;
};
export const selectContentItem = (state: RootState) => {
  return state.content.listItem;
};
export const selectCurrentPage = (state: RootState) => {
  return state.content.currentPage;
};
export const selectPagination = (state: RootState) => {
  return state.content.pagination;
};
export const selectContentLength = (state: RootState) => {
  return state.content.contentLength;
};
export const selectIsContentLoading = (state: RootState) => {
  return state.content.isContentLoading;
};
export default contentSlice.reducer;
