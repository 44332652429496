import React, { ReactNode } from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";

const ButtonElement = styled.button`
  width: ${(props: any) => (props.width ? props.width : "120px")};
  height: ${(props: any) => (props.height ? props.height : "40px")};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props: any) => (props.margin ? props.margin : "0")};
  font-size: ${(props: any) =>
    props.fontSize ? props.fontSize : props.theme.fontSizes.common};
  color: ${(props: any) =>
    props.color ? props.color : props.theme.colors.white};
  background-color: ${(props: any) =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.blue};
  outline: none;
  border: ${(props: any) => (props.borderColor ? "1px solid" : "none")};
  border-color: ${(props: any) =>
    props.borderColor ? props.borderColor : "none"};
  border-radius: ${(props: any) =>
    props.borderRadius ? props.borderRadius : "50px"};
  transition: all 0.3s linear;
  opacity: 1;
  cursor: pointer;

  &:hover {
    color: ${(props: any) =>
      props.hoverColor ? props.hoverColor : props.theme.colors.white};
    background-color: ${(props: any) =>
      props.hoverBackgroundColor
        ? props.hoverBackgroundColor
        : props.theme.colors.blue};
    border-color: ${(props: any) =>
      props.borderColorHover ? props.borderColorHover : "none"};
    opacity: ${(props: any) => (props.hoverBackgroundColor ? "1" : "0.8")};
  }
`;

export const Button = ({
  value,
  onClick,
  hoverBackgroundColor,
  borderColorHover,
  backgroundColor,
  borderRadius,
  borderColor,
  hoverColor,
  fontSize,
  children,
  height,
  margin,
  color,
  width,
  type,
  ...props
}: {
  value: string;
  onClick: Function;
  hoverBackgroundColor?: string;
  borderColorHover?: string;
  backgroundColor?: string;
  borderRadius?: string;
  children?: ReactNode;
  borderColor?: string;
  hoverColor?: string;
  className?: string;
  fontSize?: string;
  height?: string;
  margin?: string;
  color?: string;
  width?: string;
  type?: string;
}) => {
  const theme = useTheme();

  return (
    <ButtonElement
      hoverBackgroundColor={hoverBackgroundColor}
      borderColorHover={borderColorHover}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      borderColor={borderColor}
      hoverColor={hoverColor}
      fontSize={fontSize}
      onClick={onClick}
      height={height}
      margin={margin}
      theme={theme}
      width={width}
      color={color}
      type={type}
      {...props}
    >
      {value}
      {children}
    </ButtonElement>
  );
};
