import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsAvailableToStartSearching,
  selectLanguage,
  selectSearchInputValue,
  setIsAvailableToStartSearching,
} from "../app/state/appSlice";
import {
  selectCurrentPage,
  selectIsSearchContentVisible,
  selectPaginationStep,
  setContentItem,
  setContentLength,
  setIsContentLoading,
  setPagination,
} from "../app/state/contentSlice";
import { API_URL } from "./consts";
import ls from "../utils/ls";
import { LsValueType } from "../enums/LsValueType";

export interface SearchedBooksList {
  fullList: any[];
  orderedList: any[];
}

export const useGetContent = (category: string, isForDeaf?: boolean) => {
  const [localData, setLocalData] = useState<any>([]);

  const step = useSelector(selectPaginationStep);
  const currentPage = useSelector(selectCurrentPage);
  const currentLanguage = useSelector(selectLanguage);
  const isSearchedContentVisible = useSelector(selectIsSearchContentVisible);
  const searchInputValue = useSelector(selectSearchInputValue);
  const dispatch = useDispatch();
  const token = ls.get(LsValueType.token);

  const filters =
    category === "movies" || category === "sermons"
      ? `&filters[for_deaf][$eq]=${!!isForDeaf}`
      : "";

  useEffect(() => {
    if (!isSearchedContentVisible && searchInputValue.length === 0) {
      dispatch(setIsContentLoading(true));
      fetch(
        `${API_URL}/${category}?populate=*&locale=${currentLanguage.id}&pagination[page]=${currentPage}&pagination[pageSize]=${step}${filters}`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: token ? `Bearer ${token}` : "",
          }),
        }
      )
        .then((res) => res.json())
        .then(({ data, meta }) => {
          if (data) {
            dispatch(setIsContentLoading(false));

            const preparedData = data
              .map((item: any) => {
                const {
                  description,
                  image_url,
                  release_date,
                  format,
                  download_link,
                  name,
                  slug,
                  file,
                  content_type,
                  views,
                } = item.attributes;
                const { id } = item;

                return {
                  id: slug,
                  short_id: id,
                  name,
                  description,
                  image_url,
                  release_date,
                  format,
                  file,
                  download_url: download_link,
                  content_type,
                  views,
                };
              })
              .filter((item: any) => !item.forKids);
            dispatch(setPagination(meta.pagination));
            dispatch(setContentLength(preparedData.length));
            setLocalData(preparedData);
          }
        })
        .catch((err) => {
          console.log("err: ", err);
          dispatch(setIsContentLoading(true));
        });
    }
  }, [
    isSearchedContentVisible,
    currentPage,
    currentLanguage,
    searchInputValue,
  ]);

  return { content: localData };
};

export const useGetFilteredFullListContent = (
  category: string,
  searchValue: string,
  setIsloadingInProcess: (value: boolean) => void,
  isForDeaf?: boolean
) => {
  const [localData, setLocalData] = useState<any[]>([]);
  const dispatch = useDispatch();
  const isAvailableToStartSearching = useSelector(
    selectIsAvailableToStartSearching
  );
  const currentLanguage = useSelector(selectLanguage);
  const search = searchValue.length > 0 ? `&_q=${searchValue}` : searchValue;
  const filters =
    category === "movies" || category === "sermons"
      ? `&filters[for_deaf][$eq]=${!!isForDeaf}`
      : "";

  const token = ls.get(LsValueType.token);

  useEffect(() => {
    if (isAvailableToStartSearching) {
      setIsloadingInProcess(true);

      fetch(
        `${API_URL}/${category}?populate=*&locale=${currentLanguage.id}${search}${filters}`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: token ? `Bearer ${token}` : "",
          }),
        }
      )
        .then((res) => res.json())
        .then(({ data }) => {
          if (data) {
            setIsloadingInProcess(false);

            const preparedData = data
              .map((item: any) => {
                const {
                  description,
                  image_url,
                  release_date,
                  format,
                  download_link,
                  name,
                  slug,
                  file,
                  content_type,
                } = item.attributes;
                const { id } = item;

                return {
                  id: slug,
                  short_id: id,
                  name,
                  description,
                  image_url,
                  release_date,
                  format,
                  file,
                  download_url: download_link,
                  content_type,
                  comments: [
                    {
                      id: "1231212",
                      author: "Joe Biden",
                      comment:
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris, morbi massa, nulla quam nam tempus, tellus consectetur tempor. Velit rhoncus natoque pretium molestie nisl. Elit, viverra tristique faucibus rhoncus accumsan a. Sit dui mauris scelerisque pharetra.",
                    },
                    {
                      id: "12311232449852",
                      author: "Barack Obama",
                      comment:
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit bibendum at tortor pharetra bibendum mattis lectus. Adipiscing enim non velit viverra urna. Proin quisque lacinia hendrerit aliquam. Mi eu non lectus imperdiet habitant erat phasellus faucibus.",
                    },
                  ],
                };
              })
              .filter((item: any) => !item.forKids);

            dispatch(setIsAvailableToStartSearching({ value: false }));
            dispatch(setContentLength(preparedData.length));
            setIsloadingInProcess(false);
            setLocalData(preparedData);
          }
        })
        .catch((err) => {
          console.log("err: ", err);
          setIsloadingInProcess(false);
        });
    }
    if (searchValue.length === 0) {
      setLocalData([]);
    }
  }, [searchValue, isAvailableToStartSearching]);

  return localData;
};

export const useGetSearchedOrderedContent = (
  category: string,
  searchValue: string,
  isSearchedContentVisible?: boolean,
  isForDeaf?: boolean
) => {
  const [localData, setLocalData] = useState<any[]>([]);
  const step = useSelector(selectPaginationStep);
  const currentPage = useSelector(selectCurrentPage);
  const currentLanguage = useSelector(selectLanguage);
  const search = searchValue.length > 0 ? `&_q=${searchValue}` : searchValue;
  const dispatch = useDispatch();
  const filters =
    category === "movies" || category === "sermons"
      ? `&filters[for_deaf][$eq]=${!!isForDeaf}`
      : "";

  const token = ls.get(LsValueType.token);

  useEffect(() => {
    if (isSearchedContentVisible && searchValue.length > 0) {
      dispatch(setIsContentLoading(true));
      fetch(
        `${API_URL}/${category}?populate=*&locale=${currentLanguage.id}&pagination[page]=${currentPage}&pagination[pageSize]=${step}${search}${filters}`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: token ? `Bearer ${token}` : "",
          }),
        }
      )
        .then((res) => res.json())
        .then(({ data, meta }) => {
          if (data) {
            dispatch(setIsContentLoading(false));

            const preparedData = data
              .map((item: any) => {
                const {
                  description,
                  image_url,
                  release_date,
                  format,
                  download_link,
                  name,
                  slug,
                  file,
                  content_type,
                } = item.attributes;
                const { id } = item;

                return {
                  id: slug,
                  short_id: id,
                  name,
                  description,
                  image_url,
                  release_date,
                  format,
                  file,
                  download_url: download_link,
                  content_type,
                  comments: [
                    {
                      id: "1231212",
                      author: "Joe Biden",
                      comment:
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris, morbi massa, nulla quam nam tempus, tellus consectetur tempor. Velit rhoncus natoque pretium molestie nisl. Elit, viverra tristique faucibus rhoncus accumsan a. Sit dui mauris scelerisque pharetra.",
                    },
                    {
                      id: "12311232449852",
                      author: "Barack Obama",
                      comment:
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit bibendum at tortor pharetra bibendum mattis lectus. Adipiscing enim non velit viverra urna. Proin quisque lacinia hendrerit aliquam. Mi eu non lectus imperdiet habitant erat phasellus faucibus.",
                    },
                  ],
                };
              })
              .filter((item: any) => !item.forKids);
            dispatch(setPagination(meta.pagination));
            dispatch(setContentLength(preparedData.length));
            setLocalData(preparedData);
          }
        })
        .catch((err) => {
          console.log("err: ", err);
          dispatch(setIsContentLoading(false));
        });

      if (searchValue.length === 0) {
        setLocalData([]);
      }
    }
  }, [isSearchedContentVisible, searchValue, currentPage, currentLanguage]);

  return localData;
};

export const useGetContentDetails = (
  id: string,
  category: string,
  commentCategory: string
) => {
  const [localData, setLocalData] = useState<any>({});
  const dispatch = useDispatch();
  const currentLanguage = useSelector(selectLanguage);
  const token = ls.get(LsValueType.token);

  useEffect(() => {
    dispatch(setIsContentLoading(true));
    fetch(
      `${API_URL}/${category}?populate=*&locale=${currentLanguage.id}&filters[slug][$eq]=${id}`,
      {
        method: "GET",
        headers: new Headers({ Authorization: token ? `Bearer ${token}` : "" }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        const { data } = res;
        if (data) {
          const id = data[0].id;

          getComments(commentCategory, id).then((res) => {
            const preparedComments =
              res.length > 0
                ? res.map((item: any) => ({
                    id: `${item.id}`,
                    author: item.author,
                    comment: item.content,
                  }))
                : [];

            const preparedData = data.map((item: any) => {
              const {
                description,
                image_url,
                release_date,
                format,
                download_link,
                name,
                slug,
                content_type,
                file,
                views,
              } = item.attributes;

              return {
                id: slug,
                short_id: id,
                name,
                description,
                image_url,
                release_date,
                format,
                file,
                download_url: download_link,
                content_type,
                comments: preparedComments,
                views,
              };
            })[0];

            dispatch(setIsContentLoading(false));
            dispatch(setContentItem({ value: preparedData }));
            setLocalData(preparedData);
          });
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        dispatch(setIsContentLoading(true));
      });
  }, [currentLanguage, id]);

  return localData;
};

export const getComments = async (category: string, id: string) => {
  const token = ls.get(LsValueType.token);

  return fetch(`${API_URL}/comments/api::${category}.${category}:${id}`, {
    method: "GET",
    headers: new Headers({
      Authorization: token ? `Bearer ${token}` : "",
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log("e: ", e);
    });
};

export const postComment = (
  category: string,
  itemId: number,
  data: { content: string },
  addComment: (comment: any) => void,
  showSuccessMessage: () => void,
  showFailMessage: (message: string) => void
) => {
  const token = ls.get(LsValueType.token);
  fetch(`${API_URL}/comments/api::${category}.${category}:${itemId}`, {
    method: "POST",
    headers: new Headers({
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      const { content, author, id, error } = res;
      const data = { id, comment: content, author };
      if (!!error) {
        showFailMessage(error.message);
      } else {
        addComment(data);
        showSuccessMessage();
      }
    })
    .catch((e) => {
      showFailMessage(e.message);
    });
};

export const editComment = (
  category: string,
  itemId: number,
  commentId: string,
  data: { content: string; author: any },
  showSuccessMessage: () => void,
  showFailMessage: (message: string) => void
) => {
  const token = ls.get(LsValueType.token);

  fetch(
    `${API_URL}/comments/api::${category}.${category}:${itemId}/comment/${commentId}`,
    {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${token}` : "",
      }),
      body: JSON.stringify(data),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then(({ error }) => {
      if (!!error) {
        showFailMessage(error.message);
      } else {
        showSuccessMessage();
      }
    })
    .catch((e) => {
      showFailMessage(e.message);
    });
};

export const removeComment = (
  authorId: number,
  category: string,
  itemId: number,
  commentId: string,
  showSuccessMessage: () => void,
  showFailMessage: (message: string) => void
) => {
  const token = ls.get(LsValueType.token);

  fetch(
    `${API_URL}/comments/api::${category}.${category}:${itemId}/comment/${commentId}?authorId=${authorId}`,
    {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${token}` : "",
      }),
    }
  )
    .then((res) => {
      return res.json();
    })
    .then(({ error }) => {
      if (!!error) {
        showFailMessage(error.message);
      } else {
        showSuccessMessage();
      }
    })
    .catch((e) => {
      showFailMessage(e.message);
    });
};

export const setViews = (category: string, itemId: string) => {
  const token = ls.get(LsValueType.token);

  fetch(`${API_URL}/${category}/${itemId}/view`, {
    method: "PUT",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    }),
    body: JSON.stringify({}),
  })
    .then((res) => {
      return res.json();
    })
    .then(({ error }) => {
      if (!!error) {
      } else {
      }
    })
    .catch((e) => {});
};
