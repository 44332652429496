import React from "react";
// @ts-ignore
import styled from "@xstyled/styled-components";
import { useSelector } from "react-redux";
import {
  selectIsLoggedIn,
  selectLanguage,
} from "../../../../app/state/appSlice";
import { DeafIcon } from "./components/deafIcon/DeafIcon";
import { FlagIcon } from "../../../flagIcon/FlagIcon";
import { ReactComponent as PersonalInfoIcon } from "../../../../assets/personal-info-icon.svg";
import { media } from "../../../../styles/media";
import { useLocation } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const LanguageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  cursor: pointer;
  margin-right: 8px;
  & > svg {
    width: 30px;
    height: 30px;
    & > path {
      transition: all 0.3s ease;
    }
  }
  ${media.tablet`
    & > svg {
      width: 24px;
      height: 24px;
    }
    & > div {
      width: 24px;
      height: 24px;
      & > svg {
        width: 24px;
        height: 24px;
      }
    }
    margin-right: 8px;
  `}

  &.scrolled > svg > path {
    fill: ${(props: any) => props.theme.colors.black};
  }
  &.withModal {
    margin: 0;
  }

  &:hover {
    & > svg > path {
      fill: ${(props: any) => props.theme.colors.blue};
      transition: all 0.3s ease;
    }
  }
`;

const StyledPersonalInfoIcon = styled(PersonalInfoIcon)`
  width: 30px;
  height: 30px;
  margin-left: 8px;
  cursor: ${(props: any) => (props.isPersonalInfoPage ? "auto" : "pointer")};
  & > g > path {
    stroke: ${(props: any) =>
      props.isPersonalInfoPage
        ? props.theme.colors.blue
        : props.theme.colors.white};
    fill: ${(props: any) =>
      props.isPersonalInfoPage
        ? props.theme.colors.blue
        : props.theme.colors.white};
    transition: all 0.3s linear;
  }

  &.scrolled > g > path {
    stroke: ${(props: any) =>
      props.isPersonalInfoPage
        ? props.theme.colors.blue
        : props.theme.colors.black};
    fill: ${(props: any) =>
      props.isPersonalInfoPage
        ? props.theme.colors.blue
        : props.theme.colors.black};
  }

  &:hover > g > path {
    stroke: ${(props: any) => props.theme.colors.blue};
    fill: ${(props: any) => props.theme.colors.blue};
    transition: all 0.3s linear;
  }

  ${media.tablet`
    width: 24px;
    height: 24px;
  `}
`;

export const Settings = ({
  languagePickerOnClick,
  isOpenMobileMenu,
  deafIconOnClick,
  personalInfoOnClick,
  isScrolled,
  isDeaf,
}: {
  languagePickerOnClick: () => void;
  deafIconOnClick: () => void;
  personalInfoOnClick: () => void;
  isOpenMobileMenu: boolean;
  isScrolled: boolean;
  isDeaf?: boolean;
}) => {
  const language = useSelector(selectLanguage);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const currentUrl = useLocation().pathname;
  const isPersonalInfoPage = currentUrl.includes("personal");

  const { id } = language;

  return (
    <Wrapper>
      <LanguageWrapper
        className={
          isScrolled ? `scrolled ${isOpenMobileMenu ? "withModal" : ""}` : ""
        }
        onClick={languagePickerOnClick}
      >
        <FlagIcon id={id} />
      </LanguageWrapper>
      {!isOpenMobileMenu && (
        <DeafIcon
          onClick={deafIconOnClick}
          isScrolled={isScrolled}
          isDeaf={isDeaf}
        />
      )}
      {isLoggedIn && (
        <StyledPersonalInfoIcon
          className={isScrolled ? "scrolled" : ""}
          isPersonalInfoPage={isPersonalInfoPage}
          onClick={() => {
            !isPersonalInfoPage && personalInfoOnClick();
          }}
        />
      )}
    </Wrapper>
  );
};
