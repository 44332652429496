import React from "react";
// @ts-ignore
import styled from "@xstyled/styled-components";
import { ReactComponent as RussianFlag } from "../../assets/Russianflag.svg";
import { ReactComponent as EnglishFlag } from "../../assets/EnglishFlag.svg";
import { ReactComponent as KirgizFlag } from "../../assets/KirgizFlag.svg";
import { ReactComponent as KorakalpokGlag } from "../../assets/KorakolpakFlag.svg";
import { ReactComponent as UigurFlag } from "../../assets/UigurFlag.svg";
import { ReactComponent as TurkmenFlag } from "../../assets/TurkmenFlag.svg";
import { ReactComponent as KazahFlag } from "../../assets/KazahFlag.svg";
import { ReactComponent as UsbekFlag } from "../../assets/UzbekFlag.svg";
import { ReactComponent as AzerFlag } from "../../assets/AzərbaycanFlag.svg";
import { ReactComponent as TochikFlag } from "../../assets/TajikFlag.svg";
import { ReactComponent as OthersIcon } from "../../assets/OthersSign.svg";
import { ReactComponent as DeafAndDumb } from "../../assets/DeafAndDumb.svg";

const Wrapper = styled.div`
  width: ${(props: any) => (props.width ? props.width : "30px")};
  height: ${(props: any) => (props.width ? props.width : "30px")};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 50%;
  & > svg {
    width: ${(props: any) => (props.width ? props.width : "30px")};
    height: ${(props: any) => (props.width ? props.width : "30px")};
    border-radius: 50%;
  }
`;

export const FlagIcon = ({ id, width }: { id: string; width?: string }) => {
  return (
    <Wrapper width={width}>
      {id === "ru-RU" ? (
        <RussianFlag />
      ) : id === "en-GB" ? (
        <EnglishFlag />
      ) : id === "tg" ? (
        <TochikFlag />
      ) : id === "uz" ? (
        <UsbekFlag />
      ) : id === "az" ? (
        <AzerFlag />
      ) : id === "kk" ? (
        <KazahFlag />
      ) : id === "tk" ? (
        <TurkmenFlag />
      ) : id === "ug" ? (
        <UigurFlag />
      ) : // <span>{"UG"}</span>
      id === "kaa" ? (
        <KorakalpokGlag />
      ) : id === "ky" ? (
        <KirgizFlag />
      ) : id === "others" ? (
        <OthersIcon />
      ) : id === "deafDumb" ? (
        <DeafAndDumb />
      ) : (
        <></>
      )}
    </Wrapper>
  );
};
