import React from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { FormInput, FormInputType } from "./components/formInput/FormInput";
import { Button } from "../../../../../../components/button/Button";
import { FieldsType, isValidField } from "../../../../../../utils/validation";
import { media } from "../../../../../../styles/media";

const Wrapper = styled.div`
  width: 100%;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  ${media.tabletS`
    flex-direction: column-reverse;
    justify-content: center;

    & > button {
      width: 100%;
    }
  `}
`;

export interface CommentFormValuesType {
  comment: string;
  author: { id: number; name: string; email: string };
  id: string;
}

export const CommentForm = ({
  initialValues,
  setInitialValues,
  isFormSubmitted,
  setIsFormSubmitted,
  onSubmit,
  cancelOnClick,
  languageStrings,
  isEditInProcess,
  inputRef,
}: {
  initialValues: CommentFormValuesType;
  setInitialValues: React.Dispatch<React.SetStateAction<CommentFormValuesType>>;
  isFormSubmitted: boolean;
  setIsFormSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (values: CommentFormValuesType) => void;
  cancelOnClick: () => void;
  inputRef: React.RefObject<HTMLTextAreaElement | HTMLInputElement>;
  isEditInProcess: boolean;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const { blue, white } = theme.colors;
  const { medium } = theme.fontSizes;

  const { comment } = initialValues;

  const isCommentValid = !!isValidField(comment, FieldsType.TEXT);

  const buttonValue = isEditInProcess
    ? languageStrings
      ? languageStrings.saveButtonLabel
      : "Save"
    : languageStrings
    ? languageStrings.commentButtonValue
    : "Comment";

  const inputOnChange = (value: string, fieldName: string) => {
    setInitialValues({ ...initialValues, [fieldName]: value });
  };
  const clearAllInputs = () => {
    setInitialValues({ ...initialValues, comment: "" });
  };

  const inputsArr = [
    {
      value: comment,
      label: languageStrings
        ? languageStrings.commentInputCommentLabel
        : "Comment",
      placeholder: languageStrings
        ? languageStrings.commentInputCommentPlaceholder
        : "Enter comment",
      type: FormInputType.TEXTAREA,
      isError: !isCommentValid,
      errorMessage: isCommentValid
        ? ""
        : languageStrings
        ? languageStrings.commentInputCommentErrorText
        : "Comment is required!",
      onChange: (value: string) => inputOnChange(value, "comment"),
    },
  ];

  const handleOnSubmit = () => {
    setIsFormSubmitted(true);
    if (isCommentValid) {
      clearAllInputs();
      setIsFormSubmitted(false);
      onSubmit(initialValues);
    }
  };

  return (
    <Wrapper>
      {inputsArr.map((item, index: number) => {
        const {
          errorMessage,
          placeholder,
          onChange,
          isError,
          label,
          value,
          type,
        } = item;

        return (
          <FormInput
            isFormSubmitted={isFormSubmitted}
            errorMessage={errorMessage}
            placeholder={placeholder}
            onChange={onChange}
            isError={isError}
            label={label}
            value={value}
            type={type}
            key={index}
            inputRef={inputRef}
          />
        );
      })}
      <ButtonWrapper>
        {isEditInProcess && (
          <Button
            value={
              languageStrings ? languageStrings.cancelButtonLabel : "Cancel"
            }
            onClick={cancelOnClick}
            color={blue}
            backgroundColor={"transparent"}
            borderColor={blue}
            hoverColor={white}
            hoverBackgroundColor={blue}
            borderColorHover={blue}
            width={"242px"}
          />
        )}

        <Button
          value={buttonValue}
          width={"242px"}
          fontSize={medium}
          onClick={handleOnSubmit}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};
