import React from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { HomeIcon } from "./components/HomeIcon";
import { Text } from "../../../text/Text";
import { media } from "../../../../styles/media";

const Wrapper = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  & > svg {
    width: 30px;
    height: 30px;
    & > path {
      transition: all 0.3s ease;
    }
  }
  &.active {
    pointer-events: none;
    cursor: auto;
    & > svg > path {
      fill: ${(props: any) => props.theme.colors.blue};
    }
  }
  &.scrolled {
    & > svg > path {
      fill: ${(props: any) => props.theme.colors.black};
    }
  }
  &:hover {
    & > svg > path {
      fill: ${(props: any) => props.theme.colors.blue};
      transition: all 0.3s ease;
    }
    & > span {
      color: blue;
    }
  }
  ${media.tablet`
    display: none;
  `}
`;

export const HomeItem = ({
  isScrolled,
  isActive,
  href,
  languageStrings,
}: {
  isScrolled: boolean;
  isActive: boolean;
  href: string;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const { blue, white, black } = theme.colors;
  const { medium } = theme.fontSizes;

  return (
    <Wrapper
      className={isActive ? "active" : isScrolled ? "scrolled" : ""}
      href={href}
    >
      <HomeIcon />
      <Text
        color={isActive ? blue : isScrolled ? black : white}
        size={medium}
        weight={"500"}
      >
        {languageStrings ? languageStrings.homeLabel : "Home"}
      </Text>
    </Wrapper>
  );
};
