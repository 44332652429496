export enum GeneralView {
  USER = "USER",
  DEAF = "DEAF",
  COMMON = "COMMON",
  REDIRECT = "REDIRECT",
}

export enum UserView {
  USER_HOME = "USER_HOME",
  USER_BOOKS = "USER_BOOKS",
  USER_BOOKS_DETAILS = "USER_BOOKS_DETAILS",
  USER_AUDIOBOOKS = "USER_AUDIOBOOKS",
  USER_AUDIOBOOKS_DETAILS = "USER_AUDIOBOOKS_DETAILS",
  USER_MOVIES = "USER_MOVIES",
  USER_MOVIES_DETAILS = "USER_MOVIES_DETAILS",
  USER_SERMONS = "USER_SERMONS",
  USER_SERMONS_DETAILS = "USER_SERMONS_DETAILS",
  USER_KIDS = "USER_KIDS",
  USER_KIDS_DETAILS = "USER_KIDS_DETAILS",
  USER_PERSONAL_INFO = "USER_PERSONAL_INFO",
}

export enum DeafView {
  DEAF_HOME = "DEAF_HOME",
  DEAF_MOVIES = "DEAF_MOVIES",
  DEAF_MOVIES_DETAILS = "DEAF_MOVIES_DETAILS",
  DEAF_SERMONS = "DEAF_SERMONS",
  DEAF_SERMONS_DETAILS = "DEAF_SERMONS_DETAILS",
  DEAF_LESSONS = "DEAF_LESSONS",
  DEAF_LESSONS_DETAILS = "DEAF_LESSONS_DETAILS",
}
export enum CommonView {
  COMMON_WELCOME = "COMMON_WELCOME",
}
