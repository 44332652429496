import React from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { Text } from "../text/Text";
import { media } from "../../styles/media";
import { useMediaType } from "../../styles/style.context";
import { CONTACT_US_EMAIL } from "../../consts";

const Wrapper = styled.div`
  width: 100%;
  height: 102px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 58px;
  margin-top: 15px;
  gap: 6px;

  ${media.tablet`
    padding: 0 16px;
  `}

  ${media.tabletS`
      flex-direction: column;

  `}
`;

export const Footer = ({ languageStrings }: { languageStrings?: any }) => {
  const mediaType = useMediaType();
  const theme = useTheme();

  const { medium, common } = theme.fontSizes;
  const { blue } = theme.colors;
  const { tabletS, phone } = mediaType;

  return (
    <Wrapper>
      <Text color={blue} weight={"400"} size={phone ? common : medium}>
        {`${languageStrings ? languageStrings.contactUs : "Contact us"}`}
      </Text>
      <Text color={blue} weight={"400"} size={phone ? common : medium}>
        {`${tabletS ? "" : " - "}${CONTACT_US_EMAIL}`}
      </Text>
    </Wrapper>
  );
};
