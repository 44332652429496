import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLanguageStrings,
  selectSearchInputValue,
  setIsSearchedDataLoading,
} from "../../../app/state/appSlice";
import { PageWrapper } from "../../../layouts/pageWrapper/PageWrapper";
import { View } from "../../../routes";
import { ContentList } from "../../../components/contentList/ContentList";
import { selectIsSearchContentVisible } from "../../../app/state/contentSlice";
import {
  useGetContent,
  useGetFilteredFullListContent,
  useGetSearchedOrderedContent,
} from "../../../api/content";

export const DeafSermons = () => {
  const category = "sermons";
  const dispatch = useDispatch();

  const setLoadingInProcess = (value: boolean) => {
    dispatch(setIsSearchedDataLoading({ value }));
  };

  const searchInputValue = useSelector(selectSearchInputValue);
  const languageStrings = useSelector(selectLanguageStrings);
  const isSearchedContentVisible = useSelector(selectIsSearchContentVisible);

  const { content } = useGetContent(category, true);
  const searchedDataOrdered = useGetSearchedOrderedContent(
    category,
    searchInputValue,
    isSearchedContentVisible,
    true
  );
  const searchedDataFull = useGetFilteredFullListContent(
    category,
    searchInputValue,
    setLoadingInProcess,
    true
  );

  return (
    <PageWrapper
      title={languageStrings ? languageStrings.sermonsLabel : "Sermons"}
      searchedDataOrdered={searchedDataOrdered}
      searchedDataFull={searchedDataFull}
      detailsView={View.DEAF_SERMONS_DETAILS}
      currentView={View.DEAF_SERMONS}
      category={category}
    >
      <ContentList
        searchedDataOrdered={searchedDataOrdered}
        currentView={View.DEAF_SERMONS}
        detailsView={View.DEAF_SERMONS_DETAILS}
        category={category}
        data={content}
      />
    </PageWrapper>
  );
};
