import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectContentItem } from "../../../app/state/contentSlice";
import { UserView } from "../../../enums/View";
import { ContentDetails } from "../../../layouts/contentDetails/ContentDetails";
import { useGetContentDetails } from "../../../api/content";

export const KidsDetails = () => {
  const commentCategory = "kids";
  const contentCategory = "for-kids";
  const pathArr = useLocation().pathname.split("/");
  const kidId = pathArr[4];

  useGetContentDetails(kidId, contentCategory, commentCategory);
  const kidsDetailsData = useSelector(selectContentItem);

  return (
    <ContentDetails
      data={kidsDetailsData}
      rootView={UserView.USER_KIDS}
      category={commentCategory}
      contentCategory={contentCategory}
    />
  );
};
