import React from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";

import { ReactComponent as Icon } from "../../../../assets/back_arrow.svg";
import { Text } from "../../../../components/text/Text";

const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0;

  & > svg {
    width: 32px;
    height: 32px;
  }
`;

export const BackButton = ({
  onClick,
  languageStrings,
}: {
  onClick: () => void;
  languageStrings?: any;
}) => {
  const theme = useTheme();
  const { blue } = theme.colors;
  const { medium } = theme.fontSizes;

  return (
    <Wrapper onClick={onClick}>
      <Icon />
      <Text margin={"0 0 0 8px"} color={blue} size={medium}>
        {languageStrings ? languageStrings.backLabel : "Back"}
      </Text>
    </Wrapper>
  );
};
