import React from "react";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { Text } from "../../../../components/text/Text";

const Wrapper = styled.a`
  width: 100%;
  display: flex;
  padding: 15px 0;
  &.first {
    border: none;
  }
  border-top: 1px solid;
  border-color: ${(props: any) => props.theme.colors.gray3};
  text-decoration: none;
  cursor: pointer;
`;
const Image = styled.img`
  width: 72px;
  height: 80px;
  margin-right: 24px;
`;
const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SearchListItem = ({
  title,
  releaseDate,
  additionalInfo,
  imageUrl,
  searchItemOnClick,
  href,
  ...props
}: {
  title: string;
  releaseDate: string;
  additionalInfo: string;
  imageUrl: string;
  searchItemOnClick: (e: any) => void;
  href: string;
  className?: string;
}) => {
  const theme = useTheme();
  const { black1, gray2, blue } = theme.colors;
  const { common, small, extraSmall } = theme.fontSizes;

  return (
    <Wrapper href={href} onClick={searchItemOnClick} {...props}>
      <Image src={imageUrl} alt={"ITEM"} />
      <DataWrapper>
        <Text size={common} weight={"600"} color={black1} margin={"0 0 8px 0"}>
          {title}
        </Text>
        <Text size={small} weight={"400"} color={gray2} margin={"0 0 8px 0"}>
          {releaseDate}
        </Text>
        <Text size={extraSmall} weight={"400"} color={blue}>
          {additionalInfo}
        </Text>
      </DataWrapper>
    </Wrapper>
  );
};
