import React, { FC, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
// @ts-ignore
import styled, { useTheme } from "@xstyled/styled-components";
import { media } from "../../styles/media";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
  background-color: rgb(0, 0, 0, 0.6);
  ${media.phone`
    align-items: flex-end;
  `}
`;
const Container = styled.div`
  max-width: ${(props: any) => (props.width ? props.width : "882px")};
  width: calc(100% - 64px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white2;
  border-radius: ${(props: any) => props.theme.borderRadius.additional10};
  padding: 32px;
  ${media.tablet`
    width: 100%;
    position: fixed;
    bottom: 0;
    border-radius: 16px 16px 0 0;
  `}
  ${media.phone`
    align-items: flex-start;
    padding: 32px 16px;
  `}
`;

export interface ModalProps {
  children: any;
  isOpenModal: boolean;
  closeModal: () => void;
  bodyElem?: any;
  width?: string;
  className?: string;
}
export const Modal: FC<ModalProps> = ({
  children,
  isOpenModal,
  closeModal,
  bodyElem,
  width,
  ...props
}) => {
  const theme = useTheme();

  const modalRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (bodyElem) {
      bodyElem.style.overflow = isOpenModal ? "hidden" : "overlay";
    }
  }, [isOpenModal, bodyElem]);

  if (isOpenModal) {
    return createPortal(
      <Wrapper {...props}>
        <Container theme={theme} width={width} ref={modalRef}>
          {children}
        </Container>
      </Wrapper>,
      document.getElementById("modal") || document.body
    );
  }
  return null;
};
